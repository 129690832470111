import { useContext, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Exam } from "../../../types/Exam";
import { EnvironmentVariableContext } from '../../../store/EnvironmentVariableContext';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { EXAMRESULTPDFDOWNLOAD } from "../../../properties/appInsightsProperties";
import { useMsal } from "@azure/msal-react";
import { getExamPdfByExamId } from '../../../api/examsAPI';
import { Button, Toast, ToastHeader } from 'react-bootstrap';

interface ExamActionButtonProps {
  row: Exam;
}

export const ExamActionButton = (props: ExamActionButtonProps) => {
  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const appApiBaseUrl = environmentVariableContext.REACT_APP_API_BASEURL;
  const appInsights = useAppInsightsContext();
  const { accounts } = useMsal();

  const [apiError, setApiError] = useState(false);

  function displayCustomerSummaryPDF(examPdf: any){
    const url = window.URL.createObjectURL(new Blob([examPdf], { type: 'application/pdf' }));
    window.open(url);
  }

  async function handleExamPdfClick() {
    getExamPdfByExamId(appApiBaseUrl, props.row.roadTestExamId).then(examPdf => displayCustomerSummaryPDF(examPdf)).catch(error => {setApiError(true)});
    
    if (appInsights !== undefined) { 
      appInsights.trackEvent(
        { name: EXAMRESULTPDFDOWNLOAD },
        { user: accounts[0].username, roadTestExamId: props.row.roadTestExamId}
      );
    }
  }
  
  if (apiError){
    return (
      <>
        <div><strong style={{color: "red"}}>PDF Error</strong></div>
        <Toast onClose={() => setApiError(false)} role="alert" show={apiError} autohide={false} style={{
          position: 'fixed',
          top: 60,
          right: 0,
        }}>
            <ToastHeader style={{
              background: "#f8d7da",
              color: "#721c24"
            }}>
              <strong data-testid='pdfErrorToast' style={{paddingRight: "6.5rem", marginBottom: "0.25rem"}}>ERROR: Exam Summary PDF</strong>
            </ToastHeader>
            <Toast.Body>Error occurred during download of Customer Exam Summary PDF - Contact Support.</Toast.Body>
          </Toast>
        </>
    );
  } else {
      return (
        <Button className="btn btn-light btn-sm" style={{ background: "transparent", border: "none"}} name="PDF-button">
          <span className='text-primary action-icon' style={{ fontSize: "1.5em" }}>
          <FontAwesomeIcon icon={faFilePdf} title="Get road test PDF" key={props.row.roadTestExamId} onClick={(e) => handleExamPdfClick()} />
          </span>
        </Button>
      );
  }
  
};
export default ExamActionButton;
