import Collapse from "react-bootstrap/Collapse";
import { useState } from "react";
import { ConversationTypes, ConversationTypesDisplayFriendly } from "../../../enums/ConversationTypes";
import { Appointment } from "../../../types/appointment";
import { MobileNumberStatusDisplayFriendly } from "../../../enums/MobileNumberStatus";
import { Conversation } from "../../../types/conversation";
import CommunicationLogEntry from "./CommunicationLogEntry";

interface AppointmentTableRowProps {
    appointment: Appointment,
    onEditClick: Function,
    highlightRow: string
}

const AppointmentTableRow = (props: AppointmentTableRowProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <tr
        id={'row_' + props.appointment.appointmentId}
        className={props.highlightRow}
        onClick={() => setOpen(!open)}
        aria-controls={'comminfo_' + props.appointment.customerId}
        aria-expanded={open}
      >
        <td>
          {props.appointment.firstName} {props.appointment.lastName}
          <br />
          {props.appointment.customerId}
        </td>
        <td>{props.appointment.mobileNumber}</td>
        <td>{MobileNumberStatusDisplayFriendly[props.appointment.mobileNumberStatus]}</td>
        <td>{props.appointment.appointmentDate}</td>
        <td>{props.appointment.checkedInDateTime}</td>
        <td>{props.appointment.examinerNumber}</td>
        <td>{props.appointment.location?.name}</td>
        <td>
          <button
            className="btn btn-primary"
            onClick={() => props.onEditClick(props.appointment)}
          >
            Edit
          </button>
        </td>
      </tr>
      <tr>
        <td colSpan={9} className="p-0 border-top-0">
          <Collapse in={open}>
            <div
              id={'comminfo_' + props.appointment.customerId}
              className="p-3 bg-light"
            >
                <h6>Communication Log</h6>
                <div className="mb-3">
                {props.appointment.conversations.filter(c => c.conversationType === ConversationTypes.OptOut).length >0? 
                  "<b> Customer has opted out from SMS communications</b>" : 
                  // eslint-disable-next-line array-callback-return
                  props.appointment.conversations.map((convo,index) => {
                    switch (convo.conversationType) {
                        case ConversationTypes.CheckInReminder:  
                        case ConversationTypes.CovidQuestions:
                        return (
                          <div key={convo.conversationType+index +'_' + Math.random().toString(36).substring(2, 9)}>
                            Sent - {ConversationTypesDisplayFriendly[convo.conversationType]}
                            {
                            // eslint-disable-next-line array-callback-return
                            convo.conversationMessages.map(msg => {
                              <div key={msg.messageId}>  Received - {msg.body}</div>
                            })} 
                          </div> 
                        )
                      default:
                        return (
                          <div key={convo.conversationType+index +'_' + Math.random().toString().substring(2, 9)}>
                            Sent - {ConversationTypesDisplayFriendly[convo.conversationType]}</div> 
                        )
                    }
                  })}   
                </div>
                <div>
                    {props.appointment.conversations ? 
                        props.appointment.conversations.map((n: Conversation, index: number) => <CommunicationLogEntry key={index} conversation={n} />) : 
                        <p>None</p>
                    }
                </div>                               
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

export default AppointmentTableRow;
