import {
    Configuration,
    RedirectRequest,
    LogLevel,
    EndSessionRequest,
  } from '@azure/msal-browser';
  import { GetB2CPolicyInfo } from './b2cPolicies';
  
  /**
   * Configuration object to be passed to MSAL instance on creation.
   * For a full list of MSAL.js configuration parameters, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
   */
  export function getConfig(): Configuration {
    const REACT_APP_MSAL_CLIENT_ID = window['runConfig']?.REACT_APP_MSAL_CLIENT_ID;
    const REACT_APP_BASEURL = window['runConfig']?.REACT_APP_BASEURL;  
    const b2cPolicies = GetB2CPolicyInfo();
  
    return {
      auth: {
        clientId: REACT_APP_MSAL_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: REACT_APP_BASEURL,
        postLogoutRedirectUri: `${REACT_APP_BASEURL}`,
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                console.error(message);
                return;
            }
          },
        },
      },
    };
  }
  
  /**
   * Scopes you add here will be prompted for user consent during sign-in.
   * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
   * For more information about OIDC scopes, visit:
   * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
   */
  export const loginRequest: RedirectRequest = {
    scopes: ['openid', 'offline_access'],
  };
  
  export const logoutRequest: EndSessionRequest = {
    postLogoutRedirectUri: '',
  };
  
  export function getB2cScopes(): string[] {
    return [
      'openid',
      'offline_access',
      window['runConfig'].REACT_APP_BFF_API_SCOPES,
    ];
  }