import { Modal, Button } from "react-bootstrap";
import { AppointmentForDashboard } from "../../../types/appointment";
import { getDateString, getTimeString } from "./DashboardFunctions";

interface PostponedAppointmentModalProps {
    appointment: AppointmentForDashboard;
    show: boolean;
    handleClose: React.MouseEventHandler;
    handlePostponedAppt: React.MouseEventHandler;
    submitButtonParams: {text: string, disabled: boolean};
}

export function PostponedAppointmentModal({appointment, show, handleClose, handlePostponedAppt, submitButtonParams}: PostponedAppointmentModalProps) {
    
    return(
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>Postponed appointment confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body data-testid="modalBody">Are you sure you want to postponed {appointment.firstName} {appointment.lastName}'s appointment scheduled at {getDateString(appointment.appointmentDate)} {getTimeString(appointment.appointmentDate)}?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} data-testid="closeModal">
                    Close
                </Button>
                <Button variant="primary" onClick={handlePostponedAppt} data-testid="postponedAppointment" disabled={submitButtonParams.disabled}>
                    {submitButtonParams.text}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PostponedAppointmentModal;