function Footer() {
    return(
        <footer>
            <div id="footer" className="container-fluid py-3">
                <p className="my-0 small">&copy; {new Date().getFullYear()} SGI. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;