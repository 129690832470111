import { Modal, Button } from "react-bootstrap";
import { AppointmentForDashboard } from "../../../types/appointment";
import { getDateString, getTimeString } from "./DashboardFunctions";

interface NoShowAppointmentModalProps {
    appointment: AppointmentForDashboard;
    show: boolean;
    handleClose: React.MouseEventHandler;
    handleNoShowAppt: React.MouseEventHandler;
    submitButtonParams: {text: string, disabled: boolean};
}

export function NoShowAppointmentModal({appointment, show, handleClose, handleNoShowAppt, submitButtonParams}: NoShowAppointmentModalProps) {
    
    return(
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>No show appointment confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body data-testid="modalBody">Are you sure you want to update {appointment.firstName} {appointment.lastName}'s appointment at {getDateString(appointment.appointmentDate)} {getTimeString(appointment.appointmentDate)} with no show?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} data-testid="closeModal">
                    Close
                </Button>
                <Button variant="primary" onClick={handleNoShowAppt} data-testid="noShowAppointment" disabled={submitButtonParams.disabled}>
                    {submitButtonParams.text}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default NoShowAppointmentModal;