import { useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import ExamDownloadPdf from "./ExamDownloadPdf"
interface ExamSearchProps {
  search: (customerNumber: string) => Promise<void>;
  showSpinner: boolean;
  formValidationErrors: boolean;
}

function ExamsSearch(props: ExamSearchProps) {
  const handleSearch = props.search;
  const txtCustomerNumber = useRef<HTMLInputElement>(null);
  const formValidationErrors = props.formValidationErrors;
  const showSpinner = props.showSpinner;
  return (
    <>
    <Row>
      <Col xl={5}>
        <Form.Row>
          <Form.Group
            as={Col}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Form.Label className="sr-only" htmlFor="customerNumber">Customer Number</Form.Label>
              <Form.Control
              type="text"
              id="customerNumber"
              ref={txtCustomerNumber}
              placeholder="Search customer number"
              isInvalid={!!formValidationErrors}
            />
            <small className="form-text text-muted">Please enter an 8 digit number.</small>
            <Form.Control.Feedback type="invalid">
              Invalid Customer Number
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            {showSpinner ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Searching...
            </Button>
            ) : (
            <Button
              variant="primary"
              onClick={() =>
                handleSearch(txtCustomerNumber.current?.value ?? "")
              }
            >
              Search
            </Button>
          )}
          <ExamDownloadPdf customerNumber={txtCustomerNumber} />
          </Form.Group>
        </Form.Row>
      </Col>
    </Row>

    </>
  );
}

export default ExamsSearch;
