import { useContext } from "react";
import {Link, useLocation} from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { AuthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";
import { EndSessionRequest, AccountInfo } from "@azure/msal-browser";
import { EnvironmentVariableContext } from "../../../store/EnvironmentVariableContext";
import Authorized from "../../common/Authorized";
import UserProfileIcon from "./UserProfileIcon";
import { UserPermissionsContext } from "store/UserPermissionsContext";

const SignInOutButton = () => {
    const { accounts, instance } = useMsal();
    const environmentVariableContext = useContext(EnvironmentVariableContext);
    let account: AccountInfo | null;
    account = useAccount(accounts[0] || {});
    const location = useLocation();
    const logoutRequest: EndSessionRequest = {
        postLogoutRedirectUri: "/"
    }
    const permissions = useContext(UserPermissionsContext);

    return (
        <>
        {location.pathname !== "/Logout" &&
        <div>
            <AuthenticatedTemplate>
                <Dropdown>
                    {account && <UserProfileIcon name={account.name} /> }
                    <Dropdown.Menu align="right" className="nav-sub-menu">
                        { (!environmentVariableContext.REACT_APP_HIDE_DEMO) &&
                            <Authorized isAuthorized={permissions.canWriteAppointments || permissions.canReadAppointments}>
                                <DevMenu />
                            </Authorized>
                        }
                        <Dropdown.Item onClick={() => instance.logoutRedirect(logoutRequest)}>Sign Out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </AuthenticatedTemplate>
            </div>       
            }
        </>
    );
};

function DevMenu () {
    return (
        <DropdownButton className="nav-sub-sub-menu" as={ButtonGroup} id="test" drop="left" variant="link" title="Dev Menu    ">
            <Link className="dropdown-item" to="/DevDashboard">Appointments</Link>
            <Link className="dropdown-item" to="/EightPtTest">8Pt Test</Link>
        </DropdownButton>
    );
}

export default SignInOutButton;