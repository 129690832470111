import { Modal, Button } from "react-bootstrap";
import { AppointmentForDashboard } from "../../../types/appointment";
import { getDateString, getTimeString } from "./DashboardFunctions";

interface ReopenAppointmentModalProps {
    appointment: AppointmentForDashboard;
    show: boolean;
    handleClose: React.MouseEventHandler;
    handleReopenAppt: React.MouseEventHandler;
    submitButtonParams: {text: string, disabled: boolean};
}

export function ReopenAppointmentModal({appointment, show, handleClose, handleReopenAppt: handleReOpenAppt, submitButtonParams}: ReopenAppointmentModalProps) {
    
    return(
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>Reopen appointment confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body data-testid="modalBody">Are you sure you want to Reopen {appointment.firstName} {appointment.lastName}'s appointment scheduled {getDateString(appointment.appointmentDate)} {getTimeString(appointment.appointmentDate)}?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} data-testid="closeModal">
                    Close
                </Button>
                <Button variant="primary" onClick={handleReOpenAppt} data-testid="reOpenAppointment" disabled={submitButtonParams.disabled}>
                    {submitButtonParams.text}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ReopenAppointmentModal;