import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MultiSelect from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { Examiner } from 'types/Examiner';
import { Filter } from 'types/Filter';

interface Props {
  examinerFilter?: Filter<Examiner>[];
  setExaminerFilter: Function;
}

export function initialFilterState(
  filter: Filter<Examiner>[] | undefined,
  property: keyof Examiner,
): Option[] {
  if (!filter) return [];

  const currentFilter = filter.find(
    filterItem => filterItem.field === property,
  );
  if (!currentFilter || currentFilter.values.length <= 0) return [];

  if (property === 'isActive') {
    return currentFilter.values.map(value => {
      return value === 'true'
        ? examinerStatusOptions[0]
        : examinerStatusOptions[1];
    });
  } else {
    return currentFilter.values.map(value => {
      return { label: value, value };
    });
  }
}

const examinerStatusOptions: Option[] = [
  {
    value: 'true',
    label: 'Active',
  },
  {
    value: 'false',
    label: 'Inactive',
  },
];

function ExaminerFilters({ examinerFilter, setExaminerFilter }: Props) {
  const [selectedExaminerStatus, setSelectedExaminerStatus] = useState<
    Option[]
  >(initialFilterState(examinerFilter, 'isActive'));

  function handleChange(selected: Option[], property: keyof Examiner) {
    setSelectedExaminerStatus(selected);
    let newFilter: Filter<Examiner>[] = [];
    if (examinerFilter) {
      newFilter = examinerFilter.filter(filter => filter.field !== property);
    }
    if (selected.length > 0) {
      newFilter = [
        ...newFilter,
        { field: property, values: selected.map(option => option.value) },
      ];
    }
    setExaminerFilter(newFilter);
  }

  return (
    <Row>
      <Form.Group
        as={Col}
        sm={8}
        md={4}
        lg={4}
        xl={4}
        className="float-md-right float-lg-none"
      >
        <Form.Label id="examinerStatus">Examiner Status</Form.Label>
        <MultiSelect
          selectAllLabel="Select/Deselect All"
          options={examinerStatusOptions}
          value={selectedExaminerStatus}
          onChange={(selected: Option[]) => handleChange(selected, 'isActive')}
          ClearSelectedIcon={
            <FontAwesomeIcon icon={faTimes} title="Clear Selection" />
          }
          labelledBy="examinerStatus"
        />
      </Form.Group>
    </Row>
  );
}

export default ExaminerFilters;
