import { useEffect } from "react";
import {Link} from "react-router-dom";

function Logout (props: any) {

    useEffect(() => {
        // Autoredirect
        const interval = setInterval(() => {
            props.history.push('/');
        }, 7000);
        return () => clearInterval(interval);
    });

    return(
        <>
            <h4>Session Logout</h4>
            <p>You have successfully logged out of the system.  You will be redirected to the main page in seven seconds or you can go there now by clicking <Link to="/">here</Link>.</p>
        </>
    );
}

export default Logout;