import ModalHeader from 'react-bootstrap/ModalHeader';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import { AppointmentStatus } from '../../../enums/AppointmentStatus';
import { NewEventAppointment } from '../../../types/appointment';
import { ConvertDateTimeToInputValue } from '.';
import { MobileNumberStatus } from '../../../enums/MobileNumberStatus';
import { Location } from '../../../types/Location';
import { useState } from 'react';
import {ToggleButton } from 'react-bootstrap';

interface AddAppointmentModalProps {
  isOpen: boolean;
  hideModal: Function;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  newAppointment: NewEventAppointment;
  whiteListNumbers: string[];
  locations: Location[];
}

function AddAppointmentModal({
  isOpen,
  hideModal,
  onSubmit,
  onChange,
  newAppointment,
  whiteListNumbers,
  locations
}: AddAppointmentModalProps) {

  const [isEvent, setIsEvent] = useState(false);
 function  CreateWhiteListNumbersDropdown() {    
    let options = [];           
    options.push(<option key={'index0'} value=""></option>);
    for (let index = 0; index < whiteListNumbers.length; index++) {
      let phoneNumber = whiteListNumbers[index];
      options.push(<option key={`index${index+1}`} value={phoneNumber}>{phoneNumber}</option>);      
    }
    return options;
  }
  return (
    <Modal show={isOpen} onHide={hideModal} centered={true}>
      <ModalHeader>
        <h4>Add Appointment</h4>
      </ModalHeader>
      <ModalBody>
        <form
          id="addAppointmentForm"
          data-testid="addAppointmentForm"
          onSubmit={onSubmit}
        >          
          <div className="form-group">
          
              <ToggleButton
              className="form-control"
              id="isEvent"
              name="isEvent"
              type="checkbox"              
              variant={isEvent?"outline-success":"outline-primary"}
              checked={isEvent}
              value={newAppointment.isEvent || 0}
              onChange={(e) => {
                e.currentTarget.value = e.currentTarget.checked ? "1":"0";
                setIsEvent(!isEvent);                 
                onChange(e);}}
            >
              {isEvent?"As Event":"As Manual Entry"}
            </ToggleButton>
          
            <label htmlFor="customerId">PIC</label>
            <input
              type="number"
              onChange={onChange}
              className="form-control"
              name="customerId"
              id="customerId"
              value={newAppointment.customerId || 0}
            />
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              name="firstName"
              id="firstName"
              value={newAppointment.firstName || ''}
            />
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              name="lastName"
              id="lastName"
              value={newAppointment.lastName || ''}
            />
            <label htmlFor="mobileNumber">Mobile Number</label>
            <select                        
              onChange={onChange}
              className="form-control"
              name="mobileNumber"
              id="mobileNumber"
              value={newAppointment.mobileNumber}         
            >
              {CreateWhiteListNumbersDropdown()}
            </select>
            <label htmlFor="mobileNumberStatus">Mobile Number Status</label>
            <select
              onChange={onChange}
              className="form-control"
              name="mobileNumberStatus"
              id="mobileNumberStatus"
              value={newAppointment.mobileNumberStatus || 0}
            >
              <option value={MobileNumberStatus.NotSet}>Not Set</option>
              <option value={MobileNumberStatus.Valid}>Valid</option>
              <option value={MobileNumberStatus.Invalid}>Invalid</option>
              <option value={MobileNumberStatus.OptedOut}>Opted Out</option>
            </select>
            <label htmlFor="appointmentDate">Date</label>
            <input
              type="datetime-local"
              onChange={onChange}
              className="form-control"
              name="appointmentDate"
              id="appointmentDate"
              value={ConvertDateTimeToInputValue(newAppointment.appointmentDate)}
            />

            <label htmlFor="appointmentLocation">Location</label>
            <select                        
              onChange={onChange}
              className="form-control"
              name="appointmentLocation"
              id="appointmentLocation"
            >
              <option value=""></option>
              {locations.map(location => <option key={location.id} value={location.id}>{location.name}</option>)}
            </select>
            <label htmlFor="examinerNumber">Examiner Number</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              name="examinerNumber"
              id="examinerNumber"
              maxLength={4}
              value={newAppointment.examinerNumber || "0000"}
            />
            <label htmlFor="legacyAppointmentId">Legacy Appointment Id</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              name="legacyAppointmentId"
              id="legacyAppointmentId"
              value={newAppointment.legacyAppointmentId || ""}
            />
            <label>
              {' '}
              Status
              <select
                value={newAppointment.status || 1}
                onChange={onChange}
                className="form-control"
                id="status"
                name="status"
              >
                <option value={AppointmentStatus.Open}> Open</option>
                <option value={AppointmentStatus.Cancelled}>Cancelled</option>
                <option value={AppointmentStatus.CheckedIn}>Checked In</option>
                <option value={AppointmentStatus.Completed}>Completed</option>
              </select>
            </label>
            {isEvent && 
            <div>
            <label htmlFor="originLastChangedDateTime"> Change Date</label>
            <input
              type="datetime-local"
              onChange={onChange}
              className="form-control"
              name="originLastChangedDateTime"
              id="originLastChangedDateTime"
              value={ConvertDateTimeToInputValue(newAppointment.originLastChangedDateTime)}
            />
            </div>
            }
          </div>
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default AddAppointmentModal;
