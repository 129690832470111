import ModalHeader from 'react-bootstrap/ModalHeader';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import { Col, Row } from 'react-bootstrap';

export interface ExamSummaryModalProps {
  isOpen: boolean;
  hideModal: Function;
  examSummaryModalContent: any;
}

function ExamSummaryModal({
  isOpen,
  hideModal,
  examSummaryModalContent,
}: ExamSummaryModalProps) {  
  
  return (
    <div>
    <Modal show={isOpen} onHide={hideModal} size="xl">
      <ModalHeader closeButton>
        <h4>Exam Summary</h4>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>{examSummaryModalContent}</Col>
        </Row>  
      </ModalBody>
    </Modal>
    </div>
  );
}

export default ExamSummaryModal;