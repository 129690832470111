import { Popover, OverlayTrigger } from 'react-bootstrap';
import { ConversationResponse } from 'enums/ConversationResponse';
import {
  getCheckedInColumnValue,
  getDateString,
  getTimeString,
  getMobileNumberStatusIcon,
  getCovidResponseIcon,
} from './DashboardFunctions';
import { formatE164ToLocalPhoneNumber } from 'utility/formatE164ToLocalPhoneNumber';
import { ServiceTypeDisplayFriendly } from 'enums/ServiceTypes';
import { AppointmentForDashboard } from 'types/appointment';
import { DashboardActionButton } from './DashboardActionButton';
import { MobileNumberStatusDisplayFriendly } from 'enums/MobileNumberStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import { AppointmentStatus } from 'enums/AppointmentStatus';

export function dateFormatter(cell: string) {
  return getDateString(cell);
}

export function timeFormatter(cell: string, row: AppointmentForDashboard) {
  return (
    getDateString(row.appointmentDate) +
    ' - ' +
    getTimeString(row.appointmentDate)
  );
}

export function timeSortFormatter(cell: string, row: AppointmentForDashboard) {
  return row.appointmentDate;
}

export function customerFormatter(cell: string, row: AppointmentForDashboard) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        Mobile Status:{' '}
        {MobileNumberStatusDisplayFriendly[row.mobileNumberStatus]}
      </Popover.Content>
    </Popover>
  );
  if (!row.mobileNumber) {
    return (
      <>
        {row.firstName} {row.lastName}
        <br />
        {row.customerId}
        <br />
      </>
    );
  } else {
    return (
      <>
        {row.firstName} {row.lastName}
        <br />
        {row.customerId}
        <br />
        {formatE164ToLocalPhoneNumber(row.mobileNumber)}{' '}
        <OverlayTrigger placement="right" overlay={popover}>
          {getMobileNumberStatusIcon(row.mobileNumberStatus)}
        </OverlayTrigger>
      </>
    );
  }
}

export function nameSortFormatter(cell: string, row: AppointmentForDashboard) {
  return row.lastName + row.firstName;
}

export function serviceTypeFormatter(
  cell: string,
  row: AppointmentForDashboard,
) {
  return ServiceTypeDisplayFriendly[5];
}

export function healthConcernFormatter(
  covidQuestionResponse: number,
  checkInReminderQuestionResponse: number,
  covidConcernsFeatureFlag: boolean,
  checkInReminderFeatureFlag: boolean,
) {
  let concernString;
  if (
    covidQuestionResponse === ConversationResponse.No ||
    checkInReminderQuestionResponse === ConversationResponse.No
  ) {
    concernString = 'No';
  } else if (
    covidQuestionResponse === ConversationResponse.Yes ||
    checkInReminderQuestionResponse === ConversationResponse.Yes
  ) {
    concernString = 'Yes';
  } else if (
    covidConcernsFeatureFlag === false &&
    checkInReminderFeatureFlag === false
  ) {
    concernString = 'Not Set;';
  } else {
    concernString = 'Pending';
  }
  return concernString;
}

export function checkedInFormatter(cell: number, row: AppointmentForDashboard) {
  return getCheckedInColumnValue(
    cell,
    row.covidQuestionsResponse,
    row.checkInReminderResponse,
    row.mobileNumber,
  );
}

export function responseFormatter(
  cell: string,
  row: AppointmentForDashboard,
  rowIndex: any,
  featureFlagListArray: any,
) {
  if (row.mobileNumber) {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          {'Attending: ' +
            healthConcernFormatter(
              row.covidQuestionsResponse,
              row.checkInReminderResponse,
              featureFlagListArray.covidquestion,
              featureFlagListArray.checkInReminderQuestion,
            )}
        </Popover.Content>
      </Popover>
    );
    return (
      <>
        <FontAwesomeIcon
          icon={faCalendar}
          size="lg"
          color="black"
          data-testid="appointmentIcon"
        />
        <span> </span>
        <OverlayTrigger placement="right" overlay={popover}>
          {getCovidResponseIcon(row, true)}
        </OverlayTrigger>
      </>
    );
  } else {
    return (
      <>
        <FontAwesomeIcon
          icon={faCalendar}
          size="lg"
          color="gray"
          data-testid="appointmentIcon"
        />
        <span> </span>
      </>
    );
  }
}

export function responseSortFormatter(
  cell: string,
  row: AppointmentForDashboard,
) {
  if (
    row.covidQuestionsResponse === ConversationResponse.No ||
    row.checkInReminderResponse === ConversationResponse.No
  ) {
    return 2;
  } else if (
    row.covidQuestionsResponse === ConversationResponse.Yes ||
    row.checkInReminderResponse === ConversationResponse.Yes
  ) {
    return 1;
  } else {
    return 0;
  }
}

export function actionsFormatter(
  cell: string,
  row: AppointmentForDashboard,
  rowIndex: any,
  setAppointmentStatus: any
) {
  if (row.status !== AppointmentStatus.Completed) {
    return (
      <DashboardActionButton
        row={row}
        setAppointmentStatus={setAppointmentStatus}
      />
    );
  } else {
    return <></>;
  }
}

export function sortCaretFormatter(order: any, column: any) {
  if (!order)
    return (
      <span className="appointmentSortCaret" style={{ color: '#ccc' }}>
        {' '}
        <FontAwesomeIcon icon={faSort} />
      </span>
    );
  else if (order === 'asc' || order === 'desc')
    return (
      <span className="appointmentSortCaret" style={{ color: 'black' }}>
        {' '}
        <FontAwesomeIcon icon={order === 'asc' ? faSortUp : faSortDown} />
      </span>
    );
  return null;
}
