import { Col, Row } from 'react-bootstrap';
import {
  EndorsementItem,
  ExamSummary,
  RestrictionItem,
  RoadTestDemeritItem,
} from '../../../types/ExamSummary';

export interface ExamSummaryModalHtmlProps {
  examSummary: ExamSummary;
}

function ExamSummaryModalHtml({ examSummary }: ExamSummaryModalHtmlProps) {
  const customerNotes = examSummary.notes.split('\n');
  const internalNotes =
    examSummary.internalNotes !== null
      ? examSummary.internalNotes.split('\n')
      : [];

  function endorsementsRestrictionsList(
    list: EndorsementItem[] | RestrictionItem[],
  ) {
    return list.map((data, index) => (
      <span key={data.code} title={data.description + '\n' + data.details}>
        {data.code}
        {/*do not show comma for last element*/}
        {list.length - 1 !== index && `, `}
      </span>
    ));
  }

  function formatExamDate(examDate: string) {
    if (!examDate) return '';
    const date = new Date(examDate);
    return (
      date.toLocaleString('default', {
        month: 'long',
      }) +
      ' ' +
      date.getDate() +
      ', ' +
      date.getFullYear()
    );
  }

  const demeritCompare = (a: RoadTestDemeritItem, b: RoadTestDemeritItem) => {
    if (a.demeritCategory < b.demeritCategory) {
      return -1;
    }

    if (a.demeritCategory > b.demeritCategory) {
      return 1;
    }

    if (a.demeritDescription < b.demeritDescription) {
      return -1;
    }

    if (a.demeritDescription > b.demeritDescription) {
      return 1;
    }

    return 0;
  };

  function demeritList(list: RoadTestDemeritItem[]) {
    list.sort(demeritCompare);
    const groupedDemerits = list.reduce((group: any, demerit: any) => {
        const { demeritCategory, demeritDescription } = demerit;

        const key = `${demeritCategory} - ${demeritDescription}`;
        if (group[key] == undefined) group[key] = [];

        group[key].push(demerit);

        return group;
      }, {});

    const gdArr = Object.entries(groupedDemerits);

    return gdArr.map((data: any) => (
      <Row>
        <Col>
          <Row>
            <Col lg={12}>
              <b>{data[0]}</b>
              <br />
              {data[1].map((demerit: any) => (
                <>
                  <span
                    key={demerit.demeritDateTime}
                    className="examSummaryText"
                    style={{ fontSize: '.8rem' }}
                  >
                    {demerit.address}
                  </span>
                  {demerit.demeritNote.replaceAll(/\s/g, '') !== '' && (
                    <span style={{ fontSize: '.9rem', color: '#999999' }}>
                      {' '}
                      - {demerit.demeritNote}
                    </span>
                  )}
                  <br />
                </>
              ))}
            </Col>
          </Row>
          <hr className="mt-0" />
        </Col>
      </Row>
    ));
  }

  const customerNotesMargin = examSummary.isExamPassed ? "pt-5" : "";
  return (
    <>
      <Row className="pt-3">
        <Col>
          <Row>
            <Col>
              <h5>Customer Information</h5>
              <hr className="mt-0" />
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Email Address:</b>
              <br />
              <span className="examSummaryText">
                {examSummary.customer.emailAddress === ''
                  ? 'No email address found'
                  : examSummary.customer.emailAddress}
              </span>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col>
              <h5>Road Test Information</h5>
              <hr className="mt-0" />
            </Col>
          </Row>

          <Row>
            <Col>
              <b>Examiner Number:</b>
              <br />
              <span className="examSummaryText">
                {examSummary.examiner.examinerNumber}
              </span>
            </Col>
            <Col>
              <b>Transmission Type:</b>
              <br />
              <span className="examSummaryText">
                {examSummary.roadTest.vehicleTransmissionType === 'AT'
                  ? 'Automatic'
                  : 'Manual'}
              </span>
            </Col>
          </Row>

          <Row className="pt-5">
            <Col>
              <h5>Driver Training Information</h5>
              <hr className="mt-0" />
            </Col>
          </Row>

          <Row>
            <Col>
              <b>Driver Education:</b>
              <br />
              <span className="examSummaryText">
                {examSummary.customer.driverEducation === 'None'
                  ? ''
                  : examSummary.customer.driverEducation}
              </span>
            </Col>
            <Col>
              <b>Driver Education Completed Date:</b>
              <br />
              <span className="examSummaryText">
                {examSummary.customer.driverEducation === 'Waived'
                  ? 'NA'
                  : formatExamDate(
                      examSummary.customer.dateDriverEducationCompleted,
                    )}
              </span>
            </Col>
          </Row>

          <Row className="pt-5">
            <Col>
              <h5>Demerits</h5>
              <hr className="mt-0" />
            </Col>
          </Row>
          <Row>
            <Col>{demeritList(examSummary.roadTestDemerits)}</Col>
          </Row>
        </Col>

        <Col className="offset-lg-1" lg={6}>
          {examSummary.isExamPassed && (
            <>
              <Row>
                <Col>
                  <h5>License to Issue Information</h5>
                  <hr className="mt-0" />
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>GDL Level:</b>
                  <br />
                  <span className="examSummaryText">
                    {examSummary.licenseToIssue.gdlLevel}
                  </span>
                </Col>
                <Col>
                  <b>License Class:</b>
                  <br />
                  <span className="examSummaryText">
                    {examSummary.licenseToIssue.licenseClass}
                  </span>
                </Col>
              </Row>

              <Row className="pt-3">
                <Col>
                  <b>Endorsements Added:</b>
                  <br />
                  <span className="examSummaryText">
                    {endorsementsRestrictionsList(
                      examSummary.licenseToIssue.endorsementsAdded,
                    )}
                  </span>
                </Col>
                <Col>
                  <b>Restrictions Added:</b>
                  <br />
                  <span className="examSummaryText">
                    {endorsementsRestrictionsList(
                      examSummary.licenseToIssue.restrictionsAdded,
                    )}
                  </span>
                </Col>
              </Row>

              <Row className="pt-3">
                <Col>
                  <b>Endorsements Removed:</b>
                  <br />
                  <span className="examSummaryText">
                    {endorsementsRestrictionsList(
                      examSummary.licenseToIssue.restrictionsRemoved,
                    )}
                  </span>
                </Col>
              </Row>
            </>
          )}

          
          <Row className={customerNotesMargin}>
            <Col>
              <h5>Customer Notes</h5>
              <hr className="mt-0" />
              {customerNotes.map(note => (
                <div className="note-wrap" key={note}>{note}</div>
              ))}
            </Col>
          </Row>

          <Row className="pt-5">
            <Col>
              <h5>Internal Notes</h5>
              <hr className="mt-0" />
            </Col>
          </Row>

          <Row>
            <Col>
              {internalNotes.map(note => (
                <div className="note-wrap" key={note}>{note}</div>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ExamSummaryModalHtml;
