import BootstrapTable, { PaginationOptions } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { FeatureFlagContext } from 'store/FeatureFlagContext';
import { useContext } from 'react';
import { isMobile } from 'react-device-detect';

import { AppointmentForDashboard } from 'types/appointment';
import {
  timeFormatter,
  customerFormatter,
  serviceTypeFormatter,
  checkedInFormatter,
  nameSortFormatter,
  timeSortFormatter,
  responseFormatter,
  responseSortFormatter,
  actionsFormatter,
  sortCaretFormatter,
} from './DashboardAppointmentTableFormatters';

interface DashboardAppointmentTableProps {
  appointments: AppointmentForDashboard[];
  setAppointmentStatus: (appointmentId: string, status: number) => void;
}

function DashboardAppointmentTable(props: DashboardAppointmentTableProps) {
  const appointments = props.appointments;
  const featureFlagItems = useContext(FeatureFlagContext);
  const covidConcernsFeatureFlagItem = featureFlagItems.featureFlagList?.find( f => f.featureFlagName === 'CovidGuidelineNotification');
  const checkInReminderFeatureFlagItem = featureFlagItems.featureFlagList?.find( f => f.featureFlagName === 'AskForAttendingReplyNotification');
    
  function noDataIndication() {
    return 'There are no appointments that match the filter criteria.';
  }

  const columns = [
    {
      dataField: 'df1', // dummy value since this column will display multiple values
      text: 'Date',
      formatter: timeFormatter,
      classes: 'dateTime',
      headerClasses: 'dateTime',
      sort: true,
      sortCaret: sortCaretFormatter,
      sortValue: timeSortFormatter,
    },
    {
      dataField: 'df2', // dummy value since this column will display multiple values
      isDummyField: true,
      text: 'Customer',
      formatter: customerFormatter,
      classes: 'customer',
      headerClasses: 'customer',
      sort: true,
      sortValue: nameSortFormatter,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'df3',
      isDummyField: true,
      text: 'Service',
      formatter: serviceTypeFormatter,
      classes: 'serviceType',
      headerClasses: 'serviceType',
      sort: true,
      sortValue: serviceTypeFormatter,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'df4',
      isDummyField: true,
      text: 'Responses',
      formatter: responseFormatter,
      classes: 'responses',
      headerClasses: 'responses',
      sort: true,
      sortValue: (cell: string, row: AppointmentForDashboard) =>
        responseSortFormatter(cell, row),
      sortCaret: sortCaretFormatter,
      formatExtraData: {covidquestion: covidConcernsFeatureFlagItem?.featureFlagValue ?? false, 
                        checkInReminderQuestion: checkInReminderFeatureFlagItem?.featureFlagValue ?? false},
    },
    {
      dataField: 'status',
      text: 'Here',
      formatter: checkedInFormatter,
      classes: 'checkedIn',
      headerClasses: 'checkedIn',
      sort: true,
      sortValue: checkedInFormatter,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'examinerName',
      text: 'Examiner',
      classes: 'examinerNumber',
      headerClasses: 'examinerNumber',
      sort: true,
      hidden: isMobile ? true : false,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'location.name',
      text: 'Location',
      classes: 'location',
      headerClasses: 'location',
      sort: true,
      hidden: isMobile ? true : false,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      csvExport: false,
      sort: false,
      classes: 'actions',
      headerClasses: 'actions',
      formatter: actionsFormatter,
      formatExtraData: props.setAppointmentStatus,
    },
  ];

  const paginationOptions: PaginationOptions = {
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPage: 15,
    sizePerPageList: [10, 15, 20, 25],
  };

  const classCancelledAppointment = (row: any, rowIndex: number) => {
    return row.status === 2 ? 'cancelledAppointment' : '';
  };

  return (
    <BootstrapTable
      id="dashboardAppointmentTable"
      bordered={false}
      striped={false}
      hover={true}
      keyField="appointmentId"
      data={appointments}
      columns={columns}
      bootstrap4={true}
      noDataIndication={noDataIndication}
      defaultSorted={[{ dataField: 'appointmentDate', order: 'asc' }]}
      defaultSortDirection="asc"
      pagination={paginationFactory(paginationOptions)}
      rowClasses={classCancelledAppointment}
      classes="table-striped"
    />
  );
}

export default DashboardAppointmentTable;
