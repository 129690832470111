export const RuntimeConfigPath = '/config.json';
export const AppointmentEndpointBaseUrl = 'dashboard/appointments';
export const ExaminerEndpointBaseUrl = 'examiner/examiners';
export const ExamsListEndpoint = 'exams';
export const ExamPdfEndpoint = 'documents/customersummary';
export const ExamPdfCustomerEndpoint = 'documents/customersummarybycustomerid';
export const CheckInReportExcelEndPoint = "report/totaltests";
export const ScoringReportExcelEndPoint = "report/getscoringreport";
export const ScoringReportJsonEndPoint = "report/getjsonscoringreport";
export const ExamSummary = 'documents/examsummary';
export const FeatureFlagEndpoint = 'featureflag/featureflags';
export const SendSummaryEmail = 'documents/sendexamsummary'
export const AuthManagementEndpointBaseUrl = 'auth';

// Demo endpoints
export const DemoAppointmentEndpointBaseUrl = 'demo/appointments';
export const ReminderPageAppointmentListEndpoint = 'demo/appointments/location';
export const WhiteListNumbersEndpointBaseUrl = 'demo/whitelistnumbers';
export const LocationsBaseUrl = 'locations';
