import React from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { msalInstance, initializeMsalInstance } from './auth/initializeMsal';
import { useHistory } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './scss/App.scss';
import './styles/custom.css';
import { CustomNavigationClient } from './utility/NavigationClient';
import { DriverCheckInProvider } from './app/DriverCheckInProvider';
import { RuntimeConfigPath } from './properties/appProperties';
import { getScopes } from './auth/authConfig';
import SplashScreen from './app/components/SplashScreen';
import {
  initializeB2cMsalInstance,
  msalB2cInstance,
} from './auth/initializeMsalCoreServicesAuth';
import { getB2cScopes } from './auth/authConfigCoreServiceAuth';

declare global {
  interface Window {
    runConfig: {
      REACT_APP_API_BASEURL: string;
      REACT_APP_ENVIRONMENT: string;
      REACT_APP_HIDE_DEMO: boolean;
      REACT_APP_AD_CLIENT_ID: string;
      REACT_APP_SCORING_UI_BASEURL: string;
      REACT_APP_AD_AUTHORITY: string;
      REACT_APP_AD_REDIRECT_URL: string;
      REACT_APP_AD_POST_LOGOUT_REDIRECT_URL: string;
      REACT_APP_APPINSIGHTS_INSTR_KEY: string;
      REACT_APP_MSAL_CLIENT_ID: string;
      REACT_APP_MSAL_TENANT_ID: string;
      REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: string;
      REACT_APP_MSAL_FORGOTPASSWORD_POLICY: string;
      REACT_APP_MSAL_EDITPROFILE_POLICY: string;
      REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: string;
      REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: string;
      REACT_APP_BASEURL: string;
      REACT_APP_BFF_API_SCOPES: string;
      REACT_APP_USE_CORE_SERVICES_AUTH: boolean;
    };
    SPA_CONFIG_LOADED: boolean;
  }
}

function AppProvider() {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  if (window['runConfig'].REACT_APP_USE_CORE_SERVICES_AUTH) {
    msalB2cInstance.setNavigationClient(navigationClient);
  } else {
    msalInstance.setNavigationClient(navigationClient);
  }
  return (
    <MsalProvider
      instance={
        window['runConfig'].REACT_APP_USE_CORE_SERVICES_AUTH
          ? msalB2cInstance
          : msalInstance
      }
    >
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        loadingComponent={() => <SplashScreen text="Initializing" />}
        errorComponent={({ error }) => (
          <SplashScreen text={error?.message} error={true} />
        )}
        authenticationRequest={
          window['runConfig'].REACT_APP_USE_CORE_SERVICES_AUTH
            ? { scopes: getB2cScopes() }
            : { scopes: getScopes() }
        }
      >
        <DriverCheckInProvider />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

const renderApp = () => {
  if (window.SPA_CONFIG_LOADED === true) {
    if (window['runConfig'].REACT_APP_USE_CORE_SERVICES_AUTH) {
      initializeB2cMsalInstance();
    } else {
      initializeMsalInstance();
    }
    ReactDOM.render(
      <React.StrictMode>
        <ErrorBoundary>
          <AppProvider />
        </ErrorBoundary>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  }
};

try {
  fetch(RuntimeConfigPath)
    .then(response => {
      return response.json();
    })
    .then(data => {
      window.runConfig = {
        REACT_APP_API_BASEURL: data.REACT_APP_API_BASEURL,
        REACT_APP_ENVIRONMENT: data.REACT_APP_ENVIRONMENT,
        REACT_APP_HIDE_DEMO: data.REACT_APP_HIDE_DEMO,
        REACT_APP_AD_CLIENT_ID: data.REACT_APP_AD_CLIENT_ID,
        REACT_APP_SCORING_UI_BASEURL: data.REACT_APP_SCORING_UI_BASEURL,
        REACT_APP_AD_AUTHORITY: data.REACT_APP_AD_AUTHORITY,
        REACT_APP_AD_REDIRECT_URL: data.REACT_APP_AD_REDIRECT_URL,
        REACT_APP_AD_POST_LOGOUT_REDIRECT_URL:
          data.REACT_APP_AD_POST_LOGOUT_REDIRECT_URL,
        REACT_APP_APPINSIGHTS_INSTR_KEY: data.REACT_APP_APPINSIGHTS_INSTR_KEY,
        REACT_APP_MSAL_CLIENT_ID: data.REACT_APP_MSAL_CLIENT_ID,
        REACT_APP_MSAL_TENANT_ID: data.REACT_APP_MSAL_TENANT_ID,
        REACT_APP_MSAL_SIGNUPSIGNIN_POLICY:
          data.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY,
        REACT_APP_MSAL_FORGOTPASSWORD_POLICY:
          data.REACT_APP_MSAL_FORGOTPASSWORD_POLICY,
        REACT_APP_MSAL_EDITPROFILE_POLICY:
          data.REACT_APP_MSAL_EDITPROFILE_POLICY,
        REACT_APP_MSAL_POLICY_AUTHORITY_ROOT:
          data.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT,
        REACT_APP_MSAL_B2C_AUTHORITYDOMAIN:
          data.REACT_APP_MSAL_B2C_AUTHORITYDOMAIN,
        REACT_APP_BASEURL: data.REACT_APP_BASEURL,
        REACT_APP_BFF_API_SCOPES: data.REACT_APP_BFF_API_SCOPES,
        REACT_APP_USE_CORE_SERVICES_AUTH: data.REACT_APP_USE_CORE_SERVICES_AUTH,
      };
      window.SPA_CONFIG_LOADED = true;
      renderApp();
    });

  if (window['runConfig'].REACT_APP_AD_CLIENT_ID === 'undefined') {
    throw new Error(
      'REACT_APP_AD_CLIENT_ID is undefined. Check the configuration.',
    );
  }
} catch (error) {
  console.log('There was a problem initializing the application: E5201');
  console.log(error);
  window.SPA_CONFIG_LOADED = false;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
