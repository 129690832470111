import 'styles/exam.css';
import BootstrapTable, { PaginationOptions } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Exam } from 'types/Exam';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import {
  customerFormatter,
  actionsFormatter,
  dateFormatter,
  sortCaretFormatter,
  examinerFormatter,
  scoreFormatter,
  examSummaryFormatter,
  examTypeFormatter,
  emailButtonFormatter
} from './ExamTableFormatters';

interface ExamTableProps {
  exams: Exam[];
}

function ExamsTable(props: ExamTableProps) {
  const exams = props.exams;

  function noDataIndication() {
    return 'There are no exams that match the search criteria.';
  }

  const columns = [
    {
      dataField: 'examDate',
      text: 'Date',
      formatter: dateFormatter,
      classes: 'date',
      headerClasses: 'date',
      sort: true,
      sortCaret: sortCaretFormatter,
    },
    {
      dataField: 'Name',
      text: 'Name',
      classes: 'customer',
      headerClasses: 'customer',
      sort: false,
      formatter: customerFormatter,
    },
    {
      dataField: 'examType',
      text: 'Service',
      classes: 'examType',
      headerClasses: 'examType',
      sort: true,
      sortCaret: sortCaretFormatter,
      formatter: examTypeFormatter,
    },
    {
      dataField: 'examiner',
      text: 'Examiner',
      classes: 'examiner',
      headerClasses: 'examiner',
      sort: true,
      sortCaret: sortCaretFormatter,
      formatter: examinerFormatter,
    },
    {
      dataField: 'Result',
      text: 'Result',
      classes: 'result',
      headerClasses: 'result',
      sort: true,
      sortCaret: sortCaretFormatter,
      formatter: scoreFormatter,
    },
    {
      dataField: 'PDF',
      text: 'PDF',
      classes: 'action-pdf',
      headerClasses: 'action-pdf',
      sort: false,
      formatter: actionsFormatter,
    },
    {
      dataField: 'Summary',
      text: 'Summary',
      classes: 'action-summary',
      headerClasses: 'action-summary',
      sort: false,
      formatter: examSummaryFormatter,
    },
    {
      isDummyField: true,
      text: "Email",
      dataField: 'emailAciton',
      classes: 'action-email',
      headerClasses: 'action-email',
      sort: false,
      formatter: emailButtonFormatter 
    }
  ];

  const paginationOptions: PaginationOptions = {
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPage: 15,
    sizePerPageList: [10, 15, 20, 25],
  };

  return (
    <BootstrapTable
      id="examsTable"
      bordered={false}
      striped={false}
      hover={true}
      classes="table-striped"
      keyField="roadTestExamId"
      data={exams}
      columns={columns}
      bootstrap4={true}
      noDataIndication={noDataIndication}
      defaultSorted={[{ dataField: 'examDate', order: 'desc' }]}
      defaultSortDirection="desc"
      pagination={paginationFactory(paginationOptions)}
    />
  );
}

export default ExamsTable;
