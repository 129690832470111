import { useReducer, useState, useEffect } from 'react';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import { EnvironmentVariableConfig } from 'types/EnvironmentVariableConfig';
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { ai } from 'AppInsights';
import { FeatureFlagModel } from 'types/featureFlagModel';
import { getFeatureFlags } from 'api/featureFlagApi';
import { FeatureFlagContext } from 'store/FeatureFlagContext';
import { DriverCheckInContext } from 'store/DriverCheckInContext';
import { driverCheckInReducers } from 'reducers/driverCheckInReducers';
import { initialDriverCheckInState } from 'types/DriverCheckInState';
import SplashScreen from './components/SplashScreen';
import App from './App';
import { SecurityFlags } from 'types/SecurityFlags';
import { getUserPermissions } from 'api/authManagementAPI';
import { UserPermissionsContext } from 'store/UserPermissionsContext';

export function DriverCheckInProvider() {
  const [state, dispatch] = useReducer(
    driverCheckInReducers,
    initialDriverCheckInState,
  );
  const [reactPlugIn, setReactPlugIn] = useState<ReactPlugin>(
    {} as ReactPlugin,
  );
  const [featureFlagList, setFeatureFlagList] = useState<FeatureFlagModel>(
    {} as FeatureFlagModel,
  );
  const [securityFlagList, setSecurityFlagList] = useState<SecurityFlags>(
    {} as SecurityFlags,
  );
  const [loading, setLoading] = useState({
    featureFlags: true,
    appInsights: true,
    securityFlags: true,
  });
  const RootStateInit = () => {
    useEffect(() => {
      getFeatureFlags(window['runConfig'].REACT_APP_API_BASEURL).then(data => {
        setFeatureFlagList(data);
        setLoading({ ...loading, featureFlags: false });
      });

      ai.initialize(window['runConfig'].REACT_APP_APPINSIGHTS_INSTR_KEY)
        .then(({ reactPlugin }) => {
          setReactPlugIn(reactPlugin);
          setLoading({ ...loading, appInsights: false });
        })
        .catch(() => {
          console.log(
            'There was a problem initializing the application: E5200',
          );
        });

      getUserPermissions(window['runConfig'].REACT_APP_API_BASEURL)
        .then(data => {
          setSecurityFlagList(data);
          setLoading({ ...loading, securityFlags: false });
        })
        .catch(() => {
          console.log(
            'There was a problem initializing User Security Permissions',
          );
        });
      console.log('RootstateInit running...');
    }, []);

    return (
      <SplashScreen
        text="Loading Data"
        show={
          loading.featureFlags && loading.appInsights && loading.securityFlags
        }
      />
    );
  };

  let config: EnvironmentVariableConfig = {
    REACT_APP_API_BASEURL: window['runConfig'].REACT_APP_API_BASEURL,
    REACT_APP_ENVIRONMENT: window['runConfig'].REACT_APP_ENVIRONMENT,
    REACT_APP_HIDE_DEMO: window['runConfig'].REACT_APP_HIDE_DEMO,
    REACT_APP_AD_CLIENT_ID: window['runConfig'].REACT_APP_AD_CLIENT_ID,
    REACT_APP_SCORING_UI_BASEURL:
      window['runConfig'].REACT_APP_SCORING_UI_BASEURL,
    REACT_APP_AD_AUTHORITY: window['runConfig'].REACT_APP_AD_AUTHORITY,
    REACT_APP_AD_REDIRECT_URL: window['runConfig'].REACT_APP_AD_REDIRECT_URL,
    REACT_APP_AD_POST_LOGOUT_REDIRECT_URL:
      window['runConfig'].REACT_APP_AD_POST_LOGOUT_REDIRECT_URL,
    REACT_APP_APPINSIGHTS_INSTR_KEY:
      window['runConfig'].REACT_APP_APPINSIGHTS_INSTR_KEY,
    REACT_APP_MSAL_CLIENT_ID: window['runConfig'].REACT_APP_MSAL_CLIENT_ID,
    REACT_APP_MSAL_TENANT_ID: window['runConfig'].REACT_APP_MSAL_TENANT_ID,
    REACT_APP_MSAL_SIGNUPSIGNIN_POLICY:
      window['runConfig'].REACT_APP_MSAL_SIGNUPSIGNIN_POLICY,
    REACT_APP_MSAL_FORGOTPASSWORD_POLICY:
      window['runConfig'].REACT_APP_MSAL_FORGOTPASSWORD_POLICY,
    REACT_APP_MSAL_EDITPROFILE_POLICY:
      window['runConfig'].REACT_APP_MSAL_EDITPROFILE_POLICY,
    REACT_APP_MSAL_POLICY_AUTHORITY_ROOT:
      window['runConfig'].REACT_APP_MSAL_POLICY_AUTHORITY_ROOT,
    REACT_APP_MSAL_B2C_AUTHORITYDOMAIN:
      window['runConfig'].REACT_APP_MSAL_B2C_AUTHORITYDOMAIN,
    REACT_APP_BASEURL: window['runConfig'].REACT_APP_BASEURL,
    REACT_APP_BFF_API_SCOPES: window['runConfig'].REACT_APP_BFF_API_SCOPES,
    REACT_APP_USE_CORE_SERVICES_AUTH:
      window['runConfig'].REACT_APP_USE_CORE_SERVICES_AUTH,
  };

  return loading.featureFlags &&
    loading.appInsights &&
    loading.securityFlags ? (
    <RootStateInit />
  ) : (
    <EnvironmentVariableContext.Provider value={config}>
      <AppInsightsContext.Provider value={reactPlugIn}>
        <FeatureFlagContext.Provider value={featureFlagList}>
          <DriverCheckInContext.Provider value={{ state, dispatch }}>
            <UserPermissionsContext.Provider value={securityFlagList}>
              <App />
            </UserPermissionsContext.Provider>
          </DriverCheckInContext.Provider>
        </FeatureFlagContext.Provider>
      </AppInsightsContext.Provider>
    </EnvironmentVariableContext.Provider>
  );
}
