import { initialDriverCheckInState, ScreenType } from "../types/DriverCheckInState";

export enum ScreenReducerActions {
    SET_SCREEN = "SET_SCREEN",
    RESET_SCREEN = "RESET_SCREEN",
}

export type ScreenReducerActionType = {
    type: ScreenReducerActions.SET_SCREEN
    value: ScreenType
} | {
    type: ScreenReducerActions.RESET_SCREEN
}

export function screenReducer(state: ScreenType, action: ScreenReducerActionType) {
    switch (action.type) {
        case ScreenReducerActions.SET_SCREEN:
            return {
                ...state,
                screen: action.value
            };
        case ScreenReducerActions.RESET_SCREEN:
            return {
                ...state,
                screen: initialDriverCheckInState.screen
            };
        default:
            return state;
    }
}