import 'styles/exam.css';
import React from 'react';
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { getExamPdfByCustomerId } from 'api/examsAPI';

interface ExamDownloadPdfProps {
    customerNumber: React.RefObject<HTMLInputElement>;
}

function ExamDownloadPdf(props: ExamDownloadPdfProps) {
    const environmentVariableContext = useContext(EnvironmentVariableContext);
    const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;
    const [showDownloadSpinner, setShowDownloadSpinner] = useState(false);
    function displayCustomerSummaryPDF(examPdf: any) {
        const url = window.URL.createObjectURL(new Blob([examPdf], { type: 'application/pdf' }));
        window.open(url);
    }

    async function handleDownload() {
        const custNum = props.customerNumber.current?.value;
        if(!custNum) {
            toast.error("Customer number cannot empty!");    
            return;
        }
        setShowDownloadSpinner(true);
        getExamPdfByCustomerId(apiHost, custNum)
        .then(examPdf => {
        setShowDownloadSpinner(false);
        toast.success("Successfully downloaded pdf!");
        displayCustomerSummaryPDF(examPdf)
        })
        .catch(error => {      
        setShowDownloadSpinner(false);
        toast.error("Error in downloading Pdf! " + error);
        });
    }

    return (
        <>
        {showDownloadSpinner ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              downloading...
            </Button>
            ) : (
            <Button
              variant="primary"
              style={{marginLeft: "20px" }}
              onClick={() =>
                handleDownload()
              }
            >
              Download
            </Button>
          )}
        </>
        )
};

export default ExamDownloadPdf;