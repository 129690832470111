import {
  ExamsListEndpoint, 
  ExamPdfEndpoint, 
  ExamPdfCustomerEndpoint, 
  ExamSummary,
  SendSummaryEmail
 } from "../properties/appProperties";
import { HttpMethod, sendAuthorizedRequest } from "./authWrapper";

export function getExamsByCustomerNumber(host: string, customerNumber: string) {
  let examsEndpoint = host + ExamsListEndpoint + '?customerId=' + customerNumber;  
  return sendAuthorizedRequest(HttpMethod.GET, examsEndpoint);
}

export function getExamPdfByExamId(host: string, examId: string) {
  let examPdfEndpoint = host + ExamPdfEndpoint + '/' + examId;  
  return sendAuthorizedRequest(HttpMethod.GET, examPdfEndpoint, null, 'blob');
}

export function getExamPdfByCustomerId(host: string, customerId: string) {
  let examPdfEndpoint = host + ExamPdfCustomerEndpoint + '/' + customerId;  
  return sendAuthorizedRequest(HttpMethod.GET, examPdfEndpoint, null, 'blob');
}

export function getExamSummaryByExamId(host: string, examId: string) {
  let examSummaryEndpoint = host + ExamSummary + '/' + examId;  
  return sendAuthorizedRequest(HttpMethod.GET, examSummaryEndpoint, null);
}

export function sendExamPdfToCustomer(host: string, examId: string){
  let sendExamResultEmailEndpoint = host + SendSummaryEmail + '/' + examId;
  return sendAuthorizedRequest(HttpMethod.POST, sendExamResultEmailEndpoint, null);
}