import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Route } from 'react-router-dom';
import Authorized from './Authorized';

export function PrivateRoute(props: any) {
    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
            <Authorized redirectOnUnAuthorized isAuthorized={props.isAuthorized}>
                <Route {...props} />
            </Authorized>
        </MsalAuthenticationTemplate>
    );
}

export default PrivateRoute;