import axios, { ResponseType } from 'axios';
import { handleError, handleResponse } from './apiUtils';
import { msalInstance } from 'auth/initializeMsal';
import { msalB2cInstance } from 'auth/initializeMsalCoreServicesAuth';
import { loginRequest } from 'auth/authConfig';
import { appInsights } from 'AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AUTHORIZEDREQUESTATTEMPTTRACEMSG } from 'properties/appInsightsProperties';
import { getB2cScopes } from 'auth/authConfigCoreServiceAuth';

export enum HttpMethod {
  GET,
  POST,
  PUT,
  DELETE,
}

export async function sendAuthorizedRequest(
  method: HttpMethod,
  url: string,
  body?: any,
  requestResponseType?: ResponseType,
) {
  const useCoreServicesAuth = (window as any)['runConfig']
    .REACT_APP_USE_CORE_SERVICES_AUTH;

  const account = useCoreServicesAuth
    ? msalB2cInstance.getActiveAccount()
    : msalInstance.getActiveAccount();
  if (!account) {
    axios.defaults.headers.common['Authorization'] = null;
    if (appInsights !== undefined) {
      appInsights.trackTrace({
        message: AUTHORIZEDREQUESTATTEMPTTRACEMSG,
        severityLevel: SeverityLevel.Warning,
      });
    }
    return;
  }

  const response = useCoreServicesAuth
    ? await msalB2cInstance.acquireTokenSilent({
        scopes: getB2cScopes(),
        account: account,
      })
    : await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });

  const token = response.accessToken;
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common['Authorization'] = null;
  }

  switch (method) {
    case HttpMethod.GET:
      if (requestResponseType) {
        return axios
          .get(url, { responseType: requestResponseType })
          .then(handleResponse)
          .catch(handleError);
      } else {
        return axios.get(url).then(handleResponse).catch(handleError);
      }
    case HttpMethod.POST:
      return axios.post(url, body).then(handleResponse).catch(handleError);
    case HttpMethod.PUT:
      return axios.put(url, body).then(handleResponse).catch(handleError);
    case HttpMethod.DELETE:
      return axios.delete(url).then(handleResponse).catch(handleError);
  }
}
