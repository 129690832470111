import { sendAuthorizedRequest, HttpMethod} from './authWrapper';
import { CheckInReportExcelEndPoint, ScoringReportExcelEndPoint, ScoringReportJsonEndPoint } from '../properties/appProperties';
export function downloadCheckInExcel(host: string, examinerId: string | undefined, startDate: string | undefined, endDate: string | undefined) {
    let _endPoint = host + CheckInReportExcelEndPoint;
    let id = examinerId ? examinerId : "All";
    _endPoint += "/" + id + "/" + startDate + "/" + endDate;
    return sendAuthorizedRequest(HttpMethod.GET, _endPoint, null, 'blob');
}

export function downloadScoringExcel(host: string, startDate: string | undefined, endDate: string | undefined, examinerId: string | undefined, locationId: string | undefined) {
    let _endPoint = host + ScoringReportExcelEndPoint;
    let _examinerId = examinerId ? examinerId : "All";
    let _locationId = locationId ? locationId : "All";
    _endPoint += "/" + startDate + "/" + endDate + "/" + _examinerId + "/" + _locationId;
    return sendAuthorizedRequest(HttpMethod.GET, _endPoint, null, 'blob');
}

export function downloadScoringJson(host: string, startDate: string | undefined, endDate: string | undefined) {
    let _endPoint = host + ScoringReportJsonEndPoint;
    _endPoint += "/" + startDate + "/" + endDate;
    return sendAuthorizedRequest(HttpMethod.GET, _endPoint, null);
}