type tMessageTypesDisplayFriendly = {
    [key: number]: string
}

export enum MessageTypes {
    InitialBooking = 0,
    OptOutInstruction = 1,
    OptOut = 2,
    OptIn = 3,
    AppointmentReminder = 4,
    VaccinationProofInitialReminder = 5,
    VaccinationProof24HoursReminder = 6,
    VideoConsent = 7,
    VideoConsentResponseYes = 8,
    VideoConsentResponseNo = 9,
    CheckInReminder = 10,
    CovidQuestions = 11,
    CovidQuestionsResponseYes = 12,
    CovidQuestionsResponseNo = 13,
    CheckInInstructions = 14,
    CheckInResponse = 15, 
    CustomerReadyForExaminer = 16,
    Unknown = 17,
    NoAppointmentResponse = 18,
    UnexpectedMessageResponse = 19,
    UnrecognizedNumberResponse = 20,
    AreYouAttendingQuestion = 21,
    CheckInReminderResponseYes = 22,
    CheckInReminderResponseNo = 23
}

export const MessageTypesDisplayFriendly: tMessageTypesDisplayFriendly = {
    0: "Initial Booking",
    1: "Opt-Out Instruction",
    2: "Opt-Out",
    3: "Opt-In",
    4: "Appointment Reminder",
    5: "Vaccination Proof Initial Reminder",
    6: "Vaccination Proof 24 Hours Reminder",
    7: "Video Consent",
    8: "Video Consent Response - Yes",
    9: "Video Consent Response - No",
    10: "Check-In Reminder",
    11: "Covid Questions",
    12: "Covid Questions Response - Yes",
    13: "Covid Questions Response - No",
    14: "Check-In Instructions",
    15: "Check-In Response",
    16: "Ready for Examiner",
    17: "Unknown",
    18: "No Appointment Response",
    19: "Unexpected Message Response",
    20: "Un-recognized Number Response",
    21: "Are You Attending Question",
    22: "CheckIn Reminder Response - Yes",
    23: "CheckIn Reminder Response - No"
}