import { sendAuthorizedRequest, HttpMethod} from './authWrapper';
import { ExaminerEndpointBaseUrl } from '../properties/appProperties';
import { Examiner } from '../types/Examiner';
import { ExaminerUpdate } from '../types/ExaminerUpdate';

export function getExaminers(host: string) {
    let examinerEndpoint = host + ExaminerEndpointBaseUrl;
    return sendAuthorizedRequest(HttpMethod.GET, examinerEndpoint);
}

export function updateExaminer(host: string, examiner: ExaminerUpdate) {
    let examinerEndpoint = host + ExaminerEndpointBaseUrl + '/' + examiner.examinerNumber;
    return sendAuthorizedRequest(HttpMethod.PUT, examinerEndpoint, examiner);
}

export function createExaminer(host: string, examiner: Examiner) {
    let examinerEndpoint = host + ExaminerEndpointBaseUrl;
    return sendAuthorizedRequest(HttpMethod.POST, examinerEndpoint, examiner);
}