import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Exam } from 'types/Exam';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { EXAMRESULTEXAMSUMMARY } from 'properties/appInsightsProperties';
import { useMsal } from '@azure/msal-react';
import { getExamSummaryByExamId } from 'api/examsAPI';
import { Button } from 'react-bootstrap';
import ExamSummaryModal from './ExamSummaryModal';
import ExamSummaryModalHtml from './ExamSummaryModalHtml';

interface ExamSummaryActionButtonProps {
  row: Exam;
}

export const ExamSummaryActionButton = (
  props: ExamSummaryActionButtonProps,
) => {
  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const appApiBaseUrl = environmentVariableContext.REACT_APP_API_BASEURL;
  const appInsights = useAppInsightsContext();
  const { accounts } = useMsal();

  const examSummaryLoadingContent = (
    <>
      Getting exam summary&nbsp;
      <div
        className="spinner-grow spinner-grow-sm text-danger"
        role="status"
      ></div>
      <div
        className="spinner-grow spinner-grow-sm text-warning"
        role="status"
      ></div>
      <div
        className="spinner-grow spinner-grow-sm text-success"
        role="status"
      ></div>
    </>
  );

  const [isExamSummaryModalOpen, setIsExamSummaryModalOpen] = useState(false);
  const [examSummaryModalContent, setExamSummaryModalContent] = useState(
    examSummaryLoadingContent,
  );

  function hideExamInfoModal() {
    setIsExamSummaryModalOpen(false);
    resetExamSummaryModalContent();
  }

  function resetExamSummaryModalContent() {
    setExamSummaryModalContent(examSummaryLoadingContent);
  }

  async function showExamSummaryModal() {
    setIsExamSummaryModalOpen(true);

    await getExamSummaryByExamId(appApiBaseUrl, props.row.roadTestExamId)
      .then(examSummary => {
        examSummary = JSON.parse(examSummary);
        setExamSummaryModalContent(
          <ExamSummaryModalHtml examSummary={examSummary} />,
        );
      })
      .catch(error => {
        setExamSummaryModalContent(<>Error getting exam summary</>);
      });

    if (appInsights !== undefined) {
      appInsights.trackEvent(
        { name: EXAMRESULTEXAMSUMMARY },
        {
          user: accounts[0].username,
          roadTestExamId: props.row.roadTestExamId,
        },
      );
    }
  }

  return (
    <>
      <Button
        className="btn btn-light btn-sm"
        style={{ background: 'transparent', border: 'none' }}
      >
        <span
          className="text-primary action-icon"
          style={{ fontSize: '1.5em' }}
        >
          <FontAwesomeIcon
            icon={faFileAlt}
            title="Show exam summary"
            key={props.row.roadTestExamId}
            onClick={e => showExamSummaryModal()}
          />
        </span>
      </Button>
      <ExamSummaryModal
        isOpen={isExamSummaryModalOpen}
        hideModal={hideExamInfoModal}
        examSummaryModalContent={examSummaryModalContent}
      />
    </>
  );
};
export default ExamSummaryActionButton;
