import { MessageChannelsDisplayFriendly } from 'enums/MessageChannels';
import { MessageTypesDisplayFriendly } from 'enums/MessageTypes';
import { formatUTCDateStringToDateTimeLocal } from 'utility/formatUTCDateStringToDateTimeLocal';
import { Conversation, ConversationMessage } from 'types/conversation';

interface CommunicationLogEntryProps {
  conversation: Conversation;
}

function CommunicationLogEntry({ conversation }: CommunicationLogEntryProps) {
  return (
    <div>
      {conversation.conversationMessages.map((m: ConversationMessage, i) => (
        <div className="row mb-3" key={i}>
          <div className="col-4">
            Channel: {MessageChannelsDisplayFriendly[m.messageChannel]}
            <br />
            Type: <b>{MessageTypesDisplayFriendly[m.messageType]}</b>
            <br />
            To: {m.toNumber}
            <br />
            From: {m.fromNumber}
            <br />
            Time: {formatUTCDateStringToDateTimeLocal(m.messageTime)}
          </div>
          <div className="col-8">
            Body:
            <br />
            {m.body}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CommunicationLogEntry;
