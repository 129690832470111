import { Configuration, LogLevel, PopupRequest } from "@azure/msal-browser";

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export function msalConfig(): Configuration {
    const REACT_APP_AD_CLIENT_ID = window['runConfig']?.REACT_APP_AD_CLIENT_ID;
    const REACT_APP_AD_AUTHORITY = window['runConfig']?.REACT_APP_AD_AUTHORITY;
    const REACT_APP_AD_REDIRECT_URL = window['runConfig']?.REACT_APP_AD_REDIRECT_URL ;
    const REACT_APP_AD_POST_LOGOUT_REDIRECT_URL = window['runConfig']?.REACT_APP_AD_POST_LOGOUT_REDIRECT_URL;
    return {
      auth: {
        clientId: REACT_APP_AD_CLIENT_ID,
        authority: REACT_APP_AD_AUTHORITY,
        knownAuthorities: [REACT_APP_AD_AUTHORITY],
        redirectUri: REACT_APP_AD_REDIRECT_URL,
        postLogoutRedirectUri: REACT_APP_AD_POST_LOGOUT_REDIRECT_URL
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                console.error(message);
                return;
            }
          },
        },
      },
    };
  }

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md
export const loginRequest: PopupRequest = {
    scopes: getScopes()
};

export function getScopes() {
    var scopes = ["api://90f2571f-efc7-41d8-9000-994120c896ee/BFF.API"];
    switch (window.location.host) {
        case "drivertestcheckin.sgicloud.ca":
            scopes = ["api://c4556646-da97-4f3b-89f5-9894ae07209f/BFF.API"];
            break;
        case "int.driverroadtest-checkin.sgicloud.ca":
            scopes = ["api://90f2571f-efc7-41d8-9000-994120c896ee/BFF.API"];
            break;
        case "qa.driverroadtest-checkin.sgicloud.ca":
            scopes = ["api://fc6cd9d7-d476-403e-b889-fa991157b12c/BFF.API"];
            break;
    }
    return scopes;
}