import { Exam } from 'types/Exam';
import ExamActionButton from './ExamActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ExamSummaryActionButton from './ExamSummaryActionButton';
import { formatClass5ServiceText } from 'utility/formatClass5ServiceText';
import ExamSendEmailActionButton from './ExamSendEmailActionButton';

export function customerFormatter(cell: string, row: Exam) {
  return (
    <>
      {row.customerFirstName} {row.customerLastName}
    </>
  );
}

export function examinerFormatter(cell: string, row: Exam) {
  return (
    <>
      {row.examinerName} {row.examinerNumber}
    </>
  );
}

export function scoreFormatter(cell: string, row: Exam) {
  return row.roadTestExamPassed ? 'Pass' : 'Fail';
}

export function actionsFormatter(cell: string, row: Exam, rowIndex: any) {
  return <ExamActionButton row={row} />;
}

export function dateFormatter(cell: string) {
  return getDateString(cell);
}

export function examSummaryFormatter(cell: string, row: Exam, rowIndex: any) {
  return <ExamSummaryActionButton row={row} />;
}

export function examTypeFormatter(cell: string, row: Exam, rowIndex: any) {
  return formatClass5ServiceText(cell);
}

export function emailButtonFormatter(cell: string, row: Exam, rowIndex: any){
  return <ExamSendEmailActionButton examId={row.roadTestExamId} />
}

export function sortCaretFormatter(order: any, column: any) {
  if (!order)
    return (
      <span style={{ color: '#ccc', whiteSpace: 'nowrap' }}>
        <FontAwesomeIcon icon={faSort} />
      </span>
    );
  else if (order === 'asc')
    return (
      <span style={{ color: 'black', whiteSpace: 'nowrap' }}>
        <FontAwesomeIcon icon={faSortUp} />
      </span>
    );
  else if (order === 'desc')
    return (
      <span style={{ color: 'black', whiteSpace: 'nowrap' }}>
        <FontAwesomeIcon icon={faSortDown} />
      </span>
    );
  return null;
}

export function getDateString(
  examDate: string,
  format: string = 'MMM DD, YYYY',
  timeZone?: string,
): string {
  let dateString = '';
  if (moment(examDate).isValid()) {
    if (timeZone && moment.tz.zone(timeZone)) {
      dateString = moment.utc(examDate).tz(timeZone).format(format);
    } else {
      dateString = moment.utc(examDate).local().format(format);
    }
  }
  return dateString;
}
