export function formatE164ToLocalPhoneNumber(phoneString: string): string {
  if (phoneString) {
    let match = phoneString.match(/^\+?\d(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  } else {
    return '';
  }
  return phoneString;
}
