import { useContext, useEffect, useState } from "react";
import { DriverCheckInContext } from "../store/DriverCheckInContext";
import { handleWindowScrollUp, handleWindowScrollDown } from "../eventHandlers/handleOnScreenKeyboardWindowScroll";
import { WindowOrientation } from "../enums/WindowOrientation";

function useOnScreenKeyboardWindowScroll(enableForIds: Array<string>) {
    const { state: globalState } = useContext(DriverCheckInContext);
    const [element, setElement] = useState<Element | null>();

    function isLandscape() {
        return (globalState.screen.screenOrientation === WindowOrientation.LandscapePrimary || globalState.screen.screenOrientation === WindowOrientation.LandscapeSecondary) ? true : false;
    }
    
    useEffect(() => {
        if (globalState.screen.onScreenKeyboard) {
            const activeElement = document.activeElement;
            if (activeElement) { 
                if (globalState.screen.onScreenKeyboard && enableForIds.includes(activeElement.id) && isLandscape() ) {
                    handleWindowScrollUp(activeElement, 15);
                    setElement(activeElement);
                }
            }
        } else {
            if (element) {
                if (globalState.screen.screenOrientation === WindowOrientation.LandscapePrimary || globalState.screen.screenOrientation === WindowOrientation.LandscapeSecondary) {
                    handleWindowScrollDown(element, 150);
                }
                setElement(null);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState.screen.onScreenKeyboard, enableForIds]);
}

export default useOnScreenKeyboardWindowScroll;