import { msalInstance } from "../auth/initializeMsal";
import { CurrentUser } from "../types/CurrentUser";
import { msalB2cInstance } from "./initializeMsalCoreServicesAuth";

interface IdTokenClaims {
    email?: string | undefined;
    roles: string[] | undefined;
  }

export function getCurrentUserAccountInfo(): CurrentUser {
    const useCoreServicesAuth = window['runConfig'].REACT_APP_USE_CORE_SERVICES_AUTH;
    const account =  useCoreServicesAuth ? msalB2cInstance.getActiveAccount() : msalInstance.getActiveAccount();
    let roles: string[] = [];
    let currentUserInfo: CurrentUser;
    if (account) {
        /* @ts-ignore */
        roles = account.idTokenClaims.roles;
        currentUserInfo = {
            username: useCoreServicesAuth ? (account.idTokenClaims as IdTokenClaims)?.email ?? '' : account.username,
            roles: roles,
        };
    }
    else {
        currentUserInfo = {
            username: undefined,
            roles: roles,
        }
    }
    return currentUserInfo
}