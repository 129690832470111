type tConversationResponseDisplayFriendly = {
    [key: number]: string
}

export enum ConversationResponse {
    Pending = 0,
    Yes = 1,
    No = 2,
}

export const ConversationResponseDisplayFriendly: tConversationResponseDisplayFriendly = {
    0: "Pending",
    1: "Yes",
    2: "No"
}