import { NavigationClient, NavigationOptions } from "@azure/msal-browser";

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient{
    history: any;

    constructor(history: any) {
        super();
        this.history = history;
    }

    /**
     * Navigates to other pages within the same web application
     * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
     * @param url 
     * @param options 
     */
    async navigateInternal(url: string, options: NavigationOptions) {
        const relativePath = url.replace(window.location.origin, '');
        if(this.history) {
            if (options.noHistory) {
                this.history.replace(relativePath);
            } else {
                this.history.push(relativePath);
            }
        }
        return false;
    }
}
