import PageNotAuthorized from "../pages/PageNotAuthorized";

interface AuthorizedParams {
    isAuthorized: boolean;
    redirectOnUnAuthorized?: boolean;
    children: JSX.Element;
}

const Authorized = ({isAuthorized, redirectOnUnAuthorized, children}: AuthorizedParams): JSX.Element => {
    if(isAuthorized){
        return children;
    }
    else if(redirectOnUnAuthorized){
        return <PageNotAuthorized />;
    }
    else {
        return <div></div>
    }
}

export default Authorized;