type tAppointmentStatusDisplayFriendly = {
    [key: number]: string
}

export enum AppointmentStatus {
    Open = 1,
    Cancelled = 2,
    CheckedIn = 3,
    Completed = 5,
    NoShow = 6,
    Postponed= 7
}

export const AppointmentStatusDisplayFriendly: tAppointmentStatusDisplayFriendly = {
    1: "Open",
    2: "Cancelled",
    3: "Checked In",
    5: "Completed",
    6: "No Show",
    7: "Postponed",
}