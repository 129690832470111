import { useEffect, useState, useContext } from 'react';
import { getExaminers, updateExaminer, createExaminer } from 'api/examinerAPI';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import { Examiner } from 'types/Examiner';
import { ExaminerUpdate } from 'types/ExaminerUpdate';
import Authorized from 'app/common/Authorized';
import { ExaminerFormValidation } from 'types/ExaminerFormValidation';
import { formatLocalPhoneNumberToE164 } from 'utility/formatLocalPhoneNumberToE164';
import { formatE164ToLocalPhoneNumber } from 'utility/formatE164ToLocalPhoneNumber';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { getCurrentUserAccountInfo } from 'auth/authUtilities';
import { ValidationErrorMessages } from 'enums/ValidationErrorMessages';
import {
  EXAMINERADDEDMODALEVENT,
  EXAMINERMODIFIEDMODALEVENT,
  EXAMINERVALIDATIONFAILEDMODALEVENT,
} from 'properties/appInsightsProperties';
import ExaminerModal from './ExaminerModal';
import { Button } from 'react-bootstrap';
import ExaminerTable from './ExaminerTable';
import ExaminerFilters from './ExaminerFilters';
import { Filter } from 'types/Filter';
import { UserPermissionsContext } from 'store/UserPermissionsContext';

function filterExaminers(
  examiners: Examiner[],
  examinerFilter?: Filter<Examiner>[],
) {
  if (!examinerFilter) return examiners;
  let filteredExaminers = examiners;
  examinerFilter.forEach(filter => {
    filteredExaminers = filteredExaminers.filter(examiner =>
      filter.values.includes(String(examiner[filter.field])),
    );
  });
  return filteredExaminers;
}

const defaultExaminerFilter: Filter<Examiner>[] = [
  {
    field: 'isActive',
    values: ['true'],
  },
];

function Examiners() {
  const [examiners, setExaminers] = useState<Examiner[]>([]);
  const [APIError, setAPIError] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const permissions = useContext(UserPermissionsContext);
  const [examinerFilter, setExaminerFilter] = useState<
    Filter<Examiner>[] | undefined
  >(defaultExaminerFilter);
  const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;
  const blankNewExaminer: Examiner = {
    examinerNumber: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    emailAddress: '',
    isActive: true,
  };
  const blankEditExaminer: ExaminerUpdate = {
    examinerNumber: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    emailAddress: '',
    oldExaminerNumber: '',
    isActive: true,
  };
  const [newExaminer, setNewExaminer] = useState<Examiner>(blankNewExaminer);
  const [editExaminer, setEditExaminer] =
    useState<ExaminerUpdate>(blankEditExaminer);
  const [isNewExaminer, setIsNewExaminer] = useState(true);
  const [formValidationErrors, setFormValidationErrors] =
    useState<ExaminerFormValidation>({});
  const [formValidated, setFormValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [examinerModalSubmitButtonParams, setExaminerModalSubmitButtonParams] =
    useState<{ text: string; disabled: boolean }>({
      text: '',
      disabled: false,
    });
  const currentUser = getCurrentUserAccountInfo();
  const appInsights = useAppInsightsContext();

  const filteredExaminers: Examiner[] = filterExaminers(
    examiners,
    examinerFilter,
  );

  function showAddModal() {
    setIsAddModalOpen(true);
  }
  function showEditModal() {
    setIsEditModalOpen(true);
  }

  function handleAddExaminerClick() {
    setExaminerModalSubmitButtonParams({ text: 'Add', disabled: false });
    setNewExaminer(blankNewExaminer);
    setIsNewExaminer(true);
    showAddModal();
  }

  function handleEditExaminerClick(examiner: Examiner) {
    setExaminerModalSubmitButtonParams({ text: 'Save', disabled: false });
    setFormValidationErrors({});
    setIsNewExaminer(false);
    setEditExaminer({
      ...examiner,
      oldExaminerNumber: examiner.examinerNumber,
    });
    setNewExaminer(examiner);
    showEditModal();
  }

  function hideAddModal() {
    setIsAddModalOpen(false);
    setFormValidated(false);
    setFormValidationErrors({});
  }
  function hideEditModal() {
    setIsEditModalOpen(false);
    setFormValidated(false);
    setFormValidationErrors({});
  }

  function formatNewExaminerDataForApiCall(formData: Examiner): Examiner {
    const formattedMobileNumber = formatLocalPhoneNumberToE164(
      formData.mobileNumber,
    );
    const formattedExaminerNumber = formData.examinerNumber.replace(/^0+/, '');
    return {
      ...newExaminer,
      mobileNumber: formattedMobileNumber,
      examinerNumber: formattedExaminerNumber,
    };
  }
  function formatNewExaminerDataForUpdateApiCall(
    formData: ExaminerUpdate,
  ): ExaminerUpdate {
    const formattedMobileNumber = formatLocalPhoneNumberToE164(
      formData.mobileNumber,
    );
    const formattedExaminerNumber = formData.examinerNumber.replace(/^0+/, '');
    return {
      ...editExaminer,
      mobileNumber: formattedMobileNumber,
      examinerNumber: formattedExaminerNumber,
      oldExaminerNumber: formData.oldExaminerNumber,
    };
  }

  function formatExaminerDataForDisplay(examinerData: Examiner): Examiner {
    if (!examinerData.mobileNumber) {
      return {
        ...examinerData,
        mobileNumber: '',
      };
    } else {
      const mobileNumberDisplayFormat = formatE164ToLocalPhoneNumber(
        examinerData.mobileNumber,
      );
      return {
        ...examinerData,
        mobileNumber: mobileNumberDisplayFormat,
      };
    }
  }

  function handleNewExaminerSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    if (!formIsValid()) return;
    setFormValidated(true);
    if (isNewExaminer) {
      setExaminerModalSubmitButtonParams({ text: 'Adding...', disabled: true });
      const examinerApiData = formatNewExaminerDataForApiCall(newExaminer);
      createExaminer(apiHost, examinerApiData)
        .then(response => {
          toast.success('New examiner added.');
          setExaminers([
            ...examiners,
            formatExaminerDataForDisplay(examinerApiData),
          ]);
          setNewExaminer(blankNewExaminer);
          setFormValidated(false);
          hideAddModal();
          if (appInsights !== undefined) {
            appInsights.trackEvent(
              { name: EXAMINERADDEDMODALEVENT },
              {
                user: currentUser.username,
                examinerNumber: examinerApiData.examinerNumber,
              },
            );
          }
        })
        .catch(error => {

          setExaminerModalSubmitButtonParams({ text: 'Add', disabled: false });
          const errorMsg = error?.response?.data;
          if (errorMsg && errorMsg.match(/The mobile number was invalid/)) {
            setFormValidationErrors({
              ...formValidationErrors,
              mobileNumber: ValidationErrorMessages.Mobile_Number_Invalid,
            });
            setFormValidated(false);
            toast.error('Error adding new examiner.  Mobile number not valid.');
          } else if (errorMsg && errorMsg.startsWith("Couldn't find a user")) {
            setFormValidated(false);
            toast.error('Error adding new examiner.  Could not find a domain user.');
          }
          else if (
            errorMsg &&
            errorMsg.match(/Examiner number already exists/)
          ) {
            setFormValidationErrors({
              ...formValidationErrors,
              examinerNumber: ValidationErrorMessages.Examiner_Number_Exists,
            });
            setFormValidated(false);
            toast.error(
              'Error adding new examiner.  Examiner number already exists.',
            );
          } else {
            toast.error('Error adding new examiner.  Try again.');
          }
        });
    } else {
      setExaminerModalSubmitButtonParams({
        text: 'Updating...',
        disabled: true,
      });
      const examinerApiData =
        formatNewExaminerDataForUpdateApiCall(editExaminer);
      updateExaminer(apiHost, examinerApiData)
        .then(response => {
          toast.success('Examiner updated.');
          const copyOfexaminers = [...examiners];
          const index = copyOfexaminers.findIndex(
            ex => ex.examinerNumber === examinerApiData.oldExaminerNumber,
          );
          copyOfexaminers.splice(
            index,
            1,
            formatExaminerDataForDisplay(examinerApiData),
          );
          setExaminers(copyOfexaminers);
          setNewExaminer(blankNewExaminer);
          setFormValidated(false);
          hideEditModal();
          if (appInsights !== undefined) {
            appInsights.trackEvent(
              { name: EXAMINERMODIFIEDMODALEVENT },
              {
                user: currentUser.username,
                examinerNumber: examinerApiData.examinerNumber,
              },
            );
          }
        })
        .catch(error => {
          setExaminerModalSubmitButtonParams({
            text: 'Update',
            disabled: false,
          });
          const errorMsg = error?.response?.data;
          if (errorMsg && errorMsg.match(/The mobile number was invalid/)) {
            setFormValidationErrors({
              ...formValidationErrors,
              mobileNumber: ValidationErrorMessages.Mobile_Number_Invalid,
            });
            setFormValidated(false);
            toast.error('Error updating examiner.  Mobile number not valid.');
          } else if (
            errorMsg &&
            errorMsg.match(/Examiner number already exists/)
          ) {
            setFormValidationErrors({
              ...formValidationErrors,
              examinerNumber: ValidationErrorMessages.Examiner_Number_Exists,
            });
            setFormValidated(false);
            toast.error(
              'Error adding new examiner.  Examiner number already exists.',
            );
          } else {
            toast.error('Error updating examiner.  Try again.');
          }
        });
    }
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    const value = event.target.name === 'isActive' ? (event.target as HTMLInputElement).checked : event.target.value;
    setNewExaminer({
      ...newExaminer,
      [event.target.name]: value,
    });
  }
  function handleEditModalChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    const value = event.target.name === 'isActive' ? (event.target as HTMLInputElement).checked : event.target.value;
    setEditExaminer({
      ...editExaminer,
      [event.target.name]: value,
    });
  }
  function formIsValid(): boolean {
    const validationErrors: ExaminerFormValidation = {};
    const validMobileNumberFormat = /^\((\d{3})\) (\d{3})-(\d{4})$/;
    const validExaminerNumberFormat = /^\d{3,5}$/;
    const validNameFormat =
      /^[A-Za-z\u{00C0}-\u{00FF}][A-Za-z'\u{00C0}-\u{00FF}-]+([ A-Za-z][A-Za-z'\u{00C0}-\u{00FF}-]+)*/u;
    // emailRegex based off https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const validEmailLocalPart =
      '(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))';
    const validEmailDomain =
      '((\\[\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))';
    const validEmailFormat = new RegExp(
      `^${validEmailLocalPart}@${validEmailDomain}$`,
    );
    if (isNewExaminer) {
      if (
        newExaminer.mobileNumber.length > 0 &&
        !newExaminer.mobileNumber.match(validMobileNumberFormat)
      )
        validationErrors.mobileNumber =
          ValidationErrorMessages.Mobile_Number_Format;
      if (
        examiners.some(x => x.examinerNumber === newExaminer.examinerNumber) ===
          true &&
        isNewExaminer
      )
        validationErrors.examinerNumber =
          ValidationErrorMessages.Examiner_Number_Exists;
      if (
        !newExaminer.examinerNumber
          .replace(/^0+/, '')
          .match(validExaminerNumberFormat)
      )
        validationErrors.examinerNumber =
          ValidationErrorMessages.Examiner_Number;
      if (!newExaminer.lastName.match(validNameFormat))
        validationErrors.lastName = ValidationErrorMessages.Last_Name;
      if (
        newExaminer.firstName.length > 0 &&
        !newExaminer.firstName.match(validNameFormat)
      )
        validationErrors.firstName = ValidationErrorMessages.First_Name;
      if (!newExaminer.emailAddress.match(validEmailFormat))
        validationErrors.emailAddress = ValidationErrorMessages.Email_Address;
    } else {
      if (
        editExaminer.mobileNumber.length > 0 &&
        !editExaminer.mobileNumber.match(validMobileNumberFormat)
      )
        validationErrors.mobileNumber =
          ValidationErrorMessages.Mobile_Number_Format;
      if (
        examiners.some(
          x => x.examinerNumber === editExaminer.examinerNumber,
        ) === true &&
        isNewExaminer
      )
        validationErrors.examinerNumber =
          ValidationErrorMessages.Examiner_Number_Exists;
      if (
        !editExaminer.examinerNumber
          .replace(/^0+/, '')
          .match(validExaminerNumberFormat)
      )
        validationErrors.examinerNumber =
          ValidationErrorMessages.Examiner_Number;
      if (!editExaminer.lastName.match(validNameFormat))
        validationErrors.lastName = ValidationErrorMessages.Last_Name;
      if (
        editExaminer.firstName.length > 0 &&
        !editExaminer.firstName.match(validNameFormat)
      )
        validationErrors.firstName = ValidationErrorMessages.First_Name;
      if (
        editExaminer.emailAddress === null ||
        !editExaminer.emailAddress.match(validEmailFormat)
      )
        validationErrors.emailAddress = ValidationErrorMessages.Email_Address;
    }

    setFormValidationErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      if (appInsights !== undefined) {
        appInsights.trackEvent(
          { name: EXAMINERVALIDATIONFAILEDMODALEVENT },
          { user: currentUser.username, formValues: newExaminer },
        );
      }
    }
    return Object.keys(validationErrors).length === 0;
  }

  useEffect(() => {
    getExaminers(apiHost)
      .then((_examiners: Examiner[]) => {
        let examinersDataForDisplay = _examiners.map((examiner: Examiner) =>
          formatExaminerDataForDisplay(examiner),
        );
        setExaminers(examinersDataForDisplay);
        setLoading(false);
      })
      .catch(_ => {
        setAPIError(true);
      });
  }, [apiHost]);

  return (
    <>
      <h1>Examiners</h1>
      <ExaminerModal
        isOpen={isAddModalOpen}
        hideModal={hideAddModal}
        onSubmit={handleNewExaminerSubmit}
        onChange={handleChange}
        examiner={newExaminer}
        formValidationErrors={formValidationErrors}
        formValidated={formValidated}
        submitButtonParams={examinerModalSubmitButtonParams}
      />
      <ExaminerModal
        isOpen={isEditModalOpen}
        hideModal={hideEditModal}
        onSubmit={handleNewExaminerSubmit}
        onChange={handleEditModalChange}
        examiner={editExaminer}
        formValidationErrors={formValidationErrors}
        formValidated={formValidated}
        submitButtonParams={examinerModalSubmitButtonParams}
      />
      <hr />
      {!APIError && (
        <>
          <ExaminerFilters
            examinerFilter={examinerFilter}
            setExaminerFilter={setExaminerFilter}
          />
          <Authorized
            isAuthorized={permissions.canWriteExaminers}
          >
            <Button
              data-testid="addNewExaminerButton"
              variant="primary"
              className="mb-2"
              onClick={handleAddExaminerClick}
            >
              Add Examiner
            </Button>
          </Authorized>

          <ExaminerTable
            examiners={filteredExaminers}
            onClick={handleEditExaminerClick}
            loading={loading}
          />
        </>
      )}

      {APIError && (
        <div className="alert alert-warning" role="alert">
          Error retrieving data. Call support if this message doesn't disappear
          after a few minutes.
        </div>
      )}
    </>
  );
}

export default Examiners;
