import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import {
  Carousel,
  Dropdown,
  SplitButton,
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Popover,
  OverlayTrigger,
  ProgressBar,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'styles/8pttest.css';
import px01 from '../common/TestComponents/1x1_#6e5c70ff.png';
import px02 from '../common/TestComponents/1x1_#19b79eff.png';
import px03 from '../common/TestComponents/1x1_#29e8fcff.png';

function EightPtTest(props: any) {
  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="row mb-3">
          <div className="col">
            <h1>This is a test heading h1.</h1>
            <h2>This is a test heading h2.</h2>
            <h3>This is a test heading h3.</h3>
            <h4>This is a test heading h4.</h4>
            <h5>This is a test heading h5.</h5>
            <h6>This is a test heading h6.</h6>
          </div>
          <div className="col">
            <p className="h1">h1. Bootstrap heading</p>
            <p className="h2">h2. Bootstrap heading</p>
            <p className="h3">h3. Bootstrap heading</p>
            <p className="h4">h4. Bootstrap heading</p>
            <p className="h5">h5. Bootstrap heading</p>
            <p className="h6">h6. Bootstrap heading</p>
          </div>
          <div className="col">
            <h1 className="display-1">Display 1</h1>
            <h1 className="display-2">Display 2</h1>
            <h1 className="display-3">Display 3</h1>
            <h1 className="display-4">Display 4</h1>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <p>This is a paragraph</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              lectus augue, venenatis ac quam semper, sollicitudin maximus leo.
              Vestibulum ornare diam et ultricies fermentum. Mauris mattis
              tempor magna, sit amet gravida diam consequat a. Etiam vel lectus
              ante. Vivamus aliquet lorem vitae eros tristique, eget laoreet
              lacus molestie. Proin tristique, risus et fermentum volutpat,
              lorem nisl hendrerit velit, vitae dictum lorem mi eu massa.
              Integer euismod lectus ut laoreet tempus.
            </p>
            <p className="lead">
              Lead: Vivamus sagittis lacus vel augue laoreet rutrum faucibus
              dolor auctor. Duis mollis, est non commodo luctus.
            </p>
          </div>
          <div className="col">
            <blockquote className="blockquote">
              <p className="mb-0">This text is in a blockquote.</p>
              <p className="mb-0">This text is in a blockquote.</p>
            </blockquote>
            <blockquote className="blockquote">
              <p className="mb-0">This text is in a blockquote.</p>
              <footer className="blockquote-footer">
                Someone famous in <cite title="Source Title">Source Title</cite>
              </footer>
            </blockquote>
            <ul className="list-inline">
              <li className="list-inline-item">This is an inline list</li>
              <li className="list-inline-item">Phasellus iaculis</li>
              <li className="list-inline-item">Nulla volutpat</li>
            </ul>
          </div>
          <div className="col">
            <ul className="list-unstyled">
              <li>Lorem ipsum dolor sit amet</li>
              <li>Consectetur adipiscing elit</li>
              <li>Integer molestie lorem at massa</li>
              <li>Facilisis in pretium nisl aliquet</li>
              <li>
                Nulla volutpat aliquam velit
                <ul>
                  <li>Phasellus iaculis neque</li>
                  <li>Purus sodales ultricies</li>
                  <li>Vestibulum laoreet porttitor sem</li>
                  <li>Ac tristique libero volutpat at</li>
                </ul>
              </li>
              <li>Faucibus porta lacus fringilla vel</li>
              <li>Aenean sit amet erat nunc</li>
              <li>Eget porttitor lorem</li>
            </ul>
          </div>
        </div>

        <dl className="row">
          <dt className="col-sm-3">Description lists</dt>
          <dd className="col-sm-9">
            A description list is perfect for defining terms.
          </dd>

          <dt className="col-sm-3">Euismod</dt>
          <dd className="col-sm-9">
            <p>
              Vestibulum id ligula porta felis euismod semper eget lacinia odio
              sem nec elit.
            </p>
            <p>Donec id elit non mi porta gravida at eget metus.</p>
          </dd>

          <dt className="col-sm-3">Malesuada porta</dt>
          <dd className="col-sm-9">
            Etiam porta sem malesuada magna mollis euismod.
          </dd>

          <dt className="col-sm-3 text-truncate">
            Truncated term is truncated
          </dt>
          <dd className="col-sm-9">
            Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
            nibh, ut fermentum massa justo sit amet risus.
          </dd>

          <dt className="col-sm-3">Nesting</dt>
          <dd className="col-sm-9">
            <dl className="row">
              <dt className="col-sm-4">Nested definition list</dt>
              <dd className="col-sm-8">
                Aenean posuere, tortor sed cursus feugiat, nunc augue blandit
                nunc.
              </dd>
            </dl>
          </dd>
        </dl>

        <hr />
        <BootstrapTable
          keyField="id"
          data={products}
          columns={columns}
          bootstrap4={true}
          bordered={true}
          condensed={true}
        />

        <hr />

        <div className="alert alert-info" role="alert">
          This is an info alert—check it out!
        </div>
        <div className="alert alert-danger" role="alert">
          This is a danger alert—check it out!
        </div>

        <hr />
        <div className="row">
          <div className="col">
            <button type="button" className="btn btn-primary">
              Primary
            </button>
            <button type="button" className="btn btn-secondary">
              Secondary
            </button>
            <button type="button" className="btn btn-success">
              Success
            </button>
            <button type="button" className="btn btn-danger">
              Danger
            </button>
            <button type="button" className="btn btn-warning">
              Warning
            </button>
            <button type="button" className="btn btn-info">
              Info
            </button>
            <button type="button" className="btn btn-light">
              Light
            </button>
            <button type="button" className="btn btn-dark">
              Dark
            </button>
          </div>
          <div className="row col">
            <div className="col">
              <button type="button" className="btn btn-primary btn-lg">
                Large button
              </button>
              <button type="button" className="btn btn-secondary btn-lg">
                Large button
              </button>
            </div>
            <div className="col">
              <button type="button" className="btn btn-primary btn-sm">
                Small button
              </button>
              <button type="button" className="btn btn-secondary btn-sm">
                Small button
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div className="card-deck mb-3">
          <Card01 />
          <Card02 />
          <Card03 />
          <Card02 />
        </div>

        <div className="card-columns">
          <Card01 />
          <Card02 />
          <Card02 />
          <Card02 />
          <Card03 />
          <Card02 />
        </div>

        <hr />

        <Carousel fade>
          <Carousel.Item>
            <img
              height="300px"
              className="d-block w-100"
              src={px01}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              height="300px"
              className="d-block w-100"
              src={px02}
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              height="300px"
              className="d-block w-100"
              src={px03}
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <hr />

        {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger'].map(
          variant => (
            <SplitButton
              key={variant}
              id={`dropdown-split-variants-${variant}`}
              variant={variant.toLowerCase()}
              title={variant}
            >
              <Dropdown.Item eventKey="1">Action</Dropdown.Item>
              <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
              <Dropdown.Item eventKey="3" active>
                Active Item
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
            </SplitButton>
          ),
        )}

        <hr />

        <div className="row">
          <div className="col">
            <form>
              <div className="form-group row">
                <label
                  htmlFor="colFormLabelSm"
                  className="col-sm-2 col-form-label col-form-label-sm"
                >
                  Email
                </label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="colFormLabelSm"
                    placeholder="col-form-label-sm"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="colFormLabel"
                  className="col-sm-2 col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control"
                    id="colFormLabel"
                    placeholder="col-form-label"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="colFormLabelLg"
                  className="col-sm-2 col-form-label col-form-label-lg"
                >
                  Email
                </label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    id="colFormLabelLg"
                    placeholder="col-form-label-lg"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col">
            <select className="form-control form-control-lg mt-3 mb-3">
              <option>Large select</option>
            </select>
            <select className="form-control mb-3">
              <option>Default select</option>
            </select>
            <select className="form-control form-control-sm mb-3">
              <option>Small select</option>
            </select>
            <div className="form-check form-check-inline mt-3">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                1
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option2"
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                2
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio3"
                value="option3"
                disabled
              />
              <label className="form-check-label" htmlFor="inlineRadio3">
                3 (disabled)
              </label>
            </div>
          </div>
        </div>

        <form className="mt-5">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputEmail4">Email</label>
              <input
                type="email"
                className="form-control"
                id="inputEmail4"
                placeholder="Email"
              />
              <small id="passwordHelpBlock" className="form-text text-muted">
                Your password must be 8-20 characters long, contain letters and
                numbers, and must not contain spaces, special characters, or
                emoji.
              </small>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputPassword4">Password</label>
              <input
                type="password"
                className="form-control"
                id="inputPassword4"
                placeholder="Password"
              />
              <small id="passwordHelpBlock" className="form-text text-muted">
                Your password must be 8-20 characters long, contain letters and
                numbers, and must not contain spaces, special characters, or
                emoji.
              </small>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress">Address</label>
            <input
              type="text"
              className="form-control"
              id="inputAddress"
              placeholder="1234 Main St"
            />
            <small id="passwordHelpBlock" className="form-text text-muted">
              Your password must be 8-20 characters long, contain letters and
              numbers, and must not contain spaces, special characters, or
              emoji.
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress2">Address 2</label>
            <input
              type="text"
              className="form-control"
              id="inputAddress2"
              placeholder="Apartment, studio, or floor"
            />
            <small id="passwordHelpBlock" className="form-text text-muted">
              Your password must be 8-20 characters long, contain letters and
              numbers, and must not contain spaces, special characters, or
              emoji.
            </small>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputCity">City</label>
              <input type="text" className="form-control" id="inputCity" />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputState">State</label>
              <select id="inputState" className="form-control">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="inputZip">Zip</label>
              <input type="text" className="form-control" id="inputZip" />
            </div>
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="gridCheck"
              />
              <label className="form-check-label" htmlFor="gridCheck">
                Check me out
              </label>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Sign in
          </button>
        </form>

        <div className="row">
          <div className="col">
            <form className="mt-5">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail3"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputPassword3"
                  className="col-sm-2 col-form-label"
                >
                  Password
                </label>
                <div className="col-sm-10">
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword3"
                    placeholder="Password"
                  />
                </div>
              </div>
              <fieldset className="form-group">
                <div className="row">
                  <legend className="col-form-label col-sm-2 pt-0">
                    Radios
                  </legend>
                  <div className="col-sm-10">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios1"
                        value="option1"
                        checked
                      />
                      <label className="form-check-label" htmlFor="gridRadios1">
                        First radio
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios2"
                        value="option2"
                      />
                      <label className="form-check-label" htmlFor="gridRadios2">
                        Second radio
                      </label>
                    </div>
                    <div className="form-check disabled">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gridRadios"
                        id="gridRadios3"
                        value="option3"
                        disabled
                      />
                      <label className="form-check-label" htmlFor="gridRadios3">
                        Third disabled radio
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="form-group row">
                <div className="col-sm-2">Checkbox</div>
                <div className="col-sm-10">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck1"
                    />
                    <label className="form-check-label" htmlFor="gridCheck1">
                      Example checkbox
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10">
                  <button type="submit" className="btn btn-primary">
                    Sign in
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  @
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  @example.com
                </span>
              </div>
            </div>

            <label htmlFor="basic-url">Your vanity URL</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  https://example.com/users/
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="Amount (to the nearest dollar)"
              />
              <div className="input-group-append">
                <span className="input-group-text">.00</span>
              </div>
            </div>

            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">With textarea</span>
              </div>
              <textarea
                className="form-control"
                aria-label="With textarea"
              ></textarea>
            </div>

            <div className="input-group input-group-sm mb-3 mt-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  Small
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="inputGroup-sizing-default"
                >
                  Default
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
              />
            </div>

            <div className="input-group input-group-lg">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-lg">
                  Large
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
              />
            </div>

            <div className="input-group mb-3 mt-3">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="Text input with checkbox"
              />
            </div>

            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="radio"
                    aria-label="Radio button for following text input"
                  />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="Text input with radio button"
              />
            </div>
          </div>
        </div>

        <hr />

        <div className="jumbotron">
          <h1 className="display-4">Hello, world!</h1>
          <p className="lead">
            This is a simple hero unit, a simple jumbotron-style component for
            calling extra attention to featured content or information.
          </p>
          <hr className="my-4" />
          <p>
            It uses utility classes for typography and spacing to space content
            out within the larger container.
          </p>
          <p className="lead">
            <a
              className="btn btn-primary btn-lg"
              href="/EightPtTest"
              role="button"
            >
              Learn more
            </a>
          </p>
        </div>

        <hr />

        <div className="row">
          <div className="col">
            <ul className="list-group mb-3">
              <li className="list-group-item active">Cras justo odio</li>
              <li className="list-group-item">Dapibus ac facilisis in</li>
              <li className="list-group-item">Morbi leo risus</li>
              <li className="list-group-item">Porta ac consectetur ac</li>
              <li className="list-group-item">Vestibulum at eros</li>
            </ul>

            <div className="list-group mb-3">
              <a
                href="/EightPtTest"
                className="list-group-item list-group-item-action active"
              >
                Cras justo odio
              </a>
              <a
                href="/EightPtTest"
                className="list-group-item list-group-item-action"
              >
                Dapibus ac facilisis in
              </a>
              <a
                href="/EightPtTest"
                className="list-group-item list-group-item-action"
              >
                Morbi leo risus
              </a>
              <a
                href="/EightPtTest"
                className="list-group-item list-group-item-action"
              >
                Porta ac consectetur ac
              </a>
              <a
                href="/EightPtTest"
                className="list-group-item list-group-item-action disabled"
              >
                Vestibulum at eros
              </a>
            </div>
          </div>
          <div className="col">
            <ul className="list-group mb-3">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Cras justo odio
                <span className="badge badge-primary badge-pill">14</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Dapibus ac facilisis in
                <span className="badge badge-primary badge-pill">2</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Morbi leo risus
                <span className="badge badge-primary badge-pill">1</span>
              </li>
            </ul>

            <div className="list-group">
              <a
                href="/EightPtTest"
                className="list-group-item list-group-item-action flex-column align-items-start active"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">List group item heading</h5>
                  <small>3 days ago</small>
                </div>
                <p className="mb-1">
                  Donec id elit non mi porta gravida at eget metus. Maecenas sed
                  diam eget risus varius blandit.
                </p>
                <small>Donec id elit non mi porta.</small>
              </a>
              <a
                href="/EightPtTest"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">List group item heading</h5>
                  <small className="text-muted">3 days ago</small>
                </div>
                <p className="mb-1">
                  Donec id elit non mi porta gravida at eget metus. Maecenas sed
                  diam eget risus varius blandit.
                </p>
                <small className="text-muted">
                  Donec id elit non mi porta.
                </small>
              </a>
              <a
                href="/EightPtTest"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">List group item heading</h5>
                  <small className="text-muted">3 days ago</small>
                </div>
                <p className="mb-1">
                  Donec id elit non mi porta gravida at eget metus. Maecenas sed
                  diam eget risus varius blandit.
                </p>
                <small className="text-muted">
                  Donec id elit non mi porta.
                </small>
              </a>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col">
            <h1>
              Example heading <span className="badge badge-secondary">New</span>
            </h1>
            <h2>
              Example heading <span className="badge badge-secondary">New</span>
            </h2>
            <h3>
              Example heading <span className="badge badge-secondary">New</span>
            </h3>
            <h4>
              Example heading <span className="badge badge-secondary">New</span>
            </h4>
            <h5>
              Example heading <span className="badge badge-secondary">New</span>
            </h5>
            <h6>
              Example heading <span className="badge badge-secondary">New</span>
            </h6>
          </div>
          <div className="col">
            <div className="mb-3">
              <span className="badge badge-primary">Primary</span>
              <span className="badge badge-secondary">Secondary</span>
              <span className="badge badge-success">Success</span>
              <span className="badge badge-danger">Danger</span>
              <span className="badge badge-warning">Warning</span>
              <span className="badge badge-info">Info</span>
              <span className="badge badge-light">Light</span>
              <span className="badge badge-dark">Dark</span>
            </div>
            <div className="mb-3">
              <span className="badge badge-pill badge-primary">Primary</span>
              <span className="badge badge-pill badge-secondary">
                Secondary
              </span>
              <span className="badge badge-pill badge-success">Success</span>
              <span className="badge badge-pill badge-danger">Danger</span>
              <span className="badge badge-pill badge-warning">Warning</span>
              <span className="badge badge-pill badge-info">Info</span>
              <span className="badge badge-pill badge-light">Light</span>
              <span className="badge badge-pill badge-dark">Dark</span>
            </div>
            <div className="mb-3">
              <a href="/EightPtTest" className="badge badge-primary">
                Primary
              </a>
              <a href="/EightPtTest" className="badge badge-secondary">
                Secondary
              </a>
              <a href="/EightPtTest" className="badge badge-success">
                Success
              </a>
              <a href="/EightPtTest" className="badge badge-danger">
                Danger
              </a>
              <a href="/EightPtTest" className="badge badge-warning">
                Warning
              </a>
              <a href="/EightPtTest" className="badge badge-info">
                Info
              </a>
              <a href="/EightPtTest" className="badge badge-light">
                Light
              </a>
              <a href="/EightPtTest" className="badge badge-dark">
                Dark
              </a>
            </div>
            <div className="mb-3">
              <button type="button" className="btn btn-primary mr-3">
                Notifications <span className="badge badge-light">4</span>
              </button>
              <button type="button" className="btn btn-primary">
                Profile <span className="badge badge-light">9</span>
                <span className="sr-only">unread messages</span>
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div className="modal show" tabIndex={-1} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Modal title</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Modal body text goes here.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col">
            <nav className="nav mb-3">
              <a className="nav-link active" href="/EightPtTest">
                Active
              </a>
              <a className="nav-link" href="/EightPtTest">
                Link
              </a>
              <a className="nav-link" href="/EightPtTest">
                Link
              </a>
              <a className="nav-link disabled" href="/EightPtTest">
                Disabled
              </a>
            </nav>

            <div
              className="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className="nav-link active"
                id="v-pills-home-tab"
                data-toggle="pill"
                href="#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                Home
              </a>
              <a
                className="nav-link"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                Profile
              </a>
              <a
                className="nav-link"
                id="v-pills-messages-tab"
                data-toggle="pill"
                href="#v-pills-messages"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                Messages
              </a>
              <a
                className="nav-link"
                id="v-pills-settings-tab"
                data-toggle="pill"
                href="#v-pills-settings"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                Settings
              </a>
            </div>
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                ...
              </div>
            </div>
          </div>

          <div className="col">
            <ul className="nav nav-tabs mb-3">
              <li className="nav-item">
                <a className="nav-link active" href="/EightPtTest">
                  Active
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="/EightPtTest"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="/EightPtTest">
                    Action
                  </a>
                  <a className="dropdown-item" href="/EightPtTest">
                    Another action
                  </a>
                  <a className="dropdown-item" href="/EightPtTest">
                    Something else here
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="/EightPtTest">
                    Separated link
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/EightPtTest">
                  Link
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="/EightPtTest">
                  Disabled
                </a>
              </li>
            </ul>

            <ul className="nav nav-pills mb-3">
              <li className="nav-item">
                <a className="nav-link active" href="/EightPtTest">
                  Active
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="/EightPtTest"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="/EightPtTest">
                    Action
                  </a>
                  <a className="dropdown-item" href="/EightPtTest">
                    Another action
                  </a>
                  <a className="dropdown-item" href="/EightPtTest">
                    Something else here
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="/EightPtTest">
                    Separated link
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/EightPtTest">
                  Link
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="/EightPtTest">
                  Disabled
                </a>
              </li>
            </ul>

            <ul className="nav nav-pills nav-fill mb-3">
              <li className="nav-item">
                <a className="nav-link active" href="/EightPtTest">
                  Active
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/EightPtTest">
                  Longer nav link
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/EightPtTest">
                  Link
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="/EightPtTest">
                  Disabled
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr />

        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Form inline>
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
              />
              <Button variant="outline-success">Search</Button>
            </Form>
          </Navbar.Collapse>
        </Navbar>

        <hr />

        <div className="row">
          <div className="col">
            <nav aria-label="...">
              <ul className="pagination">
                <li className="page-item disabled">
                  <a className="page-link" href="/EightPtTest" tabIndex={-1}>
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="/EightPtTest">
                    2 <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col">
            <nav aria-label="...">
              <ul className="pagination pagination-lg">
                <li className="page-item disabled">
                  <a className="page-link" href="/EightPtTest" tabIndex={-1}>
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="/EightPtTest">
                    2 <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col">
            <nav aria-label="...">
              <ul className="pagination pagination-sm">
                <li className="page-item disabled">
                  <a className="page-link" href="/EightPtTest" tabIndex={-1}>
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="/EightPtTest">
                    2 <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="/EightPtTest">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <hr />

        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <Button variant="success">Click me to see a popover</Button>
        </OverlayTrigger>
        <Button
          className="ml-3"
          variant="primary"
          onClick={() => toast.success('HEY! This is a test toast!')}
        >
          Click me for a test Toast!
        </Button>

        <hr />

        <ProgressBar now={60} label={`${60}%`} />

        <hr />

        <div className="row eightPtTestRow" id="eightPtTestRow">
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
        </div>
        <div className="row eightPtTestRow mb-1" id="eightPtTestRow2">
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
        </div>
        <div className="row eightPtTestRow mb-2" id="eightPtTestRow3">
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
          <div className="col-1 text-center">
            <p>Content</p>
          </div>
        </div>
        <div className="row eightPtTestRow mb-3" id="eightPtTestRow4">
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
        </div>
        <div className="row eightPtTestRow mb-4" id="eightPtTestRow5">
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
        </div>
        <div className="row eightPtTestRow mb-5" id="eightPtTestRow6">
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-5">
        <div className="row eightPtTestRow" id="eightPtTestRow">
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
          <div className="col text-center">
            <p>Content</p>
          </div>
        </div>
      </div>
    </>
  );
}

const products = [
  { id: 1000, name: 'Product 01', price: 19.99 },
  { id: 1001, name: 'Product 02', price: 195.99 },
  { id: 1002, name: 'Product 03', price: 119.99 },
  { id: 1003, name: 'Product 04', price: 1019.99 },
];
const columns = [
  {
    dataField: 'id',
    text: 'Product ID',
  },
  {
    dataField: 'name',
    text: 'Product Name',
  },
  {
    dataField: 'price',
    text: 'Product Price',
  },
];

const Card01 = () => {
  return (
    <div className="card">
      <div className="card-header">Featured</div>
      <img className="card-img-top" src={px01} alt="Card cap" />
      <div className="card-body">
        <h5 className="card-title">Card title</h5>
        <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
        <p className="card-text">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
        <a href="/EightPtTest" className="btn btn-primary">
          Go somewhere
        </a>
      </div>
      <div className="card-footer text-muted">2 days ago</div>
    </div>
  );
};

const Card02 = () => {
  return (
    <div className="card">
      <h5 className="card-header">Featured</h5>
      <blockquote className="blockquote mb-0 card-body">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          posuere erat a ante.
        </p>
        <footer className="blockquote-footer">
          <small className="text-muted">
            Someone famous in <cite title="Source Title">Source Title</cite>
          </small>
        </footer>
      </blockquote>
    </div>
  );
};

const Card03 = () => {
  return (
    <div className="card text-right">
      <div className="card-header">Featured</div>
      <img className="card-img-top" src={px02} alt="Card cap" />
      <ul className="list-group list-group-flush">
        <li className="list-group-item">Cras justo odio</li>
        <li className="list-group-item">Dapibus ac facilisis in</li>
        <li className="list-group-item">Vestibulum at eros</li>
      </ul>
      <div className="card-footer text-muted">2 days ago</div>
    </div>
  );
};

const popover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Popover right</Popover.Title>
    <Popover.Content>
      And here's some <strong>amazing</strong> content. It's very engaging.
      right?
    </Popover.Content>
  </Popover>
);

export default EightPtTest;
