import {  useState, useContext } from 'react';
import moment, { Moment } from 'moment';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import ReportFilter from './ReportFilter';
import { IDateRange } from 'types/Filter';
import { downloadCheckInExcel, downloadScoringExcel, downloadScoringJson } from 'api/reportAPI';
import { Spinner, Col, Row } from "react-bootstrap";
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
export default function Reports() {
    const [isLoading, setIsLoading] = useState(false);
    const [dateRangeError, setDateRangeError] = useState(false);
    const [dateRange, setDateRange] = useState<IDateRange>({
        startDate: moment().add(-1, 'y'),
        endDate: moment().add(1, 'd'),
    });
    const environmentVariableContext = useContext(EnvironmentVariableContext);
    const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;
    
    const handleDateRangeChange = (datesPicked: {
        startDate: Moment | null;
        endDate: Moment | null;
    }) => {
        setDateRange({
            startDate: datesPicked.startDate,
            endDate: datesPicked.endDate,
        });
    };

    async function handleCheckInReportSubmit(e: any, examinerId: string | undefined) {
        const startDateAPI = dateRange.startDate
            ? moment(dateRange.startDate).startOf('day')
            : moment().startOf('day');
        const endDateAPI = dateRange.endDate
            ? moment(dateRange.endDate).endOf('day')
            : moment().add(1, 'd').endOf('day');
        const formattedStartDate = startDateAPI.utc().format('YYYY-MM-DDTHH:mm:ss');
        const formattedEndDate = endDateAPI.utc().format('YYYY-MM-DDTHH:mm:ss');
        if (formattedStartDate > formattedEndDate) {
            setDateRangeError(true);
            return false;
        }

        setIsLoading(true);
        await downloadCheckInExcel(apiHost, examinerId, formattedStartDate, formattedEndDate)
            .then(excel => {
                setIsLoading(false);
                downloadBlob(excel, "CheckInReport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                toast.success("Successfully download checkin report.");
            })
            .catch(error => {
                setIsLoading(false);
                toast.error("Error in downloading CheckIn Report.");
                console.log('downloadCheckInExcel Error', error);
            });
    }

    async function handleScoringReportSubmit(e: any, examinerId: string | undefined, locationId: string | undefined) {
        const startDateAPI = dateRange.startDate
            ? moment(dateRange.startDate).startOf('day')
            : moment().startOf('day');
        const endDateAPI = dateRange.endDate
            ? moment(dateRange.endDate).endOf('day')
            : moment().add(1, 'd').endOf('day');
        const formattedStartDate = startDateAPI.utc().format('YYYY-MM-DDTHH:mm:ss');
        const formattedEndDate = endDateAPI.utc().format('YYYY-MM-DDTHH:mm:ss');
        if (formattedStartDate > formattedEndDate) {
            setDateRangeError(true);
            return false;
        }

        setIsLoading(true);
        if(e === "downloadExamReport")
        {
            await downloadScoringExcel(apiHost, formattedStartDate, formattedEndDate, examinerId, locationId)
            .then(excel => {
                setIsLoading(false);
                downloadBlob(excel, "ScoringReport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                toast.success("Successfully download scoring report.");
            })
            .catch((error) => {
                setIsLoading(false);
                if(error.toLocaleString() && error.toLocaleString() === "Error: No content found") toast.error("No records found!");
                else toast.error("Error in downloading Scoring Report.");
            });
        }
        else if(e === "downloadExamJson")
        {
            await downloadScoringJson(apiHost, formattedStartDate, formattedEndDate)
            .then(c => {
                setIsLoading(false);
                toast.success("Successfully download scoring report json.");
                downloadBlob(c.file, c.fileName, "text/plain");
            })
            .catch(error => {
                setIsLoading(false);
                toast.error("Error in downloading Scoring Report Json.");
                console.log('downloadScoringJson Error', error);
            });
        }
        
    }

    function downloadBlob(content: any, fileName: string, type: string) {
        const url = window.URL.createObjectURL(new Blob([content], { type: type }));
        saveAs(url, fileName);
    }

    return (
        <>
            <h1>Check-In Reports</h1>
            <hr />
            <ReportFilter
                dateFilterOnChangeHandler={handleDateRangeChange}
                dateRange={dateRange}
                disableButton={isLoading}
                checkInReportSubmitHandler={handleCheckInReportSubmit}
                scoringReportSubmitHandler={handleScoringReportSubmit}
            />
            {dateRangeError ? <p>From date cannot be greater than To!</p> : ""}
            <div className="container">
                <Row>
                    <Col className="d-flex justify-content-center">
                        {isLoading && <Spinner animation="grow" />}  
                    </Col>
                </Row>
            </div>
        </>
    )
}