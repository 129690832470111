type tMessageChannelsDisplayFriendly = {
    [key: number]: string
}

export enum MessageChannels {
    SMS = 0,
    Email = 1,
}

export const MessageChannelsDisplayFriendly: tMessageChannelsDisplayFriendly = {
    0: "SMS",
    1: "Email"
}