import { sendAuthorizedRequest, HttpMethod} from './authWrapper';
import { AppointmentForDashboard } from '../types/appointment';
import { DemoAppointmentEndpointBaseUrl, AppointmentEndpointBaseUrl} from '../properties/appProperties';

export function getAppointmentsByDateRange(host: string, startDate: string, endDate: string) {
   let appointmentEndpoint = host + AppointmentEndpointBaseUrl + '/?startDate=' + startDate + '&endDate=' + endDate;
  return sendAuthorizedRequest(HttpMethod.GET, appointmentEndpoint);
}
export function createAppointment(host: string, appointment:any) {  
   let appointmentEndpoint =  host + AppointmentEndpointBaseUrl; 
  return sendAuthorizedRequest(HttpMethod.POST, appointmentEndpoint, appointment);
}
export function createDemoAppointment(host: string, appointment:any) {  
  let appointmentEndpoint =  host + DemoAppointmentEndpointBaseUrl;
  // eslint-disable-next-line eqeqeq
  const eventAppointmentEndpoint = (appointment.isEvent == 1) ? appointmentEndpoint + '/?useEvent=true' : appointmentEndpoint;
  return sendAuthorizedRequest(HttpMethod.POST, eventAppointmentEndpoint, appointment);
}

export function updateAppointment(host: string, appointment: any) {
  let appointmentEndpoint = host + DemoAppointmentEndpointBaseUrl + '/' + appointment.appointmentId;
  // eslint-disable-next-line eqeqeq
  const eventAppointmentEndpoint = (appointment.isEvent == 1) ? appointmentEndpoint + '?useEvent=true' : appointmentEndpoint;
  return sendAuthorizedRequest(HttpMethod.PUT, eventAppointmentEndpoint, appointment);
}

export function cancelAppointment(host: string, appointment: AppointmentForDashboard) {
  let appointmentEndpoint = host + AppointmentEndpointBaseUrl + '/' + appointment.appointmentId + '/cancel';
  return sendAuthorizedRequest(HttpMethod.POST, appointmentEndpoint, appointment);
}

export function noShowAppointment(host: string, appointment: AppointmentForDashboard) {
  let appointmentEndpoint = host + AppointmentEndpointBaseUrl + '/' + appointment.appointmentId + '/noshow';
  return sendAuthorizedRequest(HttpMethod.POST, appointmentEndpoint, appointment);
}

export function postponedAppointment(host: string, appointment: AppointmentForDashboard) {
  let appointmentEndpoint = host + AppointmentEndpointBaseUrl + '/' + appointment.appointmentId + '/postponed';
  return sendAuthorizedRequest(HttpMethod.POST, appointmentEndpoint, appointment);
}

export function reopenAppointment(host: string, appointment: AppointmentForDashboard) {
  let appointmentEndpoint = host + AppointmentEndpointBaseUrl + '/' + appointment.appointmentId + '/reopen';
  return sendAuthorizedRequest(HttpMethod.POST, appointmentEndpoint, appointment);
}

export function getAppointmentById(host: string, appointmentId: string) {
    let appointmentEndpoint = host + DemoAppointmentEndpointBaseUrl + '/' + appointmentId;
    return sendAuthorizedRequest(HttpMethod.GET, appointmentEndpoint);
}
