export interface SecurityFlags {
    canReadExam: boolean;
    canReadExaminers: boolean;
    canWriteExaminers: boolean;
    canReadAppointments: boolean;
    canWriteAppointments: boolean;
    canInitiateRoadTest: boolean;
}

export const defaultSecurityFlags: SecurityFlags = {
    canReadExam: false,
    canReadExaminers: false,
    canWriteExaminers: false,
    canReadAppointments: false,
    canWriteAppointments: false,
    canInitiateRoadTest: false,
}