import Dropdown from "react-bootstrap/Dropdown";

interface UserProfileIconProps {
  name: string | undefined;
}

function UserProfileIcon({ name } : UserProfileIconProps) {
    return(
        <Dropdown.Toggle variant="info" id="dropdown-user" className="initialButton" data-testid="userProfileIcon">
            {(name !== undefined) ? getInitials(name) : ""}
        </Dropdown.Toggle>
    );
}

function getInitials(name: string) {
    let splitNames = name.match(/\S+\s*/g);
    return splitNames && splitNames[0].charAt(0).toUpperCase() + splitNames[splitNames.length-1].charAt(0).toUpperCase();
}

export default UserProfileIcon;