export const APPINSIGHTSCLOUDROLE = "DriverRoadTests.CheckIn.UI";

// Trace
export const SUCCESSFULSIGNINTRACEMSG = "Successful sign-in.";
export const AUTHORIZEDREQUESTATTEMPTTRACEMSG  = "An attempt to make an authorized request without an active account was made.";

// Events
export const APPTCANCELLEDDASHBOARDEVENT = "DRTCHECKIN - Appointment Cancelled - Dashboard Action";
export const APPTNOSHOWDASHBOARDEVENT = "DRTCHECKIN - Appointment No Show - Dashboard Action";
export const APPTPOSTPONEDDASHBOARDEVENT = "DRTCHECKIN - Appointment Postponed - Dashboard Action";
export const APPTREOPENDASHBOARDEVENT = "DRTCHECKIN - Appointment Reopen - Dashboard Action";
export const EXAMINERADDEDMODALEVENT = "DRTCHECKIN - Examiner Added - Examiner Modal";
export const EXAMLAUNCHEDDASHBOARDEVENT = "DRTCHECKIN - Exam Launched - Dashboard Action";
export const EXAMINERMODIFIEDMODALEVENT = "DRTCHECKIN - Examiner Modified - Examiner Modal";
export const EXAMINERVALIDATIONFAILEDMODALEVENT = "DRTCHECKIN - Examiner Validation Failed - Examiner Modal";
export const EXAMRESULTPDFDOWNLOAD = "DRTCHECKIN - Exam Result Pdf Downloaded - Exam Action";
export const EXAMRESULTEXAMSUMMARY = "DRTCHECKIN - Exam summary called  - Exam Summary Modal";
