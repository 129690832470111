import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { sendExamPdfToCustomer } from 'api/examsAPI';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';

interface IProps {
    examId: string;
}

const ExamSendEmailActionButton = (props: IProps) => {
    const environmentVariableContext = useContext(EnvironmentVariableContext);
    const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;

    const handleSendEmailClicked = async (examId: string) => {
        try{
            await sendExamPdfToCustomer(apiHost, examId);
            toast.success("Email has been successfully sent to the customer.");
        }
        catch (ex: any) {
            let errorMessage = "Failed to send a summary email to the customer.";
            if(ex.response?.data !== undefined)
                errorMessage = ex.response.data;

            toast.error(errorMessage);
        }
    }

    return (
        <Button className="btn btn-light btn-sm" style={{ background: "transparent", border: "none"}} name="PDF-button">
          <span className='text-primary action-icon' style={{ fontSize: "1.5em" }}>
          <FontAwesomeIcon icon={faEnvelope} title="Send exam summary" key={props.examId} onClick={(e) => handleSendEmailClicked(props.examId)} />
          </span>
        </Button>
    );
}

export default ExamSendEmailActionButton;