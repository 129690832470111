import {
  AccountType,
  initialDriverCheckInState,
  DriverCheckInStateType,
} from 'types/DriverCheckInState';

export enum AccountReducerActions {
  SET_ACCOUNT = 'SET_ACCOUNT',
  RESET_ACCOUNT = 'RESET_ACCOUNT',
}

export type AccountReducerActionType =
  | {
      type: AccountReducerActions.SET_ACCOUNT;
      value: AccountType;
    }
  | {
      type: AccountReducerActions.RESET_ACCOUNT;
    };

export function accountReducer(
  state: DriverCheckInStateType,
  action: AccountReducerActionType,
) {
  switch (action.type) {
    case AccountReducerActions.SET_ACCOUNT:
      return {
        ...state,
        account: {
          id: action.value.id,
          name: action.value.name,
          address: action.value.address,
        },
      };
    case AccountReducerActions.RESET_ACCOUNT:
      return {
        ...state,
        account: initialDriverCheckInState.account,
      };
    default:
      return state;
  }
}
