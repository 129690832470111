import { PublicClientApplication, EventType, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "../auth/authConfig";
import { appInsights } from "../AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { SUCCESSFULSIGNINTRACEMSG } from "../properties/appInsightsProperties";


export let msalInstance = new PublicClientApplication(msalConfig());

export const initializeMsalInstance = () => {
    //to initialize on demand after config is loaded
    msalInstance = new PublicClientApplication(msalConfig());
    console.log("msal init. Config is typically not loaded first time this happens");
    msalInstance.addEventCallback((event) => {
        const authResult = event.payload as AuthenticationResult;
        if (event.eventType === EventType.LOGIN_SUCCESS && authResult.account) {
            const account = authResult.account;
            msalInstance.setActiveAccount(account);
            if (appInsights !== undefined) { 
                appInsights.setAuthenticatedUserContext(account.username);
                appInsights.trackTrace(
                    { message: SUCCESSFULSIGNINTRACEMSG, severityLevel: SeverityLevel.Information },
                    { user: account.username }
                ); 
            }
        }
    });

    const accounts = msalInstance.getAllAccounts();
    if (accounts !== undefined) {
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }
    }
};