import { useContext, useState } from 'react';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import { useMsal } from '@azure/msal-react';
import { Dropdown } from 'react-bootstrap';
import { AppointmentForDashboard } from 'types/appointment';
import CancelAppointmentModal from './CancelAppointmentModal';
import NoShowAppointmentModal from './noShowAppointmentModal';
import PostponedAppointmentModal from './postponedAppointmentModal';
import ReopenAppointmentModal from './reOpenAppointmentModal';
import { cancelAppointment, noShowAppointment, postponedAppointment, reopenAppointment } from 'api/appointmentAPI';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  EXAMLAUNCHEDDASHBOARDEVENT,
  APPTCANCELLEDDASHBOARDEVENT,
  APPTNOSHOWDASHBOARDEVENT,
  APPTPOSTPONEDDASHBOARDEVENT,
  APPTREOPENDASHBOARDEVENT,
} from 'properties/appInsightsProperties';
import { UserPermissionsContext } from 'store/UserPermissionsContext';

interface DashboardActionMenuProps {
  appointment: AppointmentForDashboard;
  show?: boolean;
  setAppointmentStatus: (appointmentId: string, status: number) => void;
}

function DashboardActionMenu(props: DashboardActionMenuProps) {
  const [showCancelApptModal, setShowCancelApptModal] = useState(false);
  const [cancelModalSubmitButtonParams, setCancelModalSubmitButtonParams] =
    useState<{ text: string; disabled: boolean }>({
      text: 'Cancel Appointment',
      disabled: false,
    });
  const handleCancelApptClose = () => setShowCancelApptModal(false);
  const handleCancelApptShow = () => {
    setCancelModalSubmitButtonParams({
      text: 'Cancel Appointment',
      disabled: false,
    });
    setShowCancelApptModal(true);
  };

  const [showNoShowApptModal, setShowNoShowApptModal] = useState(false);
  const [noShowModalSubmitButtonParams, setNoShowModalSubmitButtonParams] =
    useState<{ text: string; disabled: boolean }>({
      text: 'No Show',
      disabled: false,
    });
  const handleNoShowApptClose = () => setShowNoShowApptModal(false);
  const handleNoShowApptShow = () => {
    setNoShowModalSubmitButtonParams({
      text: 'No Show',
      disabled: false,
    });
    setShowNoShowApptModal(true);
  };

  const [showPostponedApptModal, setShowPostponedApptModal] = useState(false);
  const [postponedModalSubmitButtonParams, setPostponedModalSubmitButtonParams] =
    useState<{ text: string; disabled: boolean }>({
      text: 'Postponed',
      disabled: false,
    });
  const handlePostponedApptClose = () => setShowPostponedApptModal(false);
  const handlePostponedApptShow = () => {
    setPostponedModalSubmitButtonParams({
      text: 'Postponed',
      disabled: false,
    });
    setShowPostponedApptModal(true);
  };

  const [showReopenApptModal, setShowReopenApptModal] = useState(false);
  const [reopenModalSubmitButtonParams, setReopenModalSubmitButtonParams] =
    useState<{ text: string; disabled: boolean }>({
      text: 'Reopen',
      disabled: false,
    });
  const handleReopenApptClose = () => setShowReopenApptModal(false);
  const handleReopenApptShow = () => {
    setReopenModalSubmitButtonParams({
      text: 'Reopen',
      disabled: false,
    });
    setShowReopenApptModal(true);
  };

  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const scoringAppUIBaseUrl =
    environmentVariableContext.REACT_APP_SCORING_UI_BASEURL;
  const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;
  const { accounts } = useMsal();
  const appInsights = useAppInsightsContext();
  const permissions = useContext(UserPermissionsContext);

  function handleLaunchExamClick() {
    if (props.appointment.legacyAppointmentId) {
      window.open(
        `${scoringAppUIBaseUrl}examinformation/${props.appointment.legacyAppointmentId}`,
      );
    } else {
      window.open(
        `${scoringAppUIBaseUrl}examinformation/${props.appointment.appointmentId}`,
      );
    }

    if (appInsights !== undefined) {
      appInsights.trackEvent(
        { name: EXAMLAUNCHEDDASHBOARDEVENT },
        {
          user: accounts[0].username,
          appointmentId: props.appointment.legacyAppointmentId,
        },
      );
    }
  }

  function handleCancelAppointment() {
    setCancelModalSubmitButtonParams({
      text: 'Cancelling Appointment',
      disabled: true,
    });
    cancelAppointment(apiHost, props.appointment)
      .then(response => {
        // 2 - Cencelled status
        props.setAppointmentStatus(props.appointment.appointmentId, 2);
        toast.success('Appointment cancelled.');
        setShowCancelApptModal(false);
        if (appInsights !== undefined) {
          appInsights.trackEvent(
            { name: APPTCANCELLEDDASHBOARDEVENT },
            {
              user: accounts[0].username,
              appointmentId: props.appointment.appointmentId,
            },
          );
        }
      })
      .catch(error => {
        toast.error('Error cancelling appointment. Try again.');
        setShowCancelApptModal(false);
      });
  }

  
  function handleNoShowAppointment() {
    setNoShowModalSubmitButtonParams({
      text: 'No Show Appointment',
      disabled: true,
    });
    noShowAppointment(apiHost, props.appointment)
      .then(response => {
        // 6 - NoShow appointments
        props.setAppointmentStatus(props.appointment.appointmentId, 6);
        toast.success('Appointment No Show updated!');
        setShowNoShowApptModal(false);
        if (appInsights !== undefined) {
          appInsights.trackEvent(
            { name: APPTNOSHOWDASHBOARDEVENT },
            {
              user: accounts[0].username,
              appointmentId: props.appointment.appointmentId,
            },
          );
        }
      })
      .catch(error => {
        toast.error('Error no show appointment update. Try again.');
        setShowNoShowApptModal(false);
      });
  }

  function handlePostponedAppointment() {
    setPostponedModalSubmitButtonParams({
      text: 'Postponed Appointment',
      disabled: true,
    });
    postponedAppointment(apiHost, props.appointment)
      .then(response => {
        // 7 - posponed status
        props.setAppointmentStatus(props.appointment.appointmentId, 7);
        toast.success('Appointment postponed updated!');
        setShowPostponedApptModal(false);
        if (appInsights !== undefined) {
          appInsights.trackEvent(
            { name: APPTPOSTPONEDDASHBOARDEVENT },
            {
              user: accounts[0].username,
              appointmentId: props.appointment.appointmentId,
            },
          );
        }
      })
      .catch(error => {
        toast.error('Error postponed appointment update. Try again.');
        setShowPostponedApptModal(false);
      });
  }

  function handleReOpenAppointment() {
    setReopenModalSubmitButtonParams({
      text: 'Reopen Appointment',
      disabled: true,
    });
    reopenAppointment(apiHost, props.appointment)
      .then(response => {
        // 1 - Open status
        props.setAppointmentStatus(props.appointment.appointmentId, 1);
        toast.success('Reopen updated!');
        setShowReopenApptModal(false);
        if (appInsights !== undefined) {
          appInsights.trackEvent(
            { name: APPTREOPENDASHBOARDEVENT },
            {
              user: accounts[0].username,
              appointmentId: props.appointment.appointmentId,
            },
          );
        }
      })
      .catch(error => {
        toast.error('Error reopen appointment update. Try again.');
        setShowReopenApptModal(false);
      });
  }

  return (
    <>
      <Dropdown.Menu data-testid="actionMenu" show={props.show}>
        <Dropdown.Item
          data-testid="launchExam"
          disabled={
            !permissions.canInitiateRoadTest || (props.appointment.status === 2 || props.appointment.status === 6 || props.appointment.status === 7)
          }
          as="button"
          onClick={e => handleLaunchExamClick()}
        >
          Launch Exam
        </Dropdown.Item>
        {console.log('permissions', permissions)}
        <Dropdown.Item
          data-testid="cancelAppointment"
          disabled={
            !permissions.canWriteAppointments || props.appointment.status === 2
          }
          as="button"
          onClick={e => handleCancelApptShow()}
        >
          Cancel Appointment
        </Dropdown.Item>
        <Dropdown.Item
          data-testid="noShowAppointment"
          disabled={
            !permissions.canInitiateRoadTest || (props.appointment.status === 2 || props.appointment.status === 6 || props.appointment.status === 7)
          }
          as="button"
          onClick={e => handleNoShowApptShow()}
        >
          No Show Appointment
        </Dropdown.Item>
        <Dropdown.Item
          data-testid="postponedAppointment"
          disabled={
            !permissions.canInitiateRoadTest || (props.appointment.status === 2 || props.appointment.status === 6 || props.appointment.status === 7)
          }
          as="button"
          onClick={e => handlePostponedApptShow()}
        >
          Postponed Appointment
        </Dropdown.Item>
        {(props.appointment.status === 6 || props.appointment.status === 7) && (<Dropdown.Item
          data-testid="reOpenAppointment"
          disabled={
            !permissions.canInitiateRoadTest
          }
          as="button"
          onClick={e => handleReopenApptShow()}
        >
          Reopen Appointment
        </Dropdown.Item>)}
      </Dropdown.Menu>
      {showCancelApptModal && (
        <CancelAppointmentModal
          appointment={props.appointment}
          show={showCancelApptModal}
          handleClose={handleCancelApptClose}
          handleCancelAppt={handleCancelAppointment}
          submitButtonParams={cancelModalSubmitButtonParams}
        />
      )}
      {showNoShowApptModal && (
        <NoShowAppointmentModal
          appointment={props.appointment}
          show={showNoShowApptModal}
          handleClose={handleNoShowApptClose}
          handleNoShowAppt={handleNoShowAppointment}
          submitButtonParams={noShowModalSubmitButtonParams}
        />
      )}
      {showPostponedApptModal && (
        <PostponedAppointmentModal
          appointment={props.appointment}
          show={showPostponedApptModal}
          handleClose={handlePostponedApptClose}
          handlePostponedAppt={handlePostponedAppointment}
          submitButtonParams={postponedModalSubmitButtonParams}
        />
      )}
      {showReopenApptModal && (
        <ReopenAppointmentModal
          appointment={props.appointment}
          show={showReopenApptModal}
          handleClose={handleReopenApptClose}
          handleReopenAppt={handleReOpenAppointment}
          submitButtonParams={reopenModalSubmitButtonParams}
        />
      )}
    </>
  );
}

export default DashboardActionMenu;
