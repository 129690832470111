import { useEffect, useState, useContext } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css'
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import { Moment } from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { IDateRange } from 'types/Filter';
import { getLocations } from 'api/locationAPI';
import { Examiner } from 'types/Examiner';
import { Location } from 'types/Location';
import { getExaminers } from 'api/examinerAPI';
import { Col, Form, Row, Button, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';

interface ReportFilterProps {
    dateRange: IDateRange;
    dateFilterOnChangeHandler: (arg: {
        startDate: Moment | null;
        endDate: Moment | null;
    }) => void;
    disableButton: boolean | undefined;
    checkInReportSubmitHandler: (args: any, examinerId: string | undefined) => void;
    scoringReportSubmitHandler: (args: any, examinerId: string | undefined, locationId: string | undefined) => void;
}

export default function ReportFilter({
    dateRange,
    dateFilterOnChangeHandler,
    disableButton,
    checkInReportSubmitHandler,
    scoringReportSubmitHandler }: ReportFilterProps) {
    const environmentVariableContext = useContext(EnvironmentVariableContext);
    const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;
    const [examiners, setExaminers] = useState<Examiner[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [examiner, setExaminer] = useState<Examiner>();
    const [location, setLocation] = useState<Location>();
    const [loading, setLoading] = useState(true);
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
        null,
    );
    function handleFocusChange(focusedInputChanged: FocusedInputShape | null) {
        setFocusedInput(focusedInputChanged);
    }
    const sortByLastNameThenFirstName = (examiners: Examiner[]) => {
        return [...examiners].sort((a, b) => {
            let aName = a.lastName.toLowerCase() + a.firstName.toLowerCase();
            let bName = b.lastName.toLowerCase() + b.firstName.toLowerCase();

            if (aName < bName) {
                return -1;
            }
            if (aName > bName) {
                return 1;
            }
            return 0;
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const examiners = await getExaminers(apiHost);
                setExaminers(examiners);
            }
            catch(e){
                console.log('error in getting the examiners', e);
            }
            try {
                const locations = await getLocations(apiHost);
                setLocations(locations);
            }
            catch(e){
                console.log('error in getting the locations', e);
            }

            setLoading(false);
        }

        fetchData();
    }, [loading]);

    const handleExaminerChange = (e: any) => {
        setExaminer(e.target.value ? examiners.find((p) => p.examinerNumber === e.target.value) : undefined);
    }
    const handleLocationChange = (e: any) => {
        setLocation(e.target.value ? locations.find((p) => p.id === e.target.value) : undefined);
    }
    return (
        <div className="container">
            <Row>
                <Col>
                    <Form.Group as={Col} sm={8} md={11} lg={8} xl={8} className="px-0">
                        <Form.Label htmlFor="examiner">Examiners</Form.Label>
                        <Form.Control
                            className="custom-select"
                            as="select"
                            id="examiner"
                            onChange={(e) => handleExaminerChange(e)}
                            value={examiner?.examinerNumber ?? ""}>
                            <option key="All" value="">All</option>
                            {examiners &&
                                sortByLastNameThenFirstName(examiners).map((examiner) => {
                                    return <option key={examiner.examinerNumber} value={examiner.examinerNumber}>
                                        {examiner.firstName + ' ' + examiner.lastName + ' (' + examiner.examinerNumber + ')'}
                                    </option>
                                })}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group as={Col} sm={8} md={11} lg={8} xl={8} className="px-0">
                        <Form.Label htmlFor="location">Location (<small>scoring only</small>)</Form.Label>
                        <Form.Control
                            className="custom-select"
                            as="select"
                            id="location"
                            onChange={(e) => handleLocationChange(e)}
                            value={location?.id ?? ""}>
                            <option key="All" value="">All</option>
                            {locations &&
                                locations.map((loc) => {
                                    return <option key={loc.id} value={loc.id}>
                                        {loc.name}
                                    </option>
                                })}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Label htmlFor="dateRange">Start & End Dates</Form.Label>
                    <DateRangePicker
                        startDate={dateRange.startDate}
                        startDateId="startDate"
                        endDate={dateRange.endDate}
                        endDateId="endDate"
                        onDatesChange={dateFilterOnChangeHandler}
                        focusedInput={focusedInput}
                        onFocusChange={handleFocusChange}
                        isOutsideRange={() => false}
                    />
                </Col>
                <Col className="mt-4">
                    <ButtonGroup>
                        <Button variant="primary" className="mt-2 mr-1" type="button" disabled={disableButton} onClick={(e) => { checkInReportSubmitHandler(e, examiner?.examinerNumber) }}>
                            CheckIn report
                        </Button>
                        <DropdownButton className="mt-2" as={ButtonGroup} disabled={disableButton} title="Scoring Report" id="bg-nested-dropdown" onSelect={(e) => { scoringReportSubmitHandler(e, examiner?.examinerNumber, location?.id) }}>
                            <Dropdown.Item eventKey="downloadExamReport">Exam Report</Dropdown.Item>
                            <Dropdown.Item eventKey="downloadExamJson"><small>Download Exam Json</small></Dropdown.Item>
                        </DropdownButton>
                    </ButtonGroup>
                </Col>
            </Row>

        </div>
    )
}
