import moment from 'moment';
import { NewAppointment } from 'types/appointment';
import { Location } from 'types/Location';

export interface AppointmentFormValidation {
  customerId?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  appointmentDate?: string;
  appointmentLocation?: string;
  examinerNumber?: string;
}
export enum AppointmentValidationErrorMessages {
  Customer_Number = 'Customer number must be 2 to 8 digits.',
  First_Name = 'Please provide a valid first name',
  Last_Name = 'Please provide a valid last name',
  Mobile_Number_Format = 'Please provide 10 digit mobile number.',
  Appointment_Date = 'Please provide a valid appointment date.',
  Appointment_Location = 'Please provide a valid appointment location.',
  Examiner_Number = '3-4 digit examiner number required',
}
export function ValidateAppointment(
  appointment: NewAppointment,
  locations: Location[]
): AppointmentFormValidation {
  const validationErrors: AppointmentFormValidation = {};
  const validCustomerNumberFormat = /^\d{2,8}$/;
  const validNameFormat =
    /^[A-Za-z\u{00C0}-\u{00FF}][A-Za-z'\u{00C0}-\u{00FF}-]+([ A-Za-z][A-Za-z'\u{00C0}-\u{00FF}-]+)*/u;
  const validMobileNumberFormat = /^[+][1]\d{10}$/;
  const validExaminerNumberFormat = /^\d{3,5}$/;

  if (!appointment.customerId.toString().match(validCustomerNumberFormat))
    validationErrors.customerId =
      AppointmentValidationErrorMessages.Customer_Number;

  if (!appointment.firstName.match(validNameFormat))
    validationErrors.firstName = AppointmentValidationErrorMessages.First_Name;

  if (!appointment.lastName.match(validNameFormat))
    validationErrors.lastName = AppointmentValidationErrorMessages.Last_Name;

  if (
    appointment.mobileNumber.length > 0 &&
    !appointment.mobileNumber.match(validMobileNumberFormat)
  )
    validationErrors.mobileNumber =
      AppointmentValidationErrorMessages.Mobile_Number_Format;

  if (
    !moment(appointment.appointmentDate).isValid() ||
    moment(appointment.appointmentDate).isBefore(new Date())
  )
    validationErrors.appointmentDate =
      AppointmentValidationErrorMessages.Appointment_Date;

  if (
    !locations
      .map(l => l.id)
      .includes(appointment.appointmentLocation)
  )
    validationErrors.appointmentLocation =
      AppointmentValidationErrorMessages.Appointment_Location;

  if (
    !appointment.examinerNumber
      .replace(/^0+/, '')
      .match(validExaminerNumberFormat)
  )
    validationErrors.examinerNumber =
      AppointmentValidationErrorMessages.Examiner_Number;

  return validationErrors;
}
