export interface EnvironmentVariableConfig {
  REACT_APP_API_BASEURL: string;
  REACT_APP_SCORING_UI_BASEURL: string;
  REACT_APP_ENVIRONMENT: string;
  REACT_APP_HIDE_DEMO: boolean;
  REACT_APP_AD_CLIENT_ID: string;
  REACT_APP_AD_AUTHORITY: string;
  REACT_APP_AD_REDIRECT_URL: string;
  REACT_APP_AD_POST_LOGOUT_REDIRECT_URL: string;
  REACT_APP_APPINSIGHTS_INSTR_KEY: string;
  REACT_APP_MSAL_CLIENT_ID?: string;
  REACT_APP_MSAL_TENANT_ID?: string;
  REACT_APP_MSAL_SIGNUPSIGNIN_POLICY?: string;
  REACT_APP_MSAL_FORGOTPASSWORD_POLICY?: string;
  REACT_APP_MSAL_EDITPROFILE_POLICY?: string;
  REACT_APP_MSAL_POLICY_AUTHORITY_ROOT?: string;
  REACT_APP_MSAL_B2C_AUTHORITYDOMAIN?: string;
  REACT_APP_BASEURL?: string;
  REACT_APP_BFF_API_SCOPES?: string;
  REACT_APP_USE_CORE_SERVICES_AUTH?: boolean;
}

export const defaultEnvironmentVariableConfig: EnvironmentVariableConfig = {
  REACT_APP_API_BASEURL: '',
  REACT_APP_ENVIRONMENT: '',
  REACT_APP_HIDE_DEMO: false,
  REACT_APP_AD_CLIENT_ID: '',
  REACT_APP_SCORING_UI_BASEURL: '',
  REACT_APP_AD_AUTHORITY: '',
  REACT_APP_AD_REDIRECT_URL: '',
  REACT_APP_AD_POST_LOGOUT_REDIRECT_URL: '',
  REACT_APP_APPINSIGHTS_INSTR_KEY: '',
  REACT_APP_MSAL_CLIENT_ID: '',
  REACT_APP_MSAL_TENANT_ID: '',
  REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: '',
  REACT_APP_MSAL_FORGOTPASSWORD_POLICY: '',
  REACT_APP_MSAL_EDITPROFILE_POLICY: '',
  REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: '',
  REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: '',
  REACT_APP_BASEURL: '',
  REACT_APP_BFF_API_SCOPES: '',
  REACT_APP_USE_CORE_SERVICES_AUTH: true,
};

export function envStringToBoolean(value: string | undefined): boolean {
  let bool = false;
  if (value === 'true' || value === '1') {
    bool = true;
  }
  return bool;
}
