import 'styles/dashboard.css';
import { useEffect, useState, useContext, useRef } from 'react';
import moment, { Moment } from 'moment';
import DashboardAppointmentTable from './DashboardAppointmentTable';
import { AppointmentForDashboard } from 'types/appointment';
import DashboardFilter from './Filters/DashboardFilter';
import { getAppointmentsByDateRange } from 'api/appointmentAPI';
import { getExaminers } from 'api/examinerAPI';
import { getLocations } from 'api/locationAPI';
import { Filter, IDateRange } from 'types/Filter';
import {
  filterAppointments,
  lookupExaminerByExaminerNumber,
  lookupCurrentExaminer,
} from './DashboardFunctions';
import { Examiner } from 'types/Examiner';
import { Location } from 'types/Location';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import AddAppointmentModal from '../Dashboard/AddAppointment/AddAppointmentModal';
import useOnScreenKeyboardWindowScroll from 'hooks/useOnScreenKeyboardWindowScroll';
import Authorized from 'app/common/Authorized';
import { getCurrentUserAccountInfo } from 'auth/authUtilities';
import { UserPermissionsContext } from 'store/UserPermissionsContext';

function Dashboard() {
  const [appointments, setAppointments] = useState<AppointmentForDashboard[]>(
    [],
  );
  const [filteredAppointments, setFilteredAppointments] = useState<
    AppointmentForDashboard[]
  >([]);
  const [APIError, setAPIError] = useState(false);
  const [DateRangeError, setDateRangeError] = useState(false);
  const [examiners, setExaminers] = useState<Examiner[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: moment(),
    endDate: moment().add(1, 'd'),
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;
  const permissions = useContext(UserPermissionsContext);

  // For window scroll when on-screen keyboard is present.
  const shiftOnTheseElementIds = ['startDate', 'endDate'];
  useOnScreenKeyboardWindowScroll(shiftOnTheseElementIds);
  const [appointmentFilter, setAppointmentFilter] = useState<
    Filter<AppointmentForDashboard>[]
  >([
    {
      field: 'status',
      values: ['1', '3'],
    },
  ]);
  let emailAddress: string = '' as string;
  if (getCurrentUserAccountInfo() !== undefined) {
    emailAddress = getCurrentUserAccountInfo().username as string;
  }
  const currentExaminer = lookupCurrentExaminer(emailAddress, examiners);
  useEffect(() => {
    if (currentExaminer !== '') {
      setAppointmentFilter(prevState => [
        ...prevState,
        { field: 'examinerNumber', values: [currentExaminer] },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentExaminer]);
  function useFirstRender() {
    const firstRender = useRef(true);
    useEffect(() => {
      getExaminers(apiHost)
        .then(_examiners => {
          setExaminers(_examiners);
        })
        .catch(_ => {});
      
      getLocations(apiHost)
        .then(data => {
          setLocations(data);
        })
        .catch(_ => {});

      firstRender.current = false;
    }, []);
    return firstRender.current;
  }
  let firstRender = useFirstRender();
  useEffect(() => {
    // Autorefresh
    const interval = setInterval(() => {
      getAppointmentDataFromAPI(examiners);
    }, 15000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (firstRender === false) {
      getAppointmentDataFromAPI(examiners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, examiners]);

  useEffect(() => {
    let filteredAppointments = filterAppointments(appointments, appointmentFilter);
    let appointmentsWithLocation = 
      filteredAppointments.map(x => 
        ({ ...x, 
          location: locations.find(l => l.id == x.appointmentLocation)
        }));

    setFilteredAppointments(appointmentsWithLocation);
  }, [appointmentFilter, appointments]);

  const getAppointmentDataFromAPI = async (examinerList: Examiner[]) => {
    const startDateAPI = dateRange.startDate
      ? moment(dateRange.startDate).startOf('day')
      : moment().startOf('day');
    const endDateAPI = dateRange.endDate
      ? moment(dateRange.endDate).endOf('day')
      : moment().add(1, 'd').endOf('day');
    const formattedStartDate = startDateAPI.utc().format('YYYY-MM-DDTHH:mm:ss');
    const formattedEndDate = endDateAPI.utc().format('YYYY-MM-DDTHH:mm:ss');
    if (formattedStartDate > formattedEndDate) {
      setDateRangeError(true);
      return false;
    }
    getAppointmentsByDateRange(apiHost, formattedStartDate, formattedEndDate)
      .then(_appointments => {
        setAPIError(false);
        setDateRangeError(false);
        let responseCopy: AppointmentForDashboard[] = [..._appointments];
        responseCopy.sort((a, b) =>
          a.appointmentDate > b.appointmentDate ? 1 : -1,
        );

        let appointmentsWithExaminerNames = responseCopy.map(a => {
          const updatedAppointment = { ...a };
          updatedAppointment.examinerName = lookupExaminerByExaminerNumber(
            a.examinerNumber,
            examinerList,
          );
          return updatedAppointment;
        });

        setAppointments(appointmentsWithExaminerNames);
      })
      .catch(error => {
        setAPIError(true);
      });
  };

  const handleDateRangeChange = (datesPicked: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setDateRange({
      startDate: datesPicked.startDate,
      endDate: datesPicked.endDate,
    });
  };

  const setAppointmentStatus = (appointmentId: string, status: number) => {
    var newAppointments = appointments.map(appt =>
      appt.appointmentId === appointmentId ? { ...appt, status: status } : appt,
    );
    setAppointments(newAppointments);
  }

  return (
    <>
      <h1>Appointments</h1>
      <hr />
      <AddAppointmentModal
        isOpen={isAddModalOpen}
        hideModal={() => {
          setIsAddModalOpen(false);
        }}
      ></AddAppointmentModal>
      <DashboardFilter
        appointmentFilter={appointmentFilter}
        setAppointmentFilter={setAppointmentFilter}
        examiners={examiners}
        locations={locations}
        dateFilterOnChangeHandler={handleDateRangeChange}
        dateRange={dateRange}
      />
      <Authorized isAuthorized={permissions.canWriteAppointments}>
        <button
          className="btn btn-primary"
          data-testid="add-button"
          style={{ marginBottom: '10px' }}
          onClick={() => setIsAddModalOpen(true)}
        >
          Add Appointment
        </button>
      </Authorized>
      {!APIError && !DateRangeError && (
        <DashboardAppointmentTable
          appointments={filteredAppointments}
          setAppointmentStatus={setAppointmentStatus}
        />
      )}

      {APIError && (
        <div className="alert alert-warning" role="alert">
          Error retrieving data. Call support if this message doesn't disappear
          after a few minutes.
        </div>
      )}

      {DateRangeError && (
        <div className="alert alert-warning" role="alert">
          Start date can not be greater than end date.
        </div>
      )}
    </>
  );
}

export default Dashboard;
