import ModalHeader from 'react-bootstrap/ModalHeader';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import { ConvertDateTimeToInputValue } from '../../DevDashboard';
import { getLocations } from '../../../../api/locationAPI';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { createAppointment } from '../../../../api/appointmentAPI';
import { EnvironmentVariableContext } from '../../../../store/EnvironmentVariableContext';
import { getWhiteListNumbers } from '../../../../api/whiteListAPI';
import { defaultAppointment, NewAppointment } from '../../../../types/appointment';
import { AppointmentFormValidation, ValidateAppointment } from './AppointmentFormValidation';
import { Button, Form } from 'react-bootstrap';
import { Location } from 'types/Location';


export interface AddAppointmentModalProps {
  isOpen: boolean;
  hideModal: Function;    
}

function AddAppointmentModal({
  isOpen,
  hideModal
}: AddAppointmentModalProps) { 
  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const apiHost = environmentVariableContext.REACT_APP_API_BASEURL; 
  const showMobileNumberDropdown = !environmentVariableContext.REACT_APP_HIDE_DEMO;
  const blankAppointment =  {
    ...defaultAppointment, 
    appointmentDate: moment(Date.now()).format("YYYY-MM-DDTHH:mm"),
    status : 1
    };
    
  const [appointment, setAppointment] = useState<NewAppointment>(blankAppointment);    
  const [formValidationErrors, setFormValidationErrors] = useState<AppointmentFormValidation>({});
  const [formValidated, setFormValidated] = useState(false);
  const [whiteListNumbers, setWhiteListNumbers] = useState<string[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [whiteListNumbersAPIError, setwhiteListNumbersAPIError] = useState(false);
  const [createAppointmentAPIError, setCreateAppointmentAPIError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  //ValidateAppointment
  function loadWhiteListNumbers() {
    getWhiteListNumbers(apiHost)
      .then((_whiteListNumbers) => {
        setwhiteListNumbersAPIError(false);
        _whiteListNumbers.unshift("+15005550006");
        blankAppointment.mobileNumber = _whiteListNumbers[0];
        setWhiteListNumbers(_whiteListNumbers);
      })
      .catch((error) => {
        setwhiteListNumbersAPIError(true);
      }
      );
  }
  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
       setAppointment({
        ...appointment,
        [event.target.name]: event.target.value,
      });
  }
  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    const newAppointmentWithUTCDateTime:NewAppointment = {
      ...appointment, 
      appointmentDate: moment(appointment.appointmentDate).utc().format(),
      customerId: parseInt(appointment.customerId.toString()),
      mobileNumber: showMobileNumberDropdown ?  appointment.mobileNumber : `+1${appointment.mobileNumber}` ,
      examinerNumber: appointment.examinerNumber.toString().replace(/^0+/, ''),
    }
    const appointmentValidationErrors = ValidateAppointment(newAppointmentWithUTCDateTime, locations);
    const isAppointmentValid = Object.keys(appointmentValidationErrors).length === 0;    
    setFormValidated(true);
    setFormValidationErrors(appointmentValidationErrors);
    if (!isAppointmentValid) return;
    setSubmitSuccess(true);
    try {
     await createAppointment(apiHost, newAppointmentWithUTCDateTime);   
    }
    catch
    {
      setCreateAppointmentAPIError(true);
      return;
    }
    setCreateAppointmentAPIError(false);
    blankAppointment.mobileNumber = whiteListNumbers[0];
    setAppointment(blankAppointment);
    setFormValidated(false);
    setSubmitSuccess(false);
    hideModal();
  }
  function  CreateWhiteListNumbersDropdown() {    
    let options = [];      
    options.push(<option key={'index0'} value=""></option>);      
    for (let index = 0; index < whiteListNumbers.length; index++) {
      let phoneNumber = whiteListNumbers[index];
      options.push(<option key={`index${index+1}`} value={phoneNumber}>{phoneNumber}</option>);      
    }
    return options;
  }

  useEffect(() => {
    if(showMobileNumberDropdown) loadWhiteListNumbers();

    const fetchLocations = async () => {
      try {
        const locations = await getLocations(apiHost);
        setLocations(locations);
      }
      catch(e){
          console.log('error in getting the locations', e);
      }
    }

    fetchLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
  return (
    <div>
     <Modal show={isOpen} onHide={hideModal} centered={true}>
        <ModalHeader closeButton>
          <h4>Add Appointment</h4>
        </ModalHeader>
      <ModalBody>
      {whiteListNumbersAPIError && (
        <div className="alert alert-warning" role="alert">
          Error retrieving white list numbers.
        </div>
      )}
      {createAppointmentAPIError && (
        <div className="alert alert-warning" role="alert">
          Error creating appointment.
        </div>
      )}
        <Form
          noValidate
          validated={formValidated}
          id="addAppointmentForm"
          data-testid="addAppointmentForm"
          onSubmit={handleSubmit}
        > 
          <Form.Group>          
          <Form.Label htmlFor="customerId">Customer Number</Form.Label>
          <Form.Control
              type="number"
              onChange={handleChange}
              name="customerId"
              id="customerId"
              data-testid="customerId"
              aria-label="customerId"
              value={appointment.customerId || ""}
              isInvalid={!!formValidationErrors.customerId}
            />
            <Form.Control.Feedback type="invalid" data-testid="appointmentCustomerIdValidationError">
              {formValidationErrors.customerId}
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
            <Form.Label htmlFor="firstName">First Name</Form.Label>
            <Form.Control
              type="text"
              onChange={handleChange}              
              name="firstName"
              id="firstName"
              data-testid="firstName"
              aria-label="firstName"
              value={appointment.firstName || ''}
              isInvalid={!!formValidationErrors.firstName}              
            />
            <Form.Control.Feedback type="invalid" data-testid="appointmentFirstNameValidationError">
              {formValidationErrors.firstName}
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
            <Form.Label htmlFor="lastName">Last Name</Form.Label>
            <Form.Control
              type="text"
              onChange={handleChange}              
              name="lastName"
              id="lastName"
              value={appointment.lastName || ''}
              data-testid="lastName"
              aria-label="lastName"              
              isInvalid={!!formValidationErrors.lastName}              
            />            
            <Form.Control.Feedback type="invalid" data-testid="appointmentLastNameValidationError">
              {formValidationErrors.lastName}
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
            <Form.Label htmlFor="mobileNumber">Mobile Phone Number</Form.Label>
            {showMobileNumberDropdown  ?
            <Form.Control
              as="select"
              type="text"
              onChange={handleChange}              
              name="mobileNumber"
              id="mobileNumber"
              value={appointment.mobileNumber}
              data-testid="mobileNumber"
              aria-label="mobileNumber"              
              isInvalid={!!formValidationErrors.mobileNumber}      
            >
              {CreateWhiteListNumbersDropdown()}
            </Form.Control>
            :
            <Form.Control              
              type="text"
              onChange={handleChange}              
              name="mobileNumber"
              id="mobileNumber"
              value={appointment.mobileNumber}
              data-testid="mobileNumber"
              aria-label="mobileNumber"              
              isInvalid={!!formValidationErrors.mobileNumber}      
            />
            }
            {!formValidationErrors.mobileNumber && <Form.Text className="text-muted">10 digit mobile number.</Form.Text> }
            <Form.Control.Feedback type="invalid" data-testid="appointmentMobileNumberValidationError">
              {formValidationErrors.mobileNumber}
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
            <Form.Label htmlFor="appointmentDate">Date</Form.Label>
            <Form.Control
              type="datetime-local"
              onChange={handleChange}              
              name="appointmentDate"
              id="appointmentDate"
              value={ConvertDateTimeToInputValue(appointment.appointmentDate)}
              data-testid="appointmentDate"
              aria-label="appointmentDate"              
              isInvalid={!!formValidationErrors.appointmentDate}      
            />
            <Form.Control.Feedback type="invalid" data-testid="appointmentDateValidationError">
              {formValidationErrors.appointmentDate}
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
            <Form.Label htmlFor="appointmentLocation">Location</Form.Label>
            <Form.Control
              as="select"
              onChange={handleChange}              
              name="appointmentLocation"
              id="appointmentLocation"
              data-testid="appointmentLocation"
              aria-label="appointmentLocation"              
              isInvalid={!!formValidationErrors.appointmentLocation} 
            >
              <option value=""></option>
              {locations.map(location => <option key={location.id} value={location.id}>{location.name}</option>)}
            </Form.Control>
            <Form.Control.Feedback type="invalid" data-testid="appointmentLocationValidationError">
              {formValidationErrors.appointmentLocation}
            </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
            <Form.Label htmlFor="examinerNumber">Examiner Number</Form.Label>
            <Form.Control
              type="text"
              onChange={handleChange}              
              name="examinerNumber"
              id="examinerNumber"
              value={appointment.examinerNumber || ""}
              data-testid="examinerNumber"
              maxLength={4}
              aria-label="examinerNumber"              
              isInvalid={!!formValidationErrors.examinerNumber} 
            />
            <Form.Control.Feedback type="invalid" data-testid="appointmentExaminerNumberValidationError">
              {formValidationErrors.examinerNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer>    
          <Button variant="primary" type="submit" disabled={submitSuccess}>Add</Button>
          <Button variant="secondary" onClick={() => hideModal()}>Cancel</Button>
          </Modal.Footer>    
          </Form>
      </ModalBody>
    </Modal>
    </div>
  );
}

export default AddAppointmentModal;


