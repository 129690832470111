import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import {  faCommentAltCheck, faCommentAltTimes, faCommentAltDots } from"@fortawesome/pro-light-svg-icons"
import { AppointmentStatus } from "../../../enums/AppointmentStatus";
import { MobileNumberStatus, MobileNumberStatusDisplayFriendly } from "../../../enums/MobileNumberStatus";
import moment from "moment";
import "moment-timezone";
import { AppointmentForDashboard } from "../../../types/appointment";
import { Filter } from "../../../types/Filter";
import { Examiner } from "../../../types/Examiner";
import { ConversationResponse } from "enums/ConversationResponse";

export const filterAppointments = (appointments: AppointmentForDashboard[], appointmentFilter: Filter<AppointmentForDashboard>[]): AppointmentForDashboard[] => {
    let filteredAppointments = appointments;
    appointmentFilter.forEach(
        filter => {
            filteredAppointments = filteredAppointments.filter(
                appointment => filter.values.includes(String(appointment[filter.field]))
            );
        }
    );
    return filteredAppointments;
}

export function getCheckedInColumnValue(statusInt: number, covidQuestionsResponse: number, checkInReminderResponse: number, mobileNumber: string): string {
    let checkedInString = "Not Yet";
    if (statusInt === AppointmentStatus.CheckedIn || statusInt === AppointmentStatus.Completed) { checkedInString = "Yes"; }
    else if (covidQuestionsResponse === ConversationResponse.No || checkInReminderResponse === ConversationResponse.No ) { checkedInString = "Not Attending"; }
    else if ( !mobileNumber) {checkedInString = ""}
    return checkedInString;
}

export function getMobileNumberStatusValue(statusInt: number): string {
    let mobileStatus = "";
    if (statusInt === MobileNumberStatus.Invalid || statusInt === MobileNumberStatus.OptedOut) { 
        mobileStatus = MobileNumberStatusDisplayFriendly[statusInt]; 
    }
    return mobileStatus;
}

export function getDateString(appointmentDate: string, format: string = "MMM DD", timeZone?: string): string {
    let dateString = "";
    if (moment(appointmentDate).isValid()) {
        if ((timeZone) && (moment.tz.zone(timeZone))) {
            dateString = moment.utc(appointmentDate).tz(timeZone).format(format);
        } else {
            dateString = moment.utc(appointmentDate).local().format(format);
        }
    }
    return dateString;
}

export function getTimeString(appointmentDate: string, timeZone?: string): string {
    let timeString = "";
    if (moment(appointmentDate).isValid()) {
        if ((timeZone) && (moment.tz.zone(timeZone))) {
            timeString = moment.utc(appointmentDate).tz(timeZone).format("hh:mm A");
        } else {
            timeString = moment.utc(appointmentDate).local().format("hh:mm A");
        }
    }
    return timeString;
}

export function lookupExaminerByExaminerNumber (examinerNumber: string, examiners: Examiner[]) {
    const examiner = examiners.find(e => e.examinerNumber === examinerNumber);
    return examiner ? examiner.firstName + ' ' + examiner.lastName : examinerNumber;
}
export function lookupCurrentExaminer (emailAddress: string, examiners: Examiner[]) {
    if(emailAddress !== undefined){
        const examiner = examiners.find(e => e.emailAddress === emailAddress);
        return examiner ? examiner.examinerNumber: "";
    }
    else{return "";}
}
export function lookupCurrentExaminerName (emailAddress: string, examiners: Examiner[]) {
    if(emailAddress !== undefined){
        const examiner = examiners.find(e => e.emailAddress === emailAddress);
        return examiner ? `${examiner.firstName + ' ' + examiner.lastName} (${examiner.examinerNumber})`: "";
    }
    else{return "";}
}

export function getCovidResponseIcon (row: AppointmentForDashboard, enabledFeatureFlags: boolean) {
    if (enabledFeatureFlags) {
        if (row.covidQuestionsResponse === ConversationResponse.Yes || row.checkInReminderResponse === ConversationResponse.Yes) { 
            return  <FontAwesomeIcon icon={faCommentAltCheck} size="lg" color="#28A745" data-testid="responseIcon" title="Attending: Yes"/>;
        } else if (row.covidQuestionsResponse === ConversationResponse.No || row.checkInReminderResponse == ConversationResponse.No) {
            return <FontAwesomeIcon icon={faCommentAltTimes} size="lg" color="#DC3545" data-testid="responseIcon" title="Attending: No" />;
        } else if (row.covidQuestionsResponse === ConversationResponse.Pending || row.checkInReminderResponse == ConversationResponse.Pending) {
            return <FontAwesomeIcon icon={faCommentAltDots} size="lg" color="#FD7E14" data-testid="responseIcon" title="Attending: Pending" />;
        }
        else{
            return <FontAwesomeIcon icon={faCommentAltDots} size="lg" color="#FD7E14" data-testid="responseIcon" title="Attending: Invalid Response" />;
        }
    } 
    else {
        return <FontAwesomeIcon icon={faCommentAltDots} size="lg" color="#FD7E14" data-testid="responseIcon" title="Attending: Not Set" />;
    }
}

export function getMobileNumberStatusIcon(status: number): JSX.Element {
    switch(status) {
        case 0: return <FontAwesomeIcon icon={faPhoneSquare} style={{color: 'orange'}} title="Mobile Status: Unknown" />;
        case 1: return <FontAwesomeIcon icon={faPhoneSquare} style={{color: 'green'}} title="Mobile Status: Valid" />;
        case 2: return <FontAwesomeIcon icon={faPhoneSquare} style={{color: 'red'}} title="Mobile Status: Invalid" />;
        case 3: return <FontAwesomeIcon icon={faPhoneSquare} style={{color: 'red'}} title="Mobile Status: Opted Out of SMS" />;

        default: return <FontAwesomeIcon icon={faExclamationCircle} />;
    }
}