type tMobileNumberStatusDisplayFriendly = {
    [key: number]: string
}

export enum MobileNumberStatus {
    NotSet = 0,
    Valid = 1,
    Invalid = 2,
    OptedOut = 3
}

export const MobileNumberStatusDisplayFriendly: tMobileNumberStatusDisplayFriendly = {
    0: "Not Set",
    1: "Valid",
    2: "Invalid",
    3: "Opted Out",
}