import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable, { PaginationOptions } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { Examiner } from 'types/Examiner';
import { formatE164ToLocalPhoneNumber } from 'utility/formatE164ToLocalPhoneNumber';
import { ExaminerUpdate } from 'types/ExaminerUpdate';
import Authorized from 'app/common/Authorized';
import { Button, Badge } from 'react-bootstrap';
import { UserPermissionsContext } from 'store/UserPermissionsContext';
import { useContext } from 'react';

interface Props {
  examiners: Examiner[];
  onClick: (examiner: Examiner) => void;
  loading: boolean;
}

function ExaminerTable({ examiners, onClick, loading }: Props) {
  const permissions = useContext(UserPermissionsContext);
  
  function sortCaretFormatter(order: any, column: any) {
    if (!order)
      return (
        <span style={{ color: '#ccc', whiteSpace: 'nowrap' }}>
          {' '}
          <FontAwesomeIcon icon={faSort} />
        </span>
      );
    else if (order === 'asc')
      return (
        <span style={{ color: 'black', whiteSpace: 'nowrap' }}>
          {' '}
          <FontAwesomeIcon icon={faSortUp} />
        </span>
      );
    else if (order === 'desc')
      return (
        <span style={{ color: 'black', whiteSpace: 'nowrap'}}>
          {' '}
          <FontAwesomeIcon icon={faSortDown} />
        </span>
      );
    return null;
  }

  function nameFormatter(cell: string, row: Examiner) {
    return <span style={{ color: 'black', whiteSpace: 'normal', wordBreak:'break-all'}}>
            {row.firstName}<br />{row.lastName}
          </span>
  }

  function emailFormtter(cell: string, row: Examiner)
  {
    return <span style={{ color: 'black', whiteSpace: 'normal', wordBreak:'break-all'}}>
            {row.emailAddress}
           </span>
  }

  function mobileNumberFormatter(cell: string, row: Examiner) {
    return row.mobileNumber
      ? formatE164ToLocalPhoneNumber(row.mobileNumber)
      : '';
  }

  function isActiveFormatter(cell: string, row: Examiner) {
    return row.isActive
    ? <Badge variant="success">Active</Badge>
    : <Badge variant="secondary">Inactive</Badge>;
  }

  function actionsFormatter(cell: string, row: ExaminerUpdate) {
    row.oldExaminerNumber = row.examinerNumber;
    return (
      <Authorized
        isAuthorized={permissions.canWriteExaminers}
      >
        <Button
          data-testid={row.examinerNumber}
          variant="outline-secondary"
          onClick={() => onClick(row)}
        >
          Edit
        </Button>
      </Authorized>
    );
  }

  const columns = [
    {
      dataField: 'examinerNumber',
      text: 'Examiner Number',
      sort: true,
      sortCaret: sortCaretFormatter,
      editable: false,
    },
    {
      dataField: 'lastName',
      text: 'Name',
      sort: true,
      sortCaret: sortCaretFormatter,
      editable: false,
      formatter: nameFormatter,
    },
    {
      dataField: 'mobileNumber',
      text: 'Mobile Number',
      sort: true,
      sortCaret: sortCaretFormatter,
      formatter: mobileNumberFormatter,
    },
    {
      dataField: 'emailAddress',
      text: 'Email',
      sort: true,
      sortCaret: sortCaretFormatter,
      editable: false,
      formatter: emailFormtter,
    },
    {
      dataField: 'isActive',
      text: 'Status',
      sort: true,
      sortCaret: sortCaretFormatter,
      formatter: isActiveFormatter,
      editable: false,
    },
    {
      dataField: '',
      text: 'Actions',
      sort: false,
      sortCaret: sortCaretFormatter,
      editable: false,
      formatter: actionsFormatter,
    },
  ];

  const paginationOptions: PaginationOptions = {
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPage: 15,
    sizePerPageList: [10, 15, 20, 25],
  };

  return (
    <BootstrapTable
      id="examinerNumbersTable"
      bordered={false}
      striped={true}
      hover={true}
      classes="table-striped"
      keyField="examinerNumber"
      data={examiners}
      columns={columns}
      bootstrap4={true}
      noDataIndication={
        loading ? 'Loading, please wait!' : 'No data to display'
      }
      defaultSorted={[{ dataField: 'lastName', order: 'asc' }]}
      defaultSortDirection="asc"
      pagination={paginationFactory(paginationOptions)}
    />
  );
}

export default ExaminerTable;
