import moment from 'moment';
import 'moment-timezone';

export function formatUTCDateStringToDateTimeLocal (utcDateTime: string, timeZone?: string): string {
  let convertedString = "";
  if (moment(utcDateTime).isValid()) {
      if((timeZone) && (moment.tz.zone(timeZone))) {
          convertedString = moment.utc(utcDateTime).tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
      } else {
          convertedString = moment.utc(utcDateTime).local().format("YYYY-MM-DD HH:mm:ss");
      }
  }
  return convertedString;
};