import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { AppointmentForDashboard } from "../../../types/appointment";
import DashboardActionMenu from "./DashboardActionMenu";

interface DashboardActionButtonProps {
  row: AppointmentForDashboard;
  setAppointmentStatus: (appointmentId: string, status: number) => void;
}

export const DashboardActionButton = (props: DashboardActionButtonProps) => {
  return (
    <Dropdown>
        <Dropdown.Toggle variant="link" className="dashboard-appointment-action-button" id={`dropdown-button-drop-${props.row.appointmentId}`}>
          <span style={{ color: "gray", fontSize: "large" }}>
            <FontAwesomeIcon icon={faEllipsisH} title="Action menu" />
          </span>
        </Dropdown.Toggle>
        <DashboardActionMenu appointment={props.row} setAppointmentStatus={props.setAppointmentStatus} />
    </Dropdown>
  );
};

export default DashboardActionButton;