type tConversationTypesDisplayFriendly = {
    [key: number]: string
}

export enum ConversationTypes {
    InitialBooking = 0,
    OptOutInstruction = 1,
    OptOut = 2,
    OptIn = 3,
    AppointmentReminder = 4,
    VaccinationProofInitialReminder = 5,
    VideoConsent = 6,
    CheckInReminder = 7,
    CovidQuestions = 8,
    CheckInInstructions = 9,
    Unknown = 10
}

export const ConversationTypesDisplayFriendly: tConversationTypesDisplayFriendly = {
    0: "Initial Booking",
    1: "Opt-Out Instruction",
    2: "Opt-Out",
    3: "Opt-In",
    4: "Appointment Reminder",
    5: "Vaccination Proof Reminder",
    6: "Video Consent",
    7: "Check-In Reminder",
    8: "Covid Questions",
    9: "Check-In Instructions",
    10: "Unknown"
}