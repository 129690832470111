import {
    PublicClientApplication,
    EventType,
    AuthenticationResult,
  } from '@azure/msal-browser';
  import { getConfig, loginRequest } from './authConfigCoreServiceAuth';
  import { appInsights } from '../AppInsights';
  import { SeverityLevel } from '@microsoft/applicationinsights-web';
  
  export var msalB2cInstance: PublicClientApplication = new PublicClientApplication(
    getConfig(),
  );
  
  export const initializeB2cMsalInstance = () => {
    msalB2cInstance = new PublicClientApplication(getConfig());
    
    msalB2cInstance.addEventCallback(event => {
      const authResult = event.payload as AuthenticationResult;
      if (event.eventType === EventType.LOGIN_SUCCESS && authResult.account) {
        const account = authResult.account;
        msalB2cInstance.setActiveAccount(account);
        if (appInsights !== undefined) {
          appInsights.setAuthenticatedUserContext(account.username);
          appInsights.trackTrace(
            {
              message: 'Successful sign-in.',
              severityLevel: SeverityLevel.Information,
            },
            { user: account.username },
          );
        }
      }
  
      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Catch the cancelled partially self asserted erorr (partial forgotten password or partial finished sign up )
        // and redirect back to login
        if (event.error?.message.includes('AADB2C90091')) {
          msalB2cInstance.loginRedirect(loginRequest);
        }
      }
    });

    const accounts = msalB2cInstance.getAllAccounts();
    if (accounts !== undefined) {
        if (accounts.length > 0) {
          msalB2cInstance.setActiveAccount(accounts[0]);
        }
    }

    console.log(
      'msal init. Config is typically not loaded first time this happens',
    );
  };
  