import ModalHeader from 'react-bootstrap/ModalHeader';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import { AppointmentStatus } from '../../../enums/AppointmentStatus';
import { UpdatedEventAppointment } from '../../../types/appointment';
import { ConvertDateTimeToInputValue } from ".";
import { MobileNumberStatus } from '../../../enums/MobileNumberStatus';
import { Location } from '../../../types/Location';
import { Dispatch, useEffect, useState } from 'react';
import { ToggleButton } from 'react-bootstrap';
import moment from 'moment';

interface EditAppointmentModalProps {
  isOpen: boolean;
  hideModal: Function;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;  
  appointment: UpdatedEventAppointment;
  setAppointment:Dispatch<React.SetStateAction<UpdatedEventAppointment>>;
  whiteListNumbers: string[];
  locations: Location[];
}

function EditAppointmentModal({
  isOpen,
  hideModal,
  onSubmit,
  onChange,  
  appointment,
  setAppointment,
  whiteListNumbers,
  locations
}: EditAppointmentModalProps) {
  const [isEvent, setIsEvent] = useState(false);
  const [originLastChangedDateTime, setOriginLastChangedDateTime] = useState(moment(Date.now()).format("YYYY-MM-DDTHH:mm"));  
  useEffect(() => {
    setOriginLastChangedDateTime(moment(Date.now()).format("YYYY-MM-DDTHH:mm"));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      appointment = {
        ...appointment, 
        isEvent: isEvent?1:0,
        originLastChangedDateTime : ConvertDateTimeToInputValue(originLastChangedDateTime)
      }      
      setAppointment(appointment);    
},[isOpen]); 
 function  CreateWhiteListNumbersDropdown() {      
    let options = [];        
    for (let index = 0; index < whiteListNumbers.length; index++) {
      let phoneNumber = whiteListNumbers[index];
      options.push(<option key={`index${index}`} value={phoneNumber}>{phoneNumber}</option>);      
    }
    return options;
  }  
  return (
    <Modal show={isOpen} onHide={hideModal} centered={true}>
      <ModalHeader>
        <h4>Edit Appointment</h4>
      </ModalHeader>
      <ModalBody>
        <form id="editAppointmentForm" onSubmit={onSubmit}>
          <div className="form-group">
          <ToggleButton
              className="form-control"
              id="isEvent"
              name="isEvent"
              type="checkbox"              
              variant={isEvent?"outline-success":"outline-primary"}
              checked={isEvent}
              value={isEvent?"1":"0"}
              onChange={(e) => {
                e.currentTarget.value = e.currentTarget.checked ? "1":"0";
                setIsEvent(!isEvent) ;                
                onChange(e);}}
            >
              {isEvent?"As Event":"As Manual Entry"}
            </ToggleButton>
            <input
              type="hidden"
              name="appointmentId"
              id="appointmentId"
              value={appointment.appointmentId || ''}
            />
            <label htmlFor="customerId">PIC</label>
            <input
              type="number"
              onChange={onChange}
              className="form-control"
              name="customerId"
              id="customerId"
              value={appointment.customerId || 0}
            />
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              name="firstName"
              id="firstName"
              value={appointment.firstName || ''}
            />
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              name="lastName"
              id="lastName"
              value={appointment.lastName || ''}
            />
            <label htmlFor="mobileNumber">Mobile Number</label>
            <select                        
              onChange={onChange}
              className="form-control"
              name="mobileNumber"
              id="mobileNumber"
              value={appointment.mobileNumber}         
            >
              {CreateWhiteListNumbersDropdown()}
            </select>
            <label htmlFor="mobileNumberStatus">Mobile Number Status</label>
            <select
              onChange={onChange}
              className="form-control"
              name="mobileNumberStatus"
              id="mobileNumberStatus"
              value={appointment.mobileNumberStatus || 0}
            >
              <option value={MobileNumberStatus.NotSet}>Not Set</option>
              <option value={MobileNumberStatus.Valid}>Valid</option>
              <option value={MobileNumberStatus.Invalid}>Invalid</option>
              <option value={MobileNumberStatus.OptedOut}>Opted Out</option>
            </select>
            <label htmlFor="appointmentDate">Date</label>
            <input
              type="datetime-local"
              onChange={onChange}
              className="form-control"
              name="appointmentDate"
              id="appointmentDate"
              value={ConvertDateTimeToInputValue(appointment.appointmentDate)}
            />
            <label htmlFor="appointmentLocation">Location</label>
            <select                        
              onChange={onChange}
              className="form-control"
              name="appointmentLocation"
              id="appointmentLocation"
              value={appointment.appointmentLocation || 0}
            >
              <option value=""></option>
              {locations.map(location => <option key={location.id} value={location.id}>{location.name}</option>)}
            </select>
            <label htmlFor="examinerNumber">Examiner Number</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              name="examinerNumber"
              id="examinerNumber"
              value={appointment.examinerNumber || "000"}
            />
            <label htmlFor="legacyAppointmentId">Legacy Appointment Id</label>
            <input
              type="text"
              onChange={onChange}
              className="form-control"
              name="legacyAppointmentId"
              id="legacyAppointmentId"
              value={appointment.legacyAppointmentId || ""}
            />
            <label>
              {' '}
              Status <br />
              <select
                value={appointment.status || 1}
                onChange={onChange}
                className="form-control"
                id="status"
                name="status"
              >
                <option value={AppointmentStatus.Open}> Open</option>
                <option value={AppointmentStatus.Cancelled}>Cancelled</option>
                <option value={AppointmentStatus.CheckedIn}>Checked In</option>
                <option value={AppointmentStatus.Completed}>Completed</option>
              </select>
            </label>
            {isEvent && 
            <div>
            <label htmlFor="originLastChangedDateTime"> Change Date</label>
            <input
              type="datetime-local"
              onChange={(e) => {
                setOriginLastChangedDateTime(e.currentTarget.value);              
                onChange(e);}}
              className="form-control"
              name="originLastChangedDateTime"
              id="originLastChangedDateTime"
              value={ConvertDateTimeToInputValue(originLastChangedDateTime)}
            />
            </div>
            }
          </div>
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default EditAppointmentModal;
