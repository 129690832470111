import { useContext, useEffect } from "react";
import { DriverCheckInContext } from "../store/DriverCheckInContext";
import handleVisualViewportResize from "../eventHandlers/handleVisualViewportResize";

function useVisualViewportResizeListener() {
    const { state: globalState, dispatch: globalDispatch } = useContext(DriverCheckInContext);

    // Event listener for window/visualviewport resizes (used to track orientation on mobile devices and presence of on-screen keyboard)
    useEffect(() => {
        // Call external function this way otherwise it gets spammed updating state.
        function handleResize() {
            handleVisualViewportResize(globalState, globalDispatch);
        }

        visualViewport.addEventListener('resize', handleResize);
        return () => visualViewport.removeEventListener('resize', handleResize);
    });
}

export default useVisualViewportResizeListener;