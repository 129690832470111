import 'styles/exam.css';
import ExamsTable from './ExamsTable';
import ExamsSearch from './ExamsSearch';
import { useState, useContext } from 'react';
import { Exam } from 'types/Exam';
import { getExamsByCustomerNumber } from 'api/examsAPI';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';

function ExamResults() {
  const [exams, setExams] = useState<Exam[]>([]);
  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;
  const [showSpinner, setShowSpinner] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState(false);

  async function handleSearch(txtCustomerNumber: string) {
    if (!formValid(txtCustomerNumber)) return;

    setShowSpinner(true);
    getExamsByCustomerNumber(apiHost, txtCustomerNumber)
      .then(_exams => {
        setFormValidationErrors(false);
        setShowSpinner(false);
        setExams(_exams);
      })
      .catch(_ => {
        setShowSpinner(false);
        setFormValidationErrors(false);
      });
  }

  function formValid(txtCustomerNumber: string): boolean {
    var regExp = /[a-zA-Z]/g;

    if (txtCustomerNumber.length === 0) {
      setExams([]);
      setFormValidationErrors(true);
      return false;
    } else if (
      txtCustomerNumber.length < 2 ||
      txtCustomerNumber.length >= 9 ||
      txtCustomerNumber.match(regExp)
    ) {
      setFormValidationErrors(true);
      return false;
    }
    return true;
  }

  return (
    <>
      <h1>Exam Results</h1>
      <hr />
      <ExamsSearch
        search={handleSearch}
        showSpinner={showSpinner}
        formValidationErrors={formValidationErrors}
      />
      <ExamsTable exams={exams} />
    </>
  );
}
export default ExamResults;
