import AppointmentTableRow from './AppointmentTableRow';
import React, { useEffect, useState, useContext } from 'react';
import * as API from 'api/appointmentAPI';
import AddAppointmentModal from './AddAppointmentModal';
import EditAppointmentModal from './EditAppointmentModal';
import { formatUTCDateStringToDateTimeLocal } from 'utility/formatUTCDateStringToDateTimeLocal';
import {
  Appointment,
  defaultEventAppointment,
  NewEventAppointment,
  UpdatedEventAppointment,
} from 'types/appointment';
import { EnvironmentVariableContext } from 'store/EnvironmentVariableContext';
import moment from 'moment';
import { getWhiteListNumbers } from 'api/whiteListAPI';
import { getLocations } from 'api/locationAPI';
import Watermark from 'app/common/Watermark';
import { Location } from 'types/Location';

export function ConvertDateTimeToInputValue(dateTime: string): string {
  return moment(dateTime).isValid()
    ? moment(dateTime).format('YYYY-MM-DDTHH:mm')
    : '';
}

function DevDashboard() {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState(
    defaultEventAppointment,
  );
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [appointmentAPIError, setAppointmentAPIError] = useState(false);
  const [newAppointment, setNewAppointment] = useState<NewEventAppointment>({
    isEvent: 0,
    customerId: 0,
    firstName: '',
    lastName: '',
    mobileNumber: '',
    mobileNumberStatus: 0,
    appointmentDate: moment(Date.now()).format('YYYY-MM-DDTHH:mm'),
    appointmentLocation: '',
    examinerNumber: '',
    status: 1,
    originLastChangedDateTime: moment(Date.now()).format('YYYY-MM-DDTHH:mm'),
    legacyAppointmentId: '',
  });
  const [whiteListNumbers, setwhiteListNumbers] = useState<string[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [whiteListNumbersAPIError, setwhiteListNumbersAPIError] =
    useState(false);
  const [searchForm, setSearchForm] = useState({
    searchAppointmentID: '',
  });
  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const apiHost = environmentVariableContext.REACT_APP_API_BASEURL;

  useEffect(() => {
    function loadWhiteListNumbers() {
      getWhiteListNumbers(apiHost)
        .then(_whiteListNumbers => {
          setwhiteListNumbersAPIError(false);
          _whiteListNumbers.unshift('+15005550006');
          newAppointment.mobileNumber =
            newAppointment.mobileNumber || _whiteListNumbers[0];
          setwhiteListNumbers(_whiteListNumbers);
        })
        .catch(error => {
          setwhiteListNumbersAPIError(true);
        });
    }
    loadWhiteListNumbers();

    const loadLocations = async () => {
      try {
        const locations = await getLocations(apiHost);
        setLocations(locations);
      }
      catch(e){
          console.log('error in getting the locations', e);
      }
    }
    loadLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Prevent infinite loop

  function formatAppointmentArray(appointmentArray: Appointment[]) {
    let formattedAppointments = [...appointmentArray];
    formattedAppointments.forEach(function (part, index) {
      part.checkedInDateTime =
        part.checkedInDateTime === '0001-01-01T00:00:00+00:00'
          ? ''
          : part.checkedInDateTime;
      part.appointmentDate =
        part.appointmentDate === '0001-01-01T00:00:00+00:00'
          ? ''
          : part.appointmentDate;
    });
    return formattedAppointments;
  }

  function getAppointmentById() {
    API.getAppointmentById(apiHost, searchForm.searchAppointmentID)
      .then(appointment => {
        setAppointmentAPIError(false);
        let appointmentArray = [appointment];
        let formattedAppointments = formatAppointmentArray(appointmentArray);
        formattedAppointments.sort((a, b) =>
          a.appointmentDate > b.appointmentDate ? 1 : -1,
        );
        formattedAppointments.forEach(
          x =>
            (x.appointmentDate = formatUTCDateStringToDateTimeLocal(
              x.appointmentDate,
            )),
        );
        formattedAppointments.forEach(
          x =>
            (x.checkedInDateTime = formatUTCDateStringToDateTimeLocal(
              x.checkedInDateTime,
            )),
        );

        formattedAppointments = formattedAppointments.map(x=>
          ({
            ...x,
            location: locations.find(l => l.id == x.appointmentLocation)
          }));

        setAppointments(formattedAppointments);
      })
      .catch(error => {
        setAppointments([]);
        setAppointmentAPIError(true);
      });
  }

  function handleSearchSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    getAppointmentById();
  }

  function validateAppointment(
    appointment: NewEventAppointment | UpdatedEventAppointment,
  ) {
    let validationMessages: Array<string> = [];

    const validLegacyAppointmentIdFormat = /^\d{2,}$/;

    // Has to be double "=" otherwise conditional doesn't work when toggled.  Not really sure why.
    // eslint-disable-next-line eqeqeq
    if (appointment.isEvent == 1) {
      if (
        !appointment.legacyAppointmentId.match(validLegacyAppointmentIdFormat)
      ) {
        validationMessages.push(
          'Legacy Appointment Id must be at least 2 digits.',
        );
      }
    }

    return validationMessages;
  }

  function handleNewAppointmentSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    const newAppointmentWithUTCDateTime = {
      ...newAppointment,
      appointmentDate: moment(newAppointment.appointmentDate).utc().format(),
      originLastChangedDateTime: moment(
        newAppointment.originLastChangedDateTime,
      )
        .utc()
        .format(),
    };

    const appointmentValidationOutput = validateAppointment(
      newAppointmentWithUTCDateTime,
    );
    if (appointmentValidationOutput.length > 0) {
      alert(appointmentValidationOutput);
    } else {
      newAppointmentApiCall(newAppointmentWithUTCDateTime);
      hideAddModal();
    }
  }

  function newAppointmentApiCall(appointmentToCreate: NewEventAppointment) {
    API.createDemoAppointment(apiHost, appointmentToCreate).then(
      appointment => {
        setAppointments([]);
        // Has to be double "=" otherwise conditional doesn't work when toggled.  Not really sure why.
        // eslint-disable-next-line eqeqeq
        if (appointmentToCreate.isEvent == 1) {
          setSearchForm({
            ...searchForm,
            searchAppointmentID: 'N/A: Appointment created via event.',
          });
        } else {
          setSearchForm({
            ...searchForm,
            searchAppointmentID: appointment.appointmentId,
          });
        }
      },
    );
  }

  function handleEditAppointmentSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    const selectedAppointmentWithUTCDateTime = {
      ...selectedAppointment,
      appointmentDate: moment(selectedAppointment.appointmentDate)
        .utc()
        .format(),
      originLastChangedDateTime: moment(
        selectedAppointment.originLastChangedDateTime,
      )
        .utc()
        .format(),
    };

    const appointmentValidationOutput = validateAppointment(
      selectedAppointmentWithUTCDateTime,
    );
    if (appointmentValidationOutput.length > 0) {
      alert(appointmentValidationOutput);
    } else {
      editAppointmentApiCall(selectedAppointmentWithUTCDateTime);
      hideEditModal();
    }
  }

  function editAppointmentApiCall(appointmentToEdit: UpdatedEventAppointment) {
    API.updateAppointment(apiHost, appointmentToEdit).then(() => {
      setSearchForm({
        ...searchForm,
        searchAppointmentID: appointmentToEdit.appointmentId,
      });
      getAppointmentById();
    });
  }

  function showAddModal() {
    setIsAddModalOpen(true);
  }

  function hideAddModal() {
    setIsAddModalOpen(false);
  }

  function showEditModal() {
    setIsEditModalOpen(true);
  }

  function hideEditModal() {
    setIsEditModalOpen(false);
  }

  function handleEditClick(appointment: UpdatedEventAppointment) {
    hideAddModal();
    setSelectedAppointment(appointment);
    showEditModal();
  }
  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchForm({
      ...searchForm,
      [event.target.name]: event.target.value,
    });
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    if (
      event.target.name === 'status' ||
      event.target.name === 'mobileNumberStatus'
    ) {
      setNewAppointment({
        ...newAppointment,
        [event.target.name]: parseInt(event.target.value),
      });
    } else
      setNewAppointment({
        ...newAppointment,
        [event.target.name]: event.target.value,
      });
  }

  function handleEditChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    if (
      event.target.name === 'status' ||
      event.target.name === 'mobileNumberStatus'
    ) {
      setSelectedAppointment({
        ...selectedAppointment,
        [event.target.name]: parseInt(event.target.value),
      });
    } else {
      let updatedSelectedAppointment = {
        ...selectedAppointment,
        [event.target.name]: event.target.value,
      };
      setSelectedAppointment(updatedSelectedAppointment);
    }
  }

  return (
    <>
      <h4>Appointment Dashboard</h4>

      <AddAppointmentModal
        isOpen={isAddModalOpen}
        hideModal={hideAddModal}
        onSubmit={handleNewAppointmentSubmit}
        onChange={handleChange}
        newAppointment={newAppointment}
        whiteListNumbers={whiteListNumbers}
        locations={locations}
      />
      <EditAppointmentModal
        isOpen={isEditModalOpen}
        hideModal={hideEditModal}
        onSubmit={handleEditAppointmentSubmit}
        onChange={handleEditChange}
        appointment={selectedAppointment}
        setAppointment={setSelectedAppointment}
        whiteListNumbers={whiteListNumbers}
        locations={locations}
      />
      <hr />
      <Watermark text={'DEMO ONLY'} />
      <div className="row mb-3">
        <div className="col-8">
          <form onSubmit={handleSearchSubmit}>
            <div className="form-group">
              <label htmlFor="searchAppointmentID" className="col-form-label">
                Appointment ID
              </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  id="searchAppointmentID"
                  name="searchAppointmentID"
                  onChange={handleSearchChange}
                  value={searchForm.searchAppointmentID}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-10">
                <button type="submit" className="btn btn-primary">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-1"></div>
        <div className="col-3 text-center">
          <h5>Actions</h5>
          <button
            className="btn btn-primary"
            data-testid="add-button"
            onClick={showAddModal}
          >
            Add Appointment
          </button>
        </div>
      </div>
      {appointments.length > 0 && (
        <table className="table" role="table">
          <thead className="thead-dark">
            <tr>
              <th>Driver</th>
              <th>Mobile</th>
              <th>Mobile Status</th>
              <th>Appointment</th>
              <th>Checked-In</th>
              <th>Examiner ID</th>
              <th>Location</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {appointments.map(a => (
              <AppointmentTableRow
                key={a.appointmentId}
                appointment={a}
                onEditClick={handleEditClick}
                highlightRow={
                  a.checkedInDateTime !== null ? 'table-warning' : ''
                }
              />
            ))}
          </tbody>
        </table>
      )}
      {appointmentAPIError && (
        <div className="alert alert-warning" role="alert">
          Appointment doesn't exist in the system.
        </div>
      )}
      {whiteListNumbersAPIError && (
        <div className="alert alert-warning" role="alert">
          Error retrieving white list numbers.
        </div>
      )}
    </>
  );
}

export default DevDashboard;
