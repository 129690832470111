import {
  initialDriverCheckInState,
  DriverCheckInStateType,
} from 'types/DriverCheckInState';

export enum LoggedInReducerActions {
  SET_LOGGEDIN = 'SET_LOGGEDIN',
  RESET_LOGGEDIN = 'RESET_LOGGEDIN',
}

export type LoggedInReducerActionType =
  | {
      type: LoggedInReducerActions.SET_LOGGEDIN;
      value: true | false;
    }
  | {
      type: LoggedInReducerActions.RESET_LOGGEDIN;
    };

export function loggedInReducer(
  state: DriverCheckInStateType,
  action: LoggedInReducerActionType,
) {
  switch (action.type) {
    case LoggedInReducerActions.SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: action.value,
      };
    case LoggedInReducerActions.RESET_LOGGEDIN:
      return {
        ...state,
        loggedIn: initialDriverCheckInState.loggedIn,
      };
    default:
      return state;
  }
}
