export type DriverCheckInStateType = {
    account: AccountType;
    loggedIn: boolean;
    screen: ScreenType;
}

export type AccountType = {
    id: number;
    name: string | undefined;
    address: string | undefined;
}

export type ScreenType = {
    windowInnerHeight: number;
    screenOrientation: string | number;
    onScreenKeyboard: boolean;
}

export const initialDriverCheckInState = {
    account: {
        id: -1,
        name: undefined,
        address: undefined
    },
    loggedIn: false,
    screen: {
        windowInnerHeight: window.innerHeight,
        screenOrientation: window.orientation,
        onScreenKeyboard: false
    },
}