import { Examiner } from "./Examiner";

export interface ExaminerUpdate extends Examiner {
  oldExaminerNumber: string;
}

export function isExaminerUpdate(
  examiner: Examiner | ExaminerUpdate
): examiner is ExaminerUpdate {
  return (examiner as ExaminerUpdate).oldExaminerNumber !== undefined;
}
