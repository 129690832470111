import { loggedInReducer } from './loggedInReducer';
import { accountReducer } from './accountReducer';
import { screenReducer } from "./screenReducer";
import combineReducers from './combineReducers';

export const driverCheckInReducers = combineReducers({
  account: accountReducer,
  loggedIn: loggedInReducer,
  screen: screenReducer,
})
