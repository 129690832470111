export function handleWindowScrollUp(element: Element, scrollAmount: number) {
    window.scrollBy({
        top: element.getBoundingClientRect().y - scrollAmount,
        behavior: "smooth"
    });
}

export function handleWindowScrollDown(element: Element, scrollAmount: number) {
    window.scrollBy({
        top: element.getBoundingClientRect().y - scrollAmount,
        behavior: "smooth"
    });
}