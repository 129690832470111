import ModalHeader from 'react-bootstrap/ModalHeader';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import { Examiner } from 'types/Examiner';
import { ExaminerUpdate, isExaminerUpdate } from 'types/ExaminerUpdate';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { ExaminerFormValidation } from 'types/ExaminerFormValidation';
import MaskedInput from 'react-maskedinput';
import { MouseEventHandler } from 'react';

interface Props {
  isOpen: boolean;
  hideModal: Function;
  onSubmit: MouseEventHandler<HTMLFormElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  examiner: ExaminerUpdate | Examiner;
  formValidationErrors: ExaminerFormValidation;
  formValidated: boolean;
  submitButtonParams: { text: string; disabled: boolean };
}

function ExaminerModal({
  isOpen,
  hideModal,
  onSubmit,
  onChange,
  examiner,
  formValidationErrors,
  formValidated,
  submitButtonParams,
}: Props) {
  return (
    <div>
      <div>{isOpen}</div>
      <Modal show={isOpen} onHide={hideModal} centered={true}>
        <ModalHeader closeButton>
          <h4>
            {!isExaminerUpdate(examiner) ? 'Add Examiner' : 'Edit Examiner'}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form
            noValidate
            validated={formValidated}
            id="examinerForm"
            data-testid="examinerForm"
          >
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                onChange={onChange}
                name="firstName"
                id="firstName"
                data-testid="firstName"
                aria-label="firstName"
                value={examiner.firstName || ''}
                isInvalid={!!formValidationErrors.firstName}
              />
              <Form.Control.Feedback
                type="invalid"
                data-testid="examinerFirstNameValidationError"
              >
                {formValidationErrors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                onChange={onChange}
                name="lastName"
                id="lastName"
                data-testid="lastName"
                aria-label="lastName"
                value={examiner.lastName || ''}
                isInvalid={!!formValidationErrors.lastName}
                required
                minLength={1}
              />
              <Form.Control.Feedback
                type="invalid"
                data-testid="examinerLastNameValidationError"
              >
                {formValidationErrors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                onChange={onChange}
                name="emailAddress"
                id="emailAddress"
                data-testid="emailAddress"
                aria-label="emailAddress"
                value={examiner.emailAddress || ''}
                isInvalid={!!formValidationErrors.emailAddress}
                required
                minLength={1}
              />
              <Form.Control.Feedback
                type="invalid"
                data-testid="examinerEmailAddressValidationError"
              >
                {formValidationErrors.emailAddress}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="md-3">
              <Form.Group as={Col}>
                <Form.Label>Examiner Number</Form.Label>
                <Form.Control
                  type="text"
                  onChange={onChange}
                  name="examinerNumber"
                  id="examinerNumber"
                  data-testid="examinerNumber"
                  aria-label="examinerNumber"
                  value={examiner.examinerNumber || ''}
                  maxLength={4}
                  isInvalid={!!formValidationErrors.examinerNumber}
                />
                {!formValidationErrors.examinerNumber && (
                  <Form.Text className="text-muted">
                    Please enter a 3-4 digit number
                  </Form.Text>
                )}
                <Form.Control.Feedback
                  type="invalid"
                  data-testid="examinerNumberValidationError"
                >
                  {formValidationErrors.examinerNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="hidden"
                  name="oldExaminerNumber"
                  id="oldExaminerNumber"
                  data-testid="oldExaminerNumber"
                  aria-label="oldExaminerNumber"
                  value={
                    isExaminerUpdate(examiner)
                      ? examiner.oldExaminerNumber
                      : examiner.examinerNumber
                  }
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  as={MaskedInput}
                  mask="(111) 111-1111"
                  onChange={onChange}
                  name="mobileNumber"
                  id="mobileNumber"
                  data-testid="mobileNumber"
                  aria-label="mobileNumber"
                  value={examiner.mobileNumber || ''}
                  isInvalid={!!formValidationErrors.mobileNumber}
                />
                <Form.Control.Feedback
                  type="invalid"
                  data-testid="examinerMobileNumberValidationError"
                >
                  {formValidationErrors.mobileNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="md-3">
            <Form.Group as={Col}>
                <Form.Check
                 type="checkbox"
                 id="isActive"
                 name="isActive"
                 onChange={onChange}
                 label="Is Active"
                 defaultChecked={examiner.isActive === true}
                />
              </Form.Group>
            </Row>
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button
            data-testid="examinerModalCancelButton"
            variant="secondary"
            onClick={() => hideModal()}
          >
            Cancel
          </Button>
          <Button
            data-testid="examinerModalSubmitButton"
            variant="primary"
            type="submit"
            disabled={submitButtonParams.disabled}
            onClick={onSubmit}
          >
            {submitButtonParams.text}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ExaminerModal;
