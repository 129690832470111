import {isMobile} from "react-device-detect";
import { DriverCheckInStateType } from "../types/DriverCheckInState";
import { ScreenReducerActions } from "../reducers/screenReducer";

function handleVisualViewportResize(globalState: DriverCheckInStateType, globalDispatch: React.Dispatch<any>) {
    const newHeight = window.innerHeight;
    const newOrientation = window.orientation;
    let newScreenState = {...globalState.screen};

    if (newHeight !== globalState.screen.windowInnerHeight) newScreenState.windowInnerHeight = newHeight;

    if (isMobile) {
        if (globalState.screen.screenOrientation !== newOrientation) newScreenState.screenOrientation = newOrientation;
        newScreenState.onScreenKeyboard = (visualViewport.height < newHeight) ? true : false;
    }

    globalDispatch({ type: ScreenReducerActions.SET_SCREEN, value: newScreenState });
}

export default handleVisualViewportResize;