import { useEffect, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { AppointmentForDashboard } from 'types/appointment';
import { Filter, IDateRange, ExaminerFilter } from 'types/Filter';
import MultiSelect from 'react-multi-select-component';
import { Examiner } from 'types/Examiner';
import { Location } from 'types/Location';
import {
  AppointmentStatus,
  AppointmentStatusDisplayFriendly,
} from 'enums/AppointmentStatus';
import { Moment } from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  lookupCurrentExaminer,
  lookupCurrentExaminerName,
} from '.././DashboardFunctions';
import { getCurrentUserAccountInfo } from 'auth/authUtilities';
import { Col, Form, Row } from 'react-bootstrap';
import "./DashboardFilter.css";

interface DashboardFilterProps {
  appointmentFilter: Filter<AppointmentForDashboard>[];
  setAppointmentFilter: any;
  examiners: Examiner[];
  locations: Location[];
  dateRange: IDateRange;
  dateFilterOnChangeHandler: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
}

export function sortByLastNameThenFirstName(examiners: Examiner[]) {
  return [...examiners].sort((a, b) => {
    let aName = a.lastName.toLowerCase() + a.firstName.toLowerCase();
    let bName = b.lastName.toLowerCase() + b.firstName.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });
}

const DashboardFilter = ({
  appointmentFilter,
  setAppointmentFilter,
  examiners,
  locations,
  dateRange,
  dateFilterOnChangeHandler,
}: DashboardFilterProps) => {
  const [examinerSelected, setExaminerSelected] = useState<ExaminerFilter[]>(
    [],
  );
  const [locationSelected, setLocationSelected] = useState([]);
  const [statusSelected, setStatusSelected] = useState([
    { value: '1', label: AppointmentStatus[1] },
    { value: '3', label: AppointmentStatus[3] },
  ]);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null,
  );
  let emailAddress: string = '' as string;
  if (getCurrentUserAccountInfo() !== undefined) {
    emailAddress = getCurrentUserAccountInfo().username as string;
  }
  const currentExaminer = lookupCurrentExaminer(emailAddress, examiners);
  const currentExaminerLabel = lookupCurrentExaminerName(
    emailAddress,
    examiners,
  );

  useEffect(() => {
    if (currentExaminer !== '') {
      //setExaminerSelected([...examinerSelected, {value: currentExaminer, label: currentExaminerLabel}])
      setExaminerSelected(prevState => [
        ...prevState,
        { value: currentExaminer, label: currentExaminerLabel },
      ]);
    }
  }, [currentExaminer, currentExaminerLabel]);
  function onChange(selected: any, property: keyof AppointmentForDashboard) {
    switch (property) {
      case 'examinerNumber':
        setExaminerSelected(selected);
        break;
      case 'appointmentLocation':
        setLocationSelected(selected);
        break;
      case 'status':
        setStatusSelected(selected);
        break;
      default:
        break;
    }

    appointmentFilter = appointmentFilter.filter(
      filter => filter.field !== property,
    );
    let tmp: string[] = [];
    selected.forEach((s: any) => tmp.push(s.value));

    if (selected.length > 0) {
      appointmentFilter.push({ field: property, values: tmp });
    }
    setAppointmentFilter(appointmentFilter);
  }

  function handleFocusChange(focusedInputChanged: FocusedInputShape | null) {
    setFocusedInput(focusedInputChanged);
  }

  return (
    <div className="container">
      <Row>
        <Col xl={6} md={6} className="px-0">
          <Form.Group as={Col} sm={8} md={11} lg={8} xl={8} className="px-0">
            <Form.Label htmlFor="customerNumber">Examiner(s)</Form.Label>
            <MultiSelect
              selectAllLabel="Select/Deselect All"
              options={sortByLastNameThenFirstName(examiners).map(examiner => ({
                value: examiner.examinerNumber,
                label: `${examiner.firstName + ' ' + examiner.lastName} (${
                  examiner.examinerNumber
                })`,
              }))}
              value={examinerSelected}
              onChange={(selected: any) => onChange(selected, 'examinerNumber')}
              ClearSelectedIcon={
                <FontAwesomeIcon icon={faTimes} title="Clear Selection" />
              }
              labelledBy="SelectExaminers"
              className='multi-select-custom'
            />
          </Form.Group>
        </Col>
        <Col xl={6} md={6} className="px-0">
          <Form.Group
            as={Col}
            sm={8}
            md={11}
            lg={8}
            xl={8}
            className="px-0 float-md-right float-lg-none"
          >
            <Form.Label htmlFor="customerNumber">Location(s)</Form.Label>
            <MultiSelect
              selectAllLabel="Select/Deselect All"
              options={locations.sort((a, b) => a.name > b.name ? 1 : -1).map(location => ({
                value: location.id,
                label: location.name,
              }))}
              value={locationSelected}
              onChange={(selected: any) =>
                onChange(selected, 'appointmentLocation')
              }
              ClearSelectedIcon={
                <FontAwesomeIcon icon={faTimes} title="Clear Selection" />
              }
              labelledBy="SelectLocations"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xl={6} md={6} className="px-0">
          <Form.Group as={Col} sm={8} md={12} lg={8} xl={8} className="px-0">
            <Row>
              <Col xl={12} md={11}>
                <Form.Label htmlFor="customerNumber">Date Range</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col sm={11} md={12} xl={12}>
                <DateRangePicker
                  startDate={dateRange.startDate}
                  startDateId="startDate"
                  endDate={dateRange.endDate}
                  endDateId="endDate"
                  onDatesChange={dateFilterOnChangeHandler}
                  focusedInput={focusedInput}
                  onFocusChange={handleFocusChange}
                  isOutsideRange={() => false}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col xl={6} md={6} className="px-0">
          <Form.Group
            as={Col}
            sm={8}
            md={11}
            lg={8}
            xl={8}
            className="px-0 float-md-right float-lg-none"
          >
            <Form.Label htmlFor="customerNumber">Appointment Status</Form.Label>
            <MultiSelect
              selectAllLabel="Select/Deselect All"
              options={Object.keys(AppointmentStatusDisplayFriendly).map(
                objKey => ({
                  value: objKey,
                  label: AppointmentStatusDisplayFriendly[Number(objKey)],
                }),
              )}
              value={statusSelected}
              onChange={(selected: any) => onChange(selected, 'status')}
              ClearSelectedIcon={
                <FontAwesomeIcon icon={faTimes} title="Clear Selection" />
              }
              labelledBy="SelectAppointmentStatuses"
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardFilter;
