export interface FeatureFlagItem {
    featureFlagName: string;
    featureFlagValue: boolean;
  }
  
  export interface FeatureFlagModel {
    featureFlagList: FeatureFlagItem[];
  }
  
  export const defaultFeatureFlagModel: FeatureFlagModel = {
    featureFlagList: [
      {
        featureFlagName: 'AskForAttendingReplyNotification',
        featureFlagValue: true,
      },
      {
        featureFlagName: 'CovidGuidelineNotification',
        featureFlagValue: false,
      },
    ],
  };