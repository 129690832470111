import { AxiosResponse, AxiosError } from "axios";

export function handleResponse(response: AxiosResponse) {
    if (response.status === 200 || response.status === 201) return response.data;
    if (response.status === 204) throw new Error("No content found");
    throw new Error("Network response not ok : " + response);
}

export function handleError(error: AxiosError) {
    throw error;
}