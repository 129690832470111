import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import SignInOutButton from "../SignInOutButton";
import Authorized from "../../common/Authorized";
import { UserPermissionsContext } from "store/UserPermissionsContext";
import { useContext } from "react";

function Header() {
    const permissions = useContext(UserPermissionsContext);
    return (
        <div className="container-fluid p-0">
            <Navbar bg="light">
                <Navbar.Brand as={Link} to="/">
                    <img src="/sgi_logo.svg" alt="SGI Logo" width="128" height="30" className="d-inline-block align-top pr-2" />
                    Driver Test Check-In
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <AuthenticatedTemplate>
                            <Authorized isAuthorized={permissions.canReadAppointments}>
                                <Link aria-current="page" className="nav-link" to="/Dashboard">Dashboard</Link>
                            </Authorized>
                            <Authorized isAuthorized={permissions.canWriteAppointments}>
                                <Link aria-current="page" className="nav-link" to="/Reports">Reports</Link>
                            </Authorized>
                            
                            <Authorized isAuthorized={permissions.canReadExaminers}>
                                <Link aria-current="page" className="nav-link" to="/Examiners">Examiners</Link>
                            </Authorized>
                            <Authorized isAuthorized={permissions.canReadExam}>
                                <Link aria-current="page" className="nav-link" to="/Exams">Exams</Link>
                            </Authorized>
                        </AuthenticatedTemplate>
                    </Nav>
                    <Nav>
                        <SignInOutButton />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Header;