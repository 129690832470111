import { Modal, Button } from "react-bootstrap";
import { AppointmentForDashboard } from "../../../types/appointment";
import { getDateString, getTimeString } from "./DashboardFunctions";

interface CancelAppointmentModalProps {
    appointment: AppointmentForDashboard;
    show: boolean;
    handleClose: React.MouseEventHandler;
    handleCancelAppt: React.MouseEventHandler;
    submitButtonParams: {text: string, disabled: boolean};
}

export function CancelAppointmentModal({appointment, show, handleClose, handleCancelAppt, submitButtonParams}: CancelAppointmentModalProps) {
    
    return(
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>Cancel appointment confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body data-testid="modalBody">Are you sure you would like to cancel {appointment.firstName} {appointment.lastName}'s appointment at {getDateString(appointment.appointmentDate)} {getTimeString(appointment.appointmentDate)} ?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} data-testid="closeModal">
                    Close
                </Button>
                <Button variant="primary" onClick={handleCancelAppt} data-testid="cancelAppointment" disabled={submitButtonParams.disabled}>
                    {submitButtonParams.text}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CancelAppointmentModal;