import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useContext } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import DevDashboard from "./pages/DevDashboard";
import Dashboard from "./pages/Dashboard";
import Reports from "./pages/CheckInReport/Index";
import Examiners from "./pages/Examiners";
import ExamResults from "./pages/ExamResults";
import EightPtTest from "./pages/EightPtTest";
import Home from "./pages/Home";
import Logout from "./pages/Logout";
import PageNotFound from "./pages/PageNotFound";
import { PrivateRoute } from "./common/PrivateRoute";
import { EnvironmentVariableContext } from "../store/EnvironmentVariableContext";
import { ToastContainer, Slide } from "react-toastify";
import useVisualViewportResizeListener from "../hooks/useVisualViewportResizeListener";
import { UserPermissionsContext } from "store/UserPermissionsContext";
import { Offline } from './components/OfflineAlert';
function App() {
  const environmentVariableContext = useContext(EnvironmentVariableContext);
  const permissions = useContext(UserPermissionsContext);
  useVisualViewportResizeListener();

  return (
      <BrowserRouter>
        <ToastContainer transition={Slide} />
        <Header />
        <div className="container contentContainer">
          <div className="row">
            <Offline />
          </div>
          <div className="row">
            <div className="col pt-5">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/Logout" component={Logout} />
                {(!environmentVariableContext.REACT_APP_HIDE_DEMO) &&
                  <PrivateRoute exact path="/DevDashboard"
                    isAuthorized={permissions.canReadAppointments}
                    component={DevDashboard} />
                }
                {(!environmentVariableContext.REACT_APP_HIDE_DEMO) &&
                  <PrivateRoute exact path="/EightPtTest"
                    isAuthorized={permissions.canReadAppointments}
                    component={EightPtTest} />
                }
                <PrivateRoute exact path="/Dashboard"
                  isAuthorized={permissions.canReadAppointments}
                  component={Dashboard} />
                <PrivateRoute exact path="/Reports"
                  isAuthorized={permissions.canWriteAppointments}
                  component={Reports} />
                <PrivateRoute exact path="/Examiners"
                  isAuthorized={permissions.canReadExaminers}
                  component={Examiners} />
                <PrivateRoute exact path="/Exams"
                  isAuthorized={permissions.canReadExam}
                  component={ExamResults} />
                <Route component={PageNotFound} />
              </Switch>
            </div>
          </div>
        </div>
        <Footer />
      </BrowserRouter>
  );
}

export default App;
