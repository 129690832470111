/**
 *
 * OfflineAlert
 */

import './offline.css';
import { useState, useEffect } from "react";
import { Alert } from 'react-bootstrap';
import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';


export function Offline() {
    const [isOffline, setIsOffline] = useState(false);
    const [lastOfflineTime, setLastOfflineTime] = useState(new Date());
    const appInsights = useAppInsightsContext();
    const trackOnline = useTrackEvent(appInsights, 'Online', {
        wentOfflineAt: new Date(),
        backOnlineAt: new Date(),
        wasOfflineForMs: 0,
    });
    const trackOffline = useTrackEvent(appInsights, 'Offline', {
        wentOfflineAt: new Date(),
    });
    const checkOnlineStatus = async () => {
        try {
            const online = await fetch('/health');
            return (
                (online.status >= 200 && online.status < 300) || online.status === 0
            );
        } catch (err) {
            return false;
        }
    };

    function handleOnlineEvent() {
        setIsOffline(false);
        const currentTime = Date.now();
        if (lastOfflineTime) {
            try {
                trackOnline({
                    wentOfflineAt: new Date(lastOfflineTime),
                    wasOfflineForMs: currentTime - lastOfflineTime.getTime(),
                    backOnlineAt: new Date(currentTime),
                });
            } catch (err) {
                console.log('track-online', err);
            }
        }
    }

    function handleOfflineEvent() {
        setIsOffline(true);
        const currentTime = Date.now();
        setLastOfflineTime(new Date(currentTime));
        try {
            trackOffline({ wentOfflineAt: new Date(currentTime) });
        } catch (err) {
            console.log('track-offline', err);
        }
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            const result = await checkOnlineStatus();
            if (result) {
                handleOnlineEvent();
            } else {
                handleOfflineEvent();
            }
        }, 6000);
        return () => clearInterval(interval);
    });

    useEffect(() => {
        window.addEventListener('online', handleOnlineEvent);
        window.addEventListener('offline', handleOfflineEvent);

        return () => {
            window.removeEventListener('online', handleOnlineEvent);
            window.removeEventListener('offline', handleOfflineEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastOfflineTime]);
    
    return (
        <>
            {isOffline ? <OfflineAlert /> : null}
        </>
    );
}
export function OfflineAlert() {
    return (
        <Alert variant='danger' className="offline-bar">
            <i className="fa-solid fa-exclamation"></i>
            <span>No Internet Connection</span>
        </Alert>
    );
}
