import { Conversation } from './conversation';
import { Location } from './Location';

export interface NewAppointment {
    customerId: number;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    mobileNumberStatus: number;
    appointmentDate: string;
    appointmentLocation: string;
    examinerNumber: string;
    status: number;
    legacyAppointmentId: string;
}

export interface NewEventAppointment extends NewAppointment {
    isEvent:number,
    originLastChangedDateTime: string;
  }
  export interface UpdatedEventAppointment extends Appointment {
    isEvent:number,
    originLastChangedDateTime: string;
  }
  export const defaultEventAppointment: UpdatedEventAppointment = {
    customerId: 0,
    firstName: "",
    lastName: "",
    mobileNumber: "",
    mobileNumberStatus: 0,
    appointmentDate: "",
    appointmentLocation: "",
    examinerNumber: "",
    status: 0,
    appointmentId: "",
    checkedInDateTime: null as any,
    conversations: [],
    isEvent:0,
    originLastChangedDateTime:"",
    legacyAppointmentId: "",
}
export interface Appointment extends NewAppointment {
    appointmentId: string;
    checkedInDateTime: string;
    conversations: Conversation[];
    location?: Location
}

export interface AppointmentForDashboard extends NewAppointment {
    appointmentId: string;
    covidQuestionsResponse: number;
    checkInReminderResponse: number;
    serviceType?: number;
    examinerName: string;
    location?: Location
}

export const defaultAppointment: Appointment = {
    customerId: 0,
    firstName: "",
    lastName: "",
    mobileNumber: "",
    mobileNumberStatus: 0,
    appointmentDate: "",
    appointmentLocation: "",
    examinerNumber: "",
    status: 0,
    appointmentId: "",
    checkedInDateTime: null as any,
    conversations: [],
    legacyAppointmentId: "",
}